import { ActiveElementStatus, PredefinedCalculation } from "@/types";
import { defineStore } from "pinia";
import { generateUUID } from "@/utils/encryption";
import { SCREEN_SIZES, getScreenSize } from "@/screenSizes";
import { WidgetHandler } from "@/components/library/Dashboard/utils/WidgetHandler";
import { DashboardHandler } from "@/components/library/Dashboard/utils/DashboardHandler";
import { OrderData } from "@/components/library/Dashboard/Order/CardOrder.vue";
import { useGlobalConfigsStore } from "../globalConfigs/globalConfigs";
import { DashboardResponse } from "@/api/types";
import { DxAPIConnector } from "@/api";
import { t } from "i18next";
import { DXTour } from "@/components/library/GuidedTour/GuidedTourUtils";
import {
  FCConstant,
  FCDataSource,
  FCSingleSymbol,
  FunctionComponent,
} from "@/components/views/MATHS/MathComponents";
import { predefinedCalculations } from "@/constants/math";
import { ChildSelectOption } from "@/components/library/BaseSelect/BaseSelectUtils";
import { SERVICE_ID } from "@/constants/consentRequiredServicesList";

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (userData: string) => void;
    };
    getSessionID: () => void;
  }
}

export const useUISettingsStore = defineStore("uisettings", {
  state: () => ({
    refreshElements: [] as string[],
    toasts: {} as Record<string, DXToast>,
    addDeviceModalOpen: false,
    saveCalculationModalOpen: false,
    isPredefinedCalculationListOpen: false,
    sideBarCollapsed: getScreenSize().width < SCREEN_SIZES.md,
    dashboards: [] as DashboardHandler[],
    editingWidget: {} as WidgetHandler | undefined,
    activeElementType: "" as "" | "widget" | "card" | "dashboard",
    activeElementStatus: "" as "" | ActiveElementStatus,
    activeElement: {} as DashboardHandler | WidgetHandler,
    activeElementParentID: "" as string,
    activeDashboardID: "" as string,
    activeCardID: "" as string,
    activeTour: null as null | DXTour,
    tourStarted: false,
    addDeviceDataEntryCurrentTab: "physical" as "physical" | "virtual",
    functionList: [] as FunctionComponent[],
    isGuidedTour: false as boolean,
    guidedTourTargetID: "" as string,
    deviceID: "" as string | undefined,
    predefinedCalculations: [] as PredefinedCalculation[],
    openPredefinedCalculationWidget: false as boolean,
    selectedPredefinedCalculationID: 0 as number,
    isTourPredefinedWidgetOpen: false as boolean,
    isCalculationCreating: false as boolean,
    isDragingComponentsToTrash: false as boolean,
    predefinedCalculationList: [] as ChildSelectOption[],
    userConsent: {
      // never change this directly use the changePermission action
      firebase: false,
      openstreet: false,
      photon: false,
      openweather: false,
    } as { [key in SERVICE_ID]: boolean },
    haveFullRefresh: false as boolean,
  }),
  getters: {
    // New Getters
    getPredefinedCalculations() {
      return predefinedCalculations;
    },
    getFunctionList(state) {
      return state.functionList;
    },
    getRegularDashboards(state) {
      return state.dashboards.filter((i) => i.imOnly === false);
    },
    getIMDashboards(state) {
      return state.dashboards.filter((i) => i.imOnly === true);
    },
    getNewDashById: (state) => (id: string) => {
      return state.dashboards.find((i) => i.id === id);
    },
    getCardsByDashboardType: (state) => (imOnly: boolean) => {
      return state.dashboards
        .filter((i) => i.imOnly === imOnly)
        .map((i) => i.cards);
    },
    getActiveDashboard(state) {
      return state.dashboards.find((i) => i.id === state.activeDashboardID);
    },
    getRegularDashboardOrders() {
      const order: OrderData[] = [];
      this.getRegularDashboards.forEach((i) => {
        order.push({ id: i.id!, title: i.title, order: i.order });
      });

      return order;
    },
    getIMDashboardOrders() {
      const order: OrderData[] = [];
      this.getIMDashboards.forEach((i) => {
        order.push({ id: i.id!, title: i.title, order: i.order });
      });

      return order;
    },
    /**
     * Expects an element id and the element type (widget or card)
     * Returns the corresponding dashboard
     * @param state
     * @returns
     */
    getDashboardByElementID:
      (state) => (id: string, elementType: "widget" | "card") => {
        switch (elementType) {
          case "widget":
            return state.dashboards.find((i) =>
              i.widgets.find((w) => w.widgetID === id)
            );
          case "card":
            return state.dashboards.find((i) =>
              i.cards.find((w) => w.id === id)
            );
          default:
            return;
        }
      },

    /**
     * Expects a widget or card id and the element type (widget or card)
     * Returns the corresponding widget or card
     * @param state
     * @returns
     */
    getElementByID: (state) => (id: string, elementType: "widget" | "card") => {
      switch (elementType) {
        case "widget":
          const targetDashboardByWidget = state.dashboards.find((dashboard) =>
            dashboard.widgets.find((widget) => widget.widgetID === id)
          );

          return targetDashboardByWidget?.widgets.find(
            (widget) => widget.widgetID === id
          );
        case "card":
          const targetDashboardByCard = state.dashboards.find((dashboard) =>
            dashboard.cards.find((card) => card.id === id)
          );
          return targetDashboardByCard?.cards.find((card) => card.id === id);
        default:
          return;
      }
    },

    /**
     * Returns all devices (ids) referenced in the dashboards widgets
     * @param state
     * @returns
     */
    getValueDevices(state) {
      const devices: Record<number, { id: number }> = {};
      const widgets = state.dashboards.flatMap((dashboard) => {
        return dashboard.widgets.filter(
          (widget) => widget.variable !== undefined
        );
      });

      widgets.forEach((widget) => {
        if (widget.variable?.deviceID) {
          devices[parseInt(widget.variable.deviceID)] = {
            id: parseInt(widget.variable.deviceID),
          };
        }
      });

      return devices;
    },
    /**
     * Returns a list of devices in the format {device: number, sppindex: string, sppsubindex: string}
     * that are referenced in the dashboards graph widgets
     * @param state
     * @returns
     */
    get24hDevices(state) {
      const devices: Record<
        string,
        { device: number; sppindex: string; sppsubindex: string }
      > = {};

      const graphWidgets = state.dashboards.flatMap((dashboard) => {
        return dashboard.widgets.filter((widget) => widget.type === "graph");
      });
      graphWidgets.forEach((widget) => {
        if (!widget.variable) {
          return;
        }

        devices[
          `${widget.variable.deviceID}__${widget.variable.index}__${widget.variable.subIndex}`
        ] = {
          device: parseInt(widget.variable.deviceID),
          sppindex: widget.variable.index.toString(),
          sppsubindex: widget.variable.subIndex.toString(),
        };
      });

      return devices;
    },
  },
  actions: {
    async fetchDashboardData() {
      try {
        // Fetch data
        const dashboardData =
          (await useGlobalConfigsStore().connector?.uiSettings.getDashboards()) as DashboardResponse;

        // Prepare the dashboards.
        const dashboards = dashboardData.dashboards.map((dashboard) => {
          // Filter cards and widgets
          const cardsByDashboard =
            dashboardData.order[
              dashboard.id as keyof typeof dashboardData.order
            ];
          // Filter cards and widgets
          const cardIDsByDashboard = cardsByDashboard.map(
            (card) => card.cardID
          );
          // Cards by dashboard
          const filteredCardsByDashboard = dashboardData.cards.filter((card) =>
            cardIDsByDashboard.includes(card.id)
          );
          // Widgets by dashboard
          const widgetsByDashboard = dashboardData.widgets.filter((widget) =>
            cardIDsByDashboard.includes(widget.cardID)
          );

          return new DashboardHandler({
            connector: useGlobalConfigsStore().connector as DxAPIConnector,
            dashboard: dashboard,
            setError: (options) => this.setError(options),
            addCardToDashboard: (dashboardID, targetCard) => {
              this.getNewDashById(dashboardID)?.addCard(targetCard);
            },
            addWidgetToCard(cardID, targetWidget) {
              useUISettingsStore()
                .getDashboardByElementID(cardID, "card")
                ?.getCardByID(cardID)
                ?.addWidget(targetWidget);
            },
            cards: filteredCardsByDashboard,
            widgets: widgetsByDashboard,
            order:
              dashboardData.order[
                dashboard.id as keyof typeof dashboardData.order
              ],
          });
        });
        this.dashboards = [...dashboards];
      } catch (error) {
        console.error(error);
        this.setError({
          message: t("error.connection_fail_handle_uisettings") ?? "",
          type: "error",
        });
      }
    },
    setCurrentDeviceTab(tab: "physical" | "virtual") {
      this.addDeviceDataEntryCurrentTab = tab;
    },
    setError(
      error: Partial<{
        id: string;
        type: string;
        title: string;
        message: string | null;
        response: string;
        action: boolean;
        persistent: boolean;
        actiontext: string;
        timeout: number;
      }>
    ) {
      const generatedID = generateUUID();
      if (
        error &&
        typeof error.id !== "undefined" &&
        error.id === "session_invalid"
      ) {
        this.toasts = {};
      }
      this.toasts[error.id ?? generatedID] = {
        id: error.id ?? generateUUID(),
        time: Date.now(),
        type: error.type ?? "error",
        title: error.title,
        message: error.message ?? "",
        response: error.response ?? "",
        active: error.action ?? false,
        dismissed: false,
        persistent: error.persistent ?? false,
        action: error.action?.toString() ?? "",
        actiontext: error.actiontext,
        timeout: error.timeout,
      };
      let to = false as false | NodeJS.Timeout;
      if (!error.persistent) {
        if (to) {
          clearTimeout(to);
        }
        to = setTimeout(
          () => {
            delete this.toasts[error.id ?? generatedID];
          },
          error.timeout ? error.timeout : 5000
        );
      }
    },
    removeToast(id: string) {
      delete this.toasts[id];
    },
    /**
     * Removes the dashboard from the dashboards list.
     * Should be run after the backend has removed the dashboard.
     * @param id
     */
    removeDashboard(id: string) {
      this.dashboards = this.dashboards.filter((dash) => dash.id !== id);
    },
    closeAllPointedModals() {
      // dummy function that pointed modals subscribe to;
      return;
    },
    updateFunctionList(newList: FunctionComponent[]) {
      this.functionList = newList;
    },
    clearFunctionList() {
      this.functionList = [];
    },
    addToFunctionList(newItem: FunctionComponent) {
      this.functionList.push(newItem);
    },
    setGuidedTour(isTour: boolean) {
      this.isGuidedTour = isTour;
    },
    setGuidedTourTargetID(id: string) {
      this.guidedTourTargetID = id;
    },
    getElementTourID(element: FunctionComponent): string {
      if (element instanceof FCDataSource) return "data-source-in-the-dragbox";
      if (element instanceof FCSingleSymbol) return "symbol-in-the-dragbox";
      if (element instanceof FCConstant) return "constant-in-the-dragbox";
      return "";
    },
    setDeviceID(id: string | undefined) {
      this.deviceID = id;
    },
    setPredefinedCalculationWidget(isWidgetOpen: boolean) {
      this.openPredefinedCalculationWidget = isWidgetOpen;
    },
    setPredefinedCalculationId(id: number) {
      this.selectedPredefinedCalculationID = id;
    },
    setTourPredefinedWidget(isWidgetOpen: boolean) {
      this.isTourPredefinedWidgetOpen = isWidgetOpen;
    },
    setDragComponentToTrash(isDragToTrash: boolean) {
      this.isDragingComponentsToTrash = isDragToTrash;
    },
    async setPredefinedCalculations() {
      // use the below api, When it provides the data in same format as in math.ts (predefinedCalculations)
      // const connector = useGlobalConfigsStore().connector;
      // if (connector) {
      //   this.predefinedCalculations =
      //     await connector.devices.getPredefinedCalculations();
      // }
      this.predefinedCalculations = predefinedCalculations;
    },
    getPredefinedCalculationDropdownList() {
      return (this.predefinedCalculationList =
        this.getPredefinedCalculations.map((calculation) => ({
          value: calculation.id,
          uiId: calculation.uiId,
          text: calculation.title,
          imageSrc:
            calculation.imgPath ||
            "/assets/img/device-images/device-placeholder.svg",
        })));
    },
    setUserConsent(permission: Partial<{ [key in SERVICE_ID]: boolean }>) {
      this.userConsent = {
        ...this.userConsent,
        ...permission,
      };
      //emit changes to dx orange
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "consent", data: this.userConsent })
        );
      }
    },
    resetUserConsent() {
      this.userConsent = {
        firebase: false,
        openstreet: false,
        photon: false,
        openweather: false,
      } as { [key in SERVICE_ID]: boolean };
      //emit changes to dx orange
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "consent", data: this.userConsent })
        );
      }
    },
  },
});

export type DXToast = {
  type: string;
  id: string;
  message: string;
  response: string;
  persistent: boolean;
  time?: number;
  title?: string;
  active?: boolean;
  dismissed?: false;
  action?: string; // todo stronger typing
  actiontext?: string;
  timeout?: number;
};

export type UISettingsStoreType = Omit<
  ReturnType<typeof useUISettingsStore>,
  keyof ReturnType<typeof defineStore>
>;
