export class DXTour {
  public steps: DXTourStep[] = [];
  public currentStep: number = 0;
  public totalSteps: number = 0;
  public isSkipped: boolean = false;
  // empty defaults for functions
  constructor(
    public readonly titleTranslationKey: string,
    public readonly id: string,
    public readonly category: string,
    public readonly rank: number,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private beforeStart: () => void = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private afterEnd: () => void = () => {}
  ) {}

  public addStep(step: DXTourStep) {
    this.steps.push(step);
  }

  callBeforeStart() {
    this.beforeStart();
  }

  callAfterEnd() {
    this.afterEnd();
  }
}

export interface DXTourStepOptions {
  elementTourID?: string;
  imgPath?: string;
  scrollToElement?: boolean;
  positionPriority?: PositionPrioValues[];
  beforeStep?: () => void;
  afterEnd?: () => void;
}

export class DXTourStep {
  constructor(
    public readonly textTitleTranslationKey: string,
    public readonly textContentTranslationKey: string,
    public readonly options?: DXTourStepOptions
  ) {}
}

// ---- utility functions for the component

export type PositionPrioValues =
  | "bottom_right"
  | "bottom_left"
  | "top_right"
  | "top_left";

export const DEFAULT_POSITION_PRIORITIES = [
  "bottom_right",
  "bottom_left",
  "top_right",
  "top_left",
] as PositionPrioValues[];

export function getPopupPositionBounds(
  placement: PositionPrioValues,
  targetElement: Element,
  popup: HTMLDivElement,
  rightBar: HTMLDivElement,
  popupPositionPadding: number
): { x1: number; x2: number; y1: number; y2: number } {
  const targetBounds = targetElement.getBoundingClientRect();
  const popupBounds = popup.getBoundingClientRect();
  const rightBarBounds = rightBar.getBoundingClientRect();
  let x1 = 0;
  let y1 = 0;
  switch (placement) {
    case "bottom_left":
      x1 =
        targetBounds.left -
        popupBounds.width -
        popupPositionPadding +
        rightBarBounds.width;
      y1 = targetBounds.top + targetBounds.height + popupPositionPadding;
      break;
    case "bottom_right":
      x1 = targetBounds.left + targetBounds.width + popupPositionPadding;
      y1 = targetBounds.top + targetBounds.height + popupPositionPadding;
      break;
    case "top_left":
      x1 =
        targetBounds.left -
        popupBounds.width -
        popupPositionPadding +
        rightBarBounds.width;
      y1 = targetBounds.top - popupBounds.height - popupPositionPadding;
      break;
    case "top_right":
      x1 = targetBounds.left + targetBounds.width + popupPositionPadding;
      y1 = targetBounds.top - popupBounds.height - popupPositionPadding;
  }

  const x2 = x1 + popupBounds.width;
  const y2 = y1 + popupBounds.height;
  return { x1, y1, x2, y2 };
}

export function positionInbounds(
  position: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  },
  body: HTMLBodyElement
): boolean {
  const bodyBounds = body.getBoundingClientRect();
  return (
    position.x1 >= 0 &&
    position.y1 >= 0 &&
    position.x2 < bodyBounds.width &&
    position.y2 < bodyBounds.height
  );
}
