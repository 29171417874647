import { FCDataSource } from "@/components/views/MATHS/MathComponents";
import { DXDevice, DXVariable, PredefinedCalculation } from "@/types";

// The `options` object should be simplified to include only the necessary time options ['1h', '24h'].
// Anyways, the value of `timeOffsetValue` is not taken from the json instead it is set in code based on the option (e.g., 3600000ms for 1h, 86400000ms for 24h) selected.
// So, duplicating detailed placeholder entries for each time option in the placeholders would not be necessary.

export const predefinedCalculations: PredefinedCalculation[] = [
    {
        "id": 1,
        "uiId": "tank-deviation",
        "title": "math.predefined.calculation.1",
        "formulaExplanation": "math.predefined.formula.1.explanation",
        "imgPath": "/assets/img/math-predefined-calculations/deviation-tank-dosed-over-time.png",
        "deviceTypes": ['DFXA', 'GAMMAX', 'GAMMAXL', 'SIGMAB'],
        "formula": "math.predefined.calculation.formula.1",
        "calculation": [
            "(",
            {
                "rank": 1,
                "type": "sourcedef",
                "variable": "placeholder_device_liquid_level"              
            },
            "-",
            {
                "rank": 2,
                "type": "sourcedef",
                "variable": "placeholder_device_liquid_level"
            },
            ")",
            "-",
            {
                "rank": 3,
                "type": "sourcedef",
                "variable": "placeholder_device_dosed_amount_1h"
            }
        ],
        "options": {
            "1h": [
                {
                    "placeholderId": 6,
                    "type": "device-variable",        
                    "name": "Liquid level volume 1h",           
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.6",
                    "sameDeviceAsPlaceholder": 0,
                    "sameOptionAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 3600000, 
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1

                },
                {
                    "placeholderId": 5,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.1",
                    "sameDeviceAsPlaceholder": 6,
                    "sameOptionAsPlaceholder": 6,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 7,
                    "type": "device-variable",
                    "name": "Dosed amount 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.7",
                    "sameDeviceAsPlaceholder": 6,
                    "sameOptionAsPlaceholder": 6,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 3600000,
                    "deviceType": "DFXA",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 9,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.9",
                    "sameDeviceAsPlaceholder": 0,
                    "sameOptionAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 3600,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 8,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.8",
                    "sameDeviceAsPlaceholder": 9,
                    "sameOptionAsPlaceholder": 9,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 10,
                    "type": "device-variable",
                    "name": "Dosed amount 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.10",
                    "sameDeviceAsPlaceholder": 9,
                    "sameOptionAsPlaceholder": 9,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 12,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.12",
                    "sameDeviceAsPlaceholder": 0,
                    "sameOptionAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 3600,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 11,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.11",
                    "sameDeviceAsPlaceholder": 12,
                    "sameOptionAsPlaceholder": 12,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 13,
                    "type": "device-variable",
                    "name": "Dosed amount 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.13",
                    "sameDeviceAsPlaceholder": 12,
                    "sameOptionAsPlaceholder": 12,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 15,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.15",
                    "sameDeviceAsPlaceholder": 0,
                    "sameOptionAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 3600,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 14,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.14",
                    "sameDeviceAsPlaceholder": 15,
                    "sameOptionAsPlaceholder": 15,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 16,
                    "type": "device-variable",
                    "name": "Dosed amount 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.16",
                    "sameDeviceAsPlaceholder": 15,
                    "sameOptionAsPlaceholder": 15,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                }
            ],
            "24h": [
                {
                    "placeholderId": 18,
                    "type": "device-variable",
                    "name": "Liquid level volume 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.18",
                    "sameDeviceAsPlaceholder": 17,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 86400,
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 17,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.17",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 19,
                    "type": "device-variable",
                    "name": "Dosed amount 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.19",
                    "sameDeviceAsPlaceholder": 18,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "DFXA",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 21,
                    "type": "device-variable",
                    "name": "Liquid level volume 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.21",
                    "sameDeviceAsPlaceholder": 20,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 86400,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 20,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.20",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 22,
                    "type": "device-variable",
                    "name": "Dosed amount 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.22",
                    "sameDeviceAsPlaceholder": 21,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 24,
                    "type": "device-variable",
                    "name": "Liquid level volume 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.24",
                    "sameDeviceAsPlaceholder": 23,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 86400,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 23,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.23",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 25,
                    "type": "device-variable",
                    "name": "Dosed amount 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.25",
                    "sameDeviceAsPlaceholder": 24,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                },
                {
                    "placeholderId": 27,
                    "type": "device-variable",
                    "name": "Liquid level volume 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.27",
                    "sameDeviceAsPlaceholder": 26,
                    "timingType": "instant",
                    "timeOffsetType": "relative",
                    "timeOffsetValue": 86400,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 26,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.26",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 28,
                    "type": "device-variable",
                    "name": "Dosed amount 24h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.28",
                    "sameDeviceAsPlaceholder": 27,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 24320,
                    "subIndex": 255,
                    "imgPath": "",
                    "rank": 3
                }
            ]
        }
    },
    {
        "id": 2,
        "uiId": "tank-difference-over-time",
        "title": "math.predefined.calculation.2",
        "formulaExplanation": "math.predefined.formula.2.explanation",
        "imgPath": "/assets/img/math-predefined-calculations/tank-difference-over-time.png",
        "formula": "math.predefined.calculation.formula.2",
        "deviceTypes": ['DFXA', 'GAMMAX', 'GAMMAXL', 'SIGMAB'],
        "calculation": [
            {
                "rank": 1,
                "type": "sourcedef",
                "variable": "placeholder_device_liquid_level_absolute"
            },
            "-",
            {
                "rank": 2,
                "type": "sourcedef",
                "variable": "placeholder_device_liquid_level_current"
            }
        ],
        "options": {
            "group1": [
                {
                    "placeholderId": 32,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.32",
                    "sameDeviceAsPlaceholder": 0,
                    "sameOptionAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "absolute",
                    "timeOffsetValue": 0,
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 33,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.33",
                    "sameDeviceAsPlaceholder": 32,
                    "sameOptionAsPlaceholder": 32,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "DFXA",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 34,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.32",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "absolute",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 35,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.33",
                    "sameDeviceAsPlaceholder": 34,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAX",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 36,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.32",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "absolute",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 37,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.33",
                    "sameDeviceAsPlaceholder": 36,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "GAMMAXL",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                },
                {
                    "placeholderId": 38,
                    "type": "device-variable",
                    "name": "Liquid level volume 1h",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.32",
                    "sameDeviceAsPlaceholder": 0,
                    "timingType": "instant",
                    "timeOffsetType": "absolute",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 1
                },
                {
                    "placeholderId": 39,
                    "type": "device-variable",
                    "name": "Liquid level volume",
                    "descriptionLocizeKey": "math.predefined.calculation.placeholder.33",
                    "sameDeviceAsPlaceholder": 38,
                    "timingType": "instant",
                    "timeOffsetType": "current",
                    "timeOffsetValue": 0,
                    "deviceType": "SIGMAB",
                    "index": 1000000,
                    "subIndex": 3,
                    "imgPath": "",
                    "rank": 2
                }
            ]
        }
    },
];

export const mathSymbols = ['+', '-', 'x', '/', '|', '(', ')'];

export const deviceForTour: DXDevice = {
    id: 1935,
    type: 'GAMMAX_1935',
    sn: '2016119571',
    name: 'gamma/ X with DULCOLEVEL',
    identcode: 'GMXA1604NPB00000UA10R0BEN',
    notify: 1,
    valueSmoothing: 1,
    online: true,
    site: null,
    tags: ['Default'],
    added: ""
  };
  
export const dosingVariableForTour: DXVariable = {
    AccessLevel: 1,
    alarmrelevant: 1,
    decimals: 2,
    deviceType: 'GAMMAX_1935',
    group: 'Performance',
    index: 24320,
    mainrank: 1,
    name: 'Dosed Amount [1h]',
    rank: 92,
    showpreview: 1,
    subindex: 255,
    unit: 'l',
    whitelisted: 1,
    writable: false,
  };
  
export const liquidLevelVariableForTour: DXVariable = {
    AccessLevel: 1,
    alarmrelevant: 0,
    decimals: 1,
    deviceType: 'GAMMAX_1935',
    group: 'Liquid level',
    index: 1000000,
    mainrank: 0,
    name: 'Liquid level, volume',
    rank: 105,
    showpreview: 1,
    subindex: 3,
    unit: 'l',
    whitelisted: 1,
    writable: false,
  };

  export const liquidLevelVolume1hDataSource = new FCDataSource();
  
  liquidLevelVolume1hDataSource.device = deviceForTour;
  liquidLevelVolume1hDataSource.variable = liquidLevelVariableForTour;
  liquidLevelVolume1hDataSource.relativeOffsetMS = 3600000;
  liquidLevelVolume1hDataSource.absoluteDate = -1; 
  liquidLevelVolume1hDataSource.valueType = 'instant';
  liquidLevelVolume1hDataSource.timingtype = 1;

  export const liquidLevelVolumeCurrentDataSource = new FCDataSource();
  
  liquidLevelVolumeCurrentDataSource.device = deviceForTour;
  liquidLevelVolumeCurrentDataSource.variable = liquidLevelVariableForTour;
  liquidLevelVolumeCurrentDataSource.relativeOffsetMS = 3600000;
  liquidLevelVolumeCurrentDataSource.absoluteDate = -1; 
  liquidLevelVolumeCurrentDataSource.valueType = 'instant';
  liquidLevelVolumeCurrentDataSource.timingtype = 0;  

  export const dosedAmount1hDataSource = new FCDataSource();

  dosedAmount1hDataSource.device = deviceForTour;
  dosedAmount1hDataSource.variable = dosingVariableForTour;
  dosedAmount1hDataSource.relativeOffsetMS = 3600000;
  dosedAmount1hDataSource.absoluteDate = -1; 
  dosedAmount1hDataSource.valueType = 'instant';
  dosedAmount1hDataSource.timingtype = 1;
  
