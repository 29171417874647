<template>
  <FullPageModal
    :isOpen="addDeviceModalOpen"
    :onCloseModal="() => (addDeviceModalOpen = false)"
    :headerText="($t('modals.modal_device_add_title') as string)"
  >
    <AddDeviceForm @reset="addDeviceModalOpen = false" />
  </FullPageModal>
</template>

<script setup lang="ts">
import { useUISettingsStore } from "@/storeModules";
import { storeToRefs } from "pinia";
import FullPageModal from "../library/FullPageModal/FullPageModal.vue";
import AddDeviceForm from "../elements/AddDeviceForm/AddDeviceForm.vue";

const { addDeviceModalOpen } = storeToRefs(useUISettingsStore());

</script>
