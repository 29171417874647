import { CardSize } from "@/api/types";
import { WidgetHandler } from "./WidgetHandler";

export class CardHandler {
  constructor(
    public id: string,
    public dashboardID: string,
    public title: string,
    public size: CardSize,
    public widgets: WidgetHandler[]
  ) {}
  /**
   * Saves the widget to the state.
   * Should be called after the widget has been saved to the BE
   * @param widget
   */
  addWidget(widget: WidgetHandler) {
    this.widgets.push(widget);
  }
  /**
   * Removes the widget from the state
   * Should be called after the widget has been removed from the BE
   * @param widget
   */
  removeWidget(widget: WidgetHandler) {
    this.widgets = this.widgets.filter((w) => w.widgetID != widget.widgetID);
  }
}
