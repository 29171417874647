import { useDeviceStore } from "@/storeModules";
import { DXDevice, DXAlarm, DXIssue } from "@/types";

export type NavigationMenu = NavigationToplevelItem[];

export type NavigationToplevelItem = NTIBasic | NTIDevices | NTINested;

export abstract class NTIBase {
  constructor(
    public i18nkey: string,
    public logo: string,
    public children: SecondLevelNavigationItem[],
    public navigationPathName: string
  ) {}
  public abstract readonly type: string;
}

export type SecondLevelNavigationItem = {
  i18nkey: string;
  targetRouteName: string;
};

export class NTIBasic extends NTIBase {
  constructor(i18nkey: string, logo: string, linkPathName: string) {
    super(i18nkey, logo, [], linkPathName);
  }
  public readonly type = "BASIC";
}

export class NTIDevices extends NTIBase {
  constructor(
    public devicesByTags: ReturnType<
      typeof useDeviceStore
    >["getDevicesGroupByTags"],
    public alarms: DXAlarm[],
    public issues: { [key: number]: DXIssue[] }
  ) {
    super("navi.item_devices", "hard-drive", [], "Device List");
  }
  public readonly type = "DEVICE";
}

export class NTINested extends NTIBase {
  constructor(
    i18nkey: string,
    logo: string,
    children: SecondLevelNavigationItem[],
    linkPathName: string
  ) {
    super(i18nkey, logo, children, linkPathName);
  }
  public readonly type = "NESTED";
}
