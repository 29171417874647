<template>
  <nav
    class="tw-flex tw-flex-row tw-items-stretch tw-justify-start tw-flex-wrap tw-flex-shrink tw-text-xs md:tw-text-base"
  >
    <div
      v-for="(item, idx) in menuItems"
      class="dropdown tw-inline-block tw-relative tw-z-0"
      :key="idx"
    >
      <router-link
        :key="item.title"
        :to="item.href"
        :class="colorShades[idx]"
        class="tw-text-white tw-font-semibold tw-px-2 tw-py-4 tw-inline-flex tw-items-center tw-border-gray-200 tw-border-solid hover:tw-bg-secondary-500 hover:tw-text-white tw-h-full"
        ><font-awesome-icon
          v-if="item.icon"
          :icon="['fas', item.icon]"
          class=""
        />
        <span v-if="item.translationKey">{{ $t(item.translationKey) }}</span>
      </router-link>
      <ul
        v-if="item.subItems && item.subItems.length"
        class="dropdown-menu tw-absolute tw-hidden tw-p-0 tw-m-0"
      >
        <li
          v-for="(subItem, subIdx) in item.subItems"
          :key="subIdx"
          class="tw-w-auto tw-mx-auto tw-z-40"
        >
          <router-link
            :key="subItem.title"
            :to="subItem.href"
            :class="colorShades[idx]"
            class="tw-text-xs md:tw-text-base tw-text-white tw-font-semibold tw-px-2 tw-py-4 tw-inline-flex tw-items-center tw-justify-center tw-border-gray-200 tw-border-solid hover:tw-bg-secondary-500 hover:tw-text-white tw-w-full"
            ><font-awesome-icon
              v-if="subItem.icon"
              :icon="['fas', subItem.icon]"
            />
            <span v-if="subItem.translationKey">{{
              $t(subItem.translationKey)
            }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
<script setup lang="ts">
type MenuItem = {
  title: string;
  active: boolean;
  href: string;
  icon?: string;
  translationKey?: string;
};

const menuItems: (MenuItem & { subItems?: MenuItem[] })[] = [
  {
    title: "",
    href: "/welcome",
    active: false,
    icon: "house",
    translationKey: "",
  },
  {
    title: "Portfolio",
    href: "#section-portfolio",
    active: false,
    translationKey: "navi.subnavi_portfolio",
  },
  {
    title: "Platform",
    href: "#section-platform",
    active: false,
    translationKey: "navi.subnavi_platform",
    subItems: [
      {
        title: "Features & Benefits",
        href: "#section-platform-features-and-benefits",
        active: false,
        translationKey: "navi.subnavi_features_benefits",
      },
      {
        title: "Use Cases",
        href: "#platform-section-use-cases",
        active: false,
        translationKey: "navi.subnavi_use_cases",
      },
    ],
  },
  {
    title: "Inventory Management",
    href: "#section-inventory-management",
    active: false,
    translationKey: "navi.subnavi_inventory_management",
    subItems: [
      {
        title: "Features & Benefits",
        href: "#section-im-features-and-benefits",
        active: false,
        translationKey: "navi.subnavi_features_benefits",
      },
    ],
  },
];

const colorShades = [
  "tw-bg-primary-500",
  "tw-bg-primary-400",
  "tw-bg-primary-300",
  "tw-bg-primary-200",
];
</script>
