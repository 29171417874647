<template>
  <dialog
    class="tw-w-full tw-h-auto md:tw-max-h-[60%] tw-max-w-full tw-p-0 tw-mt-auto tw-mx-auto tw-mb-0 tw-border-0"
    ref="dialogRef"
  >
    <div
      class="tw-sticky tw-top-0 tw-flex tw-flex-row tw-items-center tw-justify-between"
    >
      <button
        autofocus
        @click="close"
        class="tw-bg-white hover:tw-bg-gray-100 tw-rounded-full tw-p-4"
      >
        <font-awesome-icon icon="times" />
      </button>

      <LanguageSelector />
    </div>
    <div class="tw-p-4 tw-w-full">
      <h2 class="tw-text-center tw-p-4" v-html="$t('consent.title')"></h2>
      <p
        class="tw-mx-auto tw-w-full md:tw-w-2/3"
        v-html="$t('consent.main_description')"
      ></p>
    </div>
    <ConsentList @save="save" :stickyButtons="true" />
  </dialog>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useUISettingsStore } from "@/storeModules";
import { getConsentList } from "@/constants/consentRequiredServicesList";
import ConsentList from "../ConsentList/ConsentList.vue";
import LanguageSelector from "@/components/layout/LanguageSelector/LanguageSelector.vue";

const uiStore = useUISettingsStore();
const dialogRef = ref<null | HTMLDialogElement>(null);

const selection = computed(() => uiStore.userConsent);
const isDXOrange = "ReactNativeWebView" in window;
const SERVICES_LIST = getConsentList(isDXOrange);

const close = () => {
  if (dialogRef.value) {
    dialogRef.value.close();
    uiStore.resetUserConsent();
  }
};
const save = () => {
  if (dialogRef.value) {
    dialogRef.value.close();
    uiStore.setUserConsent(selection.value);
  }
};
onMounted(() => {
  const rememberChoice = localStorage.getItem("rememberConsentChoice");
  if (rememberChoice === "true") {
    SERVICES_LIST.forEach((service) => {
      const previousSelection = localStorage.getItem(service.id);
      if (previousSelection !== null) {
        selection.value[service.id] = previousSelection === "true";
      }
      uiStore.setUserConsent(selection.value);
    });
  }
  if (!rememberChoice && dialogRef.value) {
    dialogRef.value.showModal();
  }
});
</script>
<style scoped>
::backdrop {
  background-color: black;
  margin: auto 0 0 0;
  padding: 1rem;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}
</style>
