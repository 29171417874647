<template>
  <div>
    <div v-if="!success">
      <form
        id="supportForm"
        @submit.prevent="handleSubmit"
        @reset.prevent="$emit('reset')"
      >
        <BaseInput
          input-type="text"
          :placeholder="($t('modals.support_form_placeholder_name') as string)"
          inputId="name"
          :label="($t('modals.support_form_label_name') as string)"
          v-model="formValue.name"
          required
          autocomplete="name"
          :isValid="formValidation.name"
        />
        <BaseInput
          inputType="email"
          :label="($t('modals.support_form_label_mail') as string)"
          :placeholder="($t('modals.support_form_placeholder_mail') as string)"
          inputId="email"
          v-model="formValue.mail"
          required
          :isValid="formValidation.mail"
        />
        <BaseInput
          inputType="tel"
          :label="($t('modals.support_form_label_phone') as string)"
          :placeholder="($t('modals.support_form_label_phone') as string)"
          inputId="phone"
          v-model="formValue.phone"
          autocomplete="tel"
          :isValid="formValidation.phone"
        />

        <BaseTextArea
          :label="($t('modals.support_form_label_message')as string)"
          :placeholder="($t('modals.support_form_placeholder_message') as string)"
          :rows="4"
          inputId="message"
          v-model="formValue.message"
          :isValid="formValidation.message"
        />

        <BaseSelect
          type="radio"
          :label="($t('modals.support_form_label_contact') as string)"
          :options="[
            {
              value: 'Email',
              text: $t('modals.support_form_radio_mail'),
            },
            {
              value: 'Phone',
              text: $t('modals.support_form_radio_phone'),
            },
          ]"
          v-model="formValue.contactBy"
          inputId="contactBy"
          :isValid="formValidation.contactBy"
        />

        <BaseInput
          :label="($t('modals.support_form_label_agreement') as string)"
          inputType="checkbox"
          v-model="formValue.consent"
          :checked="formValue.consent"
          inputId="consent"
          :secondaryLabel="($t('modals.support_form_placeholder_agreement') as string)"
          :isValid="formValidation.consent"
        />
      </form>
      <div class="tw-flex tw-justify-end tw-space-x-2 tw-mt-8">
        <BaseButton
          variant="neutral"
          :text="($t('modals.support_form_button_cancel') as string)"
          eventType="reset"
          form="supportForm"
        />
        <BaseButton
          icon="paper-plane"
          variant="primary"
          :text="($t('modals.support_form_button_send') as string)"
          eventType="submit"
          form="supportForm"
          :disabled="!formValue.consent"
          :isWaiting="submitState"
        />
      </div>
      <div v-if="errors.length > 0">
        <div
          v-for="error in errors"
          :key="error.message"
          class="tw-text-status-red-500"
        >
          {{ error.message }}
        </div>
      </div>
    </div>

    <div
      v-if="success"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-space-y-8"
    >
      <FontAwesomeIcon
        icon="circle-check"
        class="tw-text-8xl tw-text-status-green"
      />
      <p
        class="tw-my-4 tw-text-center tw-text-2xl tw-font-bold"
        v-html="$t('modals.support_form_button_success_headline')"
      ></p>
      <p v-html="$t('modals.support_form_button_success_text')"></p>
      <BaseButton variant="primary" text="OK" @click="$emit('reset')" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import BaseButton from "../../library/BaseButton/BaseButton.vue";
import BaseInput from "../../library/BaseInput/BaseInput.vue";
import BaseTextArea from "../../library/BaseTextArea/BaseTextArea.vue";
import BaseSelect from "../../library/BaseSelect/BaseSelect.vue";
import { t } from "i18next";
import { useGlobalConfigsStore, useUISettingsStore } from "@/storeModules";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const globalStore = useGlobalConfigsStore();
const uiStore = useUISettingsStore();

const CONNECTOR = globalStore.connector;
const submitState = ref<boolean>(false);

const formValue = ref({
  name: "",
  mail: "",
  phone: "",
  message: "",
  contactBy: "Email",
  consent: false,
});

const formValidation = ref<{ [key: string]: boolean | undefined }>({
  name: undefined,
  mail: undefined,
  phone: undefined,
  message: undefined,
  contactBy: undefined,
  consent: undefined,
});

const errors = ref<Array<{ message: string }>>([]);

const validateFormData = () => {
  formValidation.value = {
    name: true,
    mail: true,
    phone: true,
    message: true,
    contactBy: true,
    consent: true,
  };
  if (formValue.value.contactBy === "Phone" && !formValue.value.phone) {
    errors.value.push({
      message: t("validation.missing_field", {
        inputName: t("inquiry.input-phone"),
      }),
    });
    formValidation.value.phone = false;
  }
  if (!formValue.value.message || formValue.value.message.length < 50) {
    errors.value.push({
      message: t("validation.min_length", {
        inputName: t("inquiry.input-message"),
        minLengthValue: 50,
      }),
    });
    formValidation.value.message = false;
  }

  return errors;
};

const success = ref<boolean>(false);
const handleSubmit = async () => {
  submitState.value = true;
  errors.value = [];
  validateFormData();
  if (errors.value.length > 0) {
    submitState.value = false;
    success.value = false;
    return;
  }

  submitState.value = true;

  try {
    const message = `Name: ${formValue.value.name}\nMail: ${formValue.value.mail}\nPhone: ${formValue.value.phone}\n\nAgreed to contact: ${formValue.value.contactBy}\nMessage:\n ${formValue.value.message}`;
    if (CONNECTOR) {
      await CONNECTOR.general.contactService({
        device: 0,
        message: message,
      });
      success.value = true;
    }
  } catch (error) {
    uiStore.setError({ message: JSON.stringify(error), type: "error" });
  } finally {
    submitState.value = false;
  }
};
</script>
