<template>
  <form
    id="formAddDevice"
    @submit.prevent="handleSubmit"
    @reset="emit('cancel')"
  >
    <div class="tw-flex" v-if="userStore.hasMathFunctionality">
      <div class="tw-w-full">
        <GuidedTourTarget targetID="add-device-physical-button">
          <BaseButton
            class="tw-w-full tw-h-16"
            :variant="currentTab === 'physical' ? 'primary' : 'neutral'"
            @click="uiStore.setCurrentDeviceTab('physical')"
            :text="($t('modals.modal_device_add_label_device') as string)"
          />
        </GuidedTourTarget>
      </div>
      <div class="tw-w-full" data-tour-id="add-device-virtual-button">
        <GuidedTourTarget targetID="add-device-virtual-button">
          <BaseButton
            class="tw-w-full tw-h-16"
            :variant="currentTab === 'virtual' ? 'primary' : 'neutral'"
            @click="uiStore.setCurrentDeviceTab('virtual')"
            :text="($t('modals.modal_device_add_label_virtual_device') as string)"
          />
        </GuidedTourTarget>
      </div>
    </div>
    <!-- Add Device -->
    <div v-if="currentTab == 'physical'">
      <BaseInput
        input-type="text"
        :placeholder="$t('modals.modal_device_add_placeholder_name') as string"
        input-id="device-name"
        :label="($t('modals.modal_device_add_label_name') as string)"
        v-model="deviceName"
        required
        data-test="device-name"
      />
      <BaseSelect
        :label="($t('modals.modal_device_add_label_type') as string)"
        input-id="device-type"
        :options="options"
        v-model="deviceType"
        :placeHolderText="($t('modals.modal_device_add_placeholder_type') as string)"
        required
        data-test="device-type"
      />
      <BaseInput
        input-type="text"
        :placeholder="($t('modals.modal_device_add_placeholder_serial') as string)"
        input-id="serial-number"
        :label="($t('modals.modal_device_add_label_serial') as string)"
        v-model="serialNumber"
        required
        data-test="device-serial-number"
      />
      <BaseInputAutofill
        input-type="text"
        :placeholder="($t('modals.modal_device_add_label_tag') as string)"
        input-id="tag"
        :label="($t('modals.modal_device_add_label_tag') as string)"
        v-model="tag"
        :autocomplete-list="tags"
        :open-list-on-focus="true"
        data-test="device-tag"
      />
    </div>
    <!-- Virtual devices -->
    <div v-else-if="currentTab === 'virtual'">
      <BaseInput
        input-type="text"
        :placeholder="$t('modals.modal_device_add_placeholder_name') as string"
        input-id="device-name"
        :label="($t('modals.modal_device_add_label_name') as string)"
        v-model="deviceName"
        required
        data-test="device-name"
      />
      <BaseLabel
        input-id="newVDeviceToggle"
        :label="($t('modals.device_add_label_new_virtual_device') as string)"
        ><div class="tw-w-32">
          <GuidedTourTarget targetID="add-device-new-virtual-switch">
            <BaseToggleSlider
              class="tw-py-2 tw-my-4"
              id="newVDeviceToggle"
              v-model="createNewVirtualDevice"
              data-interactive="true"
            />
          </GuidedTourTarget>
        </div>
      </BaseLabel>
      <BaseInput
        input-type="text"
        :placeholder="((createNewVirtualDevice?$t('modals.modal_device_add_placeholder_virtual_sn_note'):$t('modals.modal_device_add_placeholder_serial')) as string)"
        input-id="serial-number"
        :label="($t('modals.modal_device_add_label_serial') as string)"
        :model-value="createNewVirtualDevice ? '' : serialNumber"
        @update:model-value="(e) => (serialNumber = e)"
        required
        data-test="device-serial-number"
        :disabled="createNewVirtualDevice"
      />
      <BaseInputAutofill
        input-type="text"
        :placeholder="($t('modals.modal_device_add_label_tag') as string)"
        input-id="tag"
        :label="($t('modals.modal_device_add_label_tag') as string)"
        v-model="tag"
        :autocomplete-list="tags"
        :open-list-on-focus="true"
        data-test="device-tag"
      />
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-stretch sm:tw-flex-row sm:tw-justify-end sm:tw-items-start tw-space-y-2 sm:tw-space-x-2 sm:tw-space-y-0"
    >
      <BaseButton
        variant="neutral"
        :text="($t('modals.modal_device_add_button_cancel') as string)"
        eventType="reset"
        form="formAddDevice"
        data-test="modal-device-add-button-cancel"
      />
      <BaseButton
        icon="floppy-disk"
        variant="primary"
        :text="($t('modals.modal_device_add_button_submit') as string)"
        eventType="submit"
        form="formAddDevice"
        :isWaiting="isWaiting"
        data-test="modal-device-add-button-submit"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed } from "vue";
import BaseButton from "../../library/BaseButton/BaseButton.vue";
import BaseInput from "../../library/BaseInput/BaseInput.vue";
import BaseSelect from "../../library/BaseSelect/BaseSelect.vue";
import BaseInputAutofill from "@/components/library/BaseInputAutofill/BaseInputAutofill.vue";
import {
  useDeviceStore,
  useUISettingsStore,
  useUserConfigStore,
} from "@/storeModules";
import { ref } from "vue";
import { SelectOptions } from "@/components/library/BaseSelect/BaseSelectUtils";
import { DXDeviceType } from "@/types";
import BaseToggleSlider from "@/components/library/BaseToggleSlider/BaseToggleSlider.vue";
import BaseLabel from "@/components/library/BaseLabel/BaseLabel.vue";
import { useRoute } from "vue-router";
import GuidedTourTarget from "@/components/library/GuidedTour/GuidedTourTarget.vue";

defineProps({ isWaiting: Boolean });

const deviceStore = useDeviceStore();
const userStore = useUserConfigStore();
const uiStore = useUISettingsStore();
const route = useRoute();
const activeTag = computed(() =>
  route.params.tag?.toString() !== "no-tag" ? route.params.tag?.toString() : ""
);

const deviceName = ref("");
const deviceType = ref("");
const serialNumber = ref("");
const tag = ref(activeTag.value);
const createNewVirtualDevice = ref(true);
const currentTab = computed(() => uiStore.addDeviceDataEntryCurrentTab);

const options = ref<SelectOptions[]>(
  (deviceStore.devicesList as DXDeviceType[]).map((t) => ({
    value: t.type,
    text: t.displayName,
  }))
);

const tags = computed(() => {
  return deviceStore.getTags;
});

const emit = defineEmits<{
  (
    e: "addExistingDevice",
    details: { name: string; type: string; serialNumber: string; tag: string }
  ): void;
  (e: "createVirtualDevice", details: { name: string; tag: string }): void;
  (e: "cancel"): void;
}>();

function handleSubmit() {
  if (currentTab.value === "physical") {
    emit("addExistingDevice", {
      name: deviceName.value,
      type: deviceType.value,
      serialNumber: serialNumber.value,
      tag: tag.value,
    });
    return;
  }
  if (currentTab.value == "virtual" && createNewVirtualDevice.value) {
    emit("createVirtualDevice", { name: deviceName.value, tag: tag.value });
  } else {
    emit("addExistingDevice", {
      name: deviceName.value,
      type: "virtual",
      serialNumber: serialNumber.value,
      tag: tag.value,
    });
  }
}
</script>
