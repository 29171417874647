import { DXCompletedTourInfo, DXNews } from "@/types";
import { asyncCall, asyncOriginalCall } from "./call";
import {
  DeviceIdentifiers,
  ParsingResult,
  ReportSettings,
  ServerResponse,
  Settings,
  SparePartOrder,
} from "./types";
import { Token } from "@/components/views/MATHS/BackendSchema";
import { WLConfigData } from "@/components/views/WLPOC/types";

export class GeneralConnector {
  private sessionID: string;
  private urlPrefix: string;

  constructor(urlPrefix: string, sessionID: string) {
    this.urlPrefix = urlPrefix;
    this.sessionID = sessionID;
  }

  /**
   * Saving report settings
   * @target "api/set-report-settings"
   * @param reportSettings
   * @returns
   */
  async setReportSettings(reportSettings: ReportSettings) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-report-settings",
      { session: this.sessionID },
      reportSettings
    );
    return result;
  }

  /**
   * Service contact form handler.
   * @target "/api/contact-service"
   * @param options device id and message
   * @returns
   */
  async contactService(options: {
    device: DeviceIdentifiers["device"];
    message: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/contact-service",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Sends an email with the spare part list. It doesn't do any orders.
   * @target "/api/order-spare-parts"
   * @param options
   * @returns
   */
  async orderSpareParts(options: {
    device: DeviceIdentifiers["device"];
    email: string;
    items: SparePartOrder[];
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/order-spare-parts",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Sets the user's preferred language into the backend.
   * @target "/api/set-language"
   * @param options language
   * @returns
   */
  async setLanguage(options: { language: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-language",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Changes password for the user. Both old and new passwords are required.
   * @target "/api/set-password"
   * @param options old, new
   * @returns
   */
  async setPassword(options: { old: string; new: string }) {
    const result: ServerResponse = await asyncOriginalCall(
      this.urlPrefix + "/api/set-password",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Used for setting the user's timezone and email.
   * @target "/api/set-settings"
   * @param options  email, timezone
   * @returns
   */
  async setSettings(options: { email: string; timezone: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-settings",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Returns report settings.
   * @target "/api/get-report-settings"
   * @returns
   */
  async getReportSettings() {
    const result: ServerResponse | ReportSettings = await asyncCall(
      this.urlPrefix + "/api/get-report-settings",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * Gets the user's settings.
   * @target "/api/get-settings"
   * @returns userid, email, timezone, language
   */
  async getSettings() {
    const result: ServerResponse | Settings = await asyncCall(
      this.urlPrefix + "/api/get-settings",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * Gets whats new content
   * @target "/api/get-whats-new-content"
   * @returns server response or DXNews[]
   */
  async getWhatsNewContent() {
    const result: ServerResponse | DXNews[] = await asyncCall(
      this.urlPrefix + "/api/get-whats-new-content",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * Marks what's new content as seen
   * @target "/api/set-whats-new-notification-seen"
   * @param options
   * @returns
   */
  async setWhatsNewNotificationSeen(options: { whats_new_content_id: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-whats-new-notification-seen",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  async parseMathTokens(options: { tokens: Token[] }) {
    // form encode data since this can get quite large
    const formdata = new FormData();
    formdata.append("tokens", JSON.stringify(options.tokens));
    const result: ServerResponse | ParsingResult = await asyncCall(
      this.urlPrefix + "/api/math/parse-tokens",
      { session: this.sessionID },
      formdata
    );
    return result;
  }

  async setTourInfo(options: { guidedTourUiId: string, name: string, section: string, totalSteps: number, currentStep: number, rank: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-user-guided-tour",
      { session: this.sessionID, ...options }
    );
    return result;
  }

  async getSavedOrCompletedTour() {
     const result: DXCompletedTourInfo[] = await asyncCall(
       this.urlPrefix + "/api/get-user-guided-tours",
       {
         session: this.sessionID,
       }
     );
    return result;
  }

  async createWhitelabel(wlInfo: WLConfigData) {
    const result: ServerResponse | boolean = await asyncCall(
      this.urlPrefix + "/api/create-white-label",
      { session: this.sessionID },
      wlInfo
    );
    return result;
  }
  async getWhitelabel(brandQuery: string) {
    const result: ServerResponse | WLConfigData = await asyncCall(
      this.urlPrefix + "/api/get-whitelabel",
      { session: this.sessionID, brandQuery }
    );
    return result;
  }
}
