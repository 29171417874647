<template>
  <div
    class="tw-hidden md:tw-visible md:tw-block tw-sticky tw-top-0 tw-left-0 tw-z-40"
  >
    <PublicNavigation :menu-items="menuItems" :brand="globalStore.brand" />
  </div>
  <div class="tw-sticky tw-top-0 tw-left-0 md:tw-top-16 tw-z-40">
    <div class="tw-sticky tw-top-0 tw-left-0 md:tw-hidden">
      <PublicMobileMenu :menu-items="menuItems" :brand="globalStore.brand" />
    </div>
    <div
      class="tw-sticky tw-bottom-0 tw-left-20 md:tw-left-28 tw-h-auto tw-w-5/6 md:tw-w-2/3 -tw-z-40"
      v-if="$route.name === 'Welcome'"
    >
      <PublicSubMenu />
    </div>
  </div>
</template>
<script setup lang="ts">
import PublicSubMenu from "../PublicSubMenu/PublicSubMenu.vue";
import PublicMobileMenu from "../PublicMobileMenu/PublicMobileMenu.vue";
import PublicNavigation from "../PublicNavigation/PublicNavigation.vue";
import { useGlobalConfigsStore } from "@/storeModules";
import { computed } from "vue";

const globalStore = useGlobalConfigsStore();
const menuItems = computed(() => globalStore.menuItemsByWL);
</script>
