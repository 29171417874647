<template>
  <div class="breadcrumbs animate-400">
    <ul class="list-style-none list-inline" id="breadcrumbs">
      <li>
        <RouterLink :to="{ name: 'Dashboard' }">
          <FontAwesomeIcon icon="house" />
        </RouterLink>
      </li>

      <li
        :key="breadcrumb.name"
        v-for="(breadcrumb, index) in breadcrumbs"
        @click="
          index < breadcrumbs.length - 1 ? $router.push(breadcrumb.path) : null
        "
      >
        {{ breadcrumb.name }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import i18next from "i18next";
import { Ref, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { breadCrumbDecider, getDaytime, BreadCrumb } from "./BreadCrumbUtils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const breadcrumbs: Ref<BreadCrumb[]> = ref([]);

const currentRoute = useRoute();

const initialiseCrumbs = () => {
  const now = new Date();
  breadcrumbs.value = breadCrumbDecider(currentRoute, getDaytime(now));
};

onMounted(() => {
  initialiseCrumbs();
});

watch(
  () => [currentRoute.name, currentRoute.query, currentRoute.params],
  () => {
    initialiseCrumbs();
  }
);
i18next.on("languageChanged", () => {
  initialiseCrumbs();
});
i18next.on("initialized", () => {
  initialiseCrumbs();
});
</script>
<style scoped>
.breadcrumbs .list-inline {
  margin-top: 0;
}

.breadcrumbs .list-inline li {
  color: #01305c;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  word-break: break-word;
  opacity: 0.4;
  cursor: pointer;
}

.breadcrumbs .list-inline li:before {
  content: " /";
  margin-right: 8px;
  color: #01305c;
  opacity: 0.4;
  white-space: pre;
}

.breadcrumbs .list-inline li:first-child {
  font-size: 1rem;
  padding-left: 4px;
}

.breadcrumbs .list-inline li:first-child:before {
  content: none;
}

.breadcrumbs .list-inline li a {
  font-weight: 700;
  text-transform: uppercase;
  color: #01305c;
  opacity: 0.4;
}

.breadcrumbs .list-inline li:hover a:hover,
.breadcrumbs .list-inline li:first-child:hover {
  opacity: 1;
}

.breadcrumbs .list-inline li:last-child {
  opacity: 1;
  cursor: default;
}

.breadcrumbs .list-inline li:hover {
  opacity: 1;
}
</style>
