export type ContextMenuItem = {
  translationKey: string;
  id: string;
  icon?: string;
};

/**
 * Stolen from jQuerry source
 * @returns document width
 */
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}
/**
 * Stolen from jQuerry source
 * @returns document height
 */
function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

export function getPageDimensions() {
    return {width:getWidth(), height:getHeight()}
}

export interface MenuPosition {
    left: number;
    top: number;
  }
  
export  function calculateMenuPosition(
    openButtonBCR: DOMRect,
    pageDimensions: { width: number; height: number },
    menuDimensions: { width: number; height: number },
    offsetLeft: number,
    offsetTop: number
  ): MenuPosition | null {
    const start: MenuPosition = { left: offsetLeft, top: offsetTop };
    const end: MenuPosition = {
      left: start.left + menuDimensions.width,
      top: start.top + menuDimensions.height,
    };
  
    if (
      end.left + openButtonBCR.left < pageDimensions.width &&
      end.top + openButtonBCR.top < pageDimensions.height
    ) {
      return start;
    }
  
    return null;
  }
