export const clearLocalStorage = () => {
  localStorage.removeItem("prominent.session");
  localStorage.removeItem("prominent.user");
  localStorage.removeItem("prominent.user.email");
  localStorage.removeItem("prominent.user.level");
  localStorage.removeItem("prominent.access.platform");
  localStorage.removeItem("prominent.access.im");
  localStorage.removeItem("prominent.tmp");
  localStorage.removeItem("prominent.backlink");
  localStorage.removeItem("prominent.userData");
};
