<template>
  <div
    tabindex="0"
    ref="modal"
    v-if="isOpen"
    class="tw-fixed tw-overflow-scroll tw-no-scrollbar tw-bg-black tw-bg-opacity-50 tw-z-50 sm:tw-p-8 tw-p-2 tw-cursor-pointer tw-flex tw-top-0 tw-left-0 tw-w-full tw-h-full"
    @click.prevent.stop="onBackgroundClick"
  >
    <div
      @click.stop
      class="md:tw-w-4/6 tw-h-auto tw-rounded-lg tw-bg-slate-50 tw-m-auto tw-cursor-default"
    >
      <div
        class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-border-gray-500 tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-py-2 tw-border-opacity-50"
      >
        <span class="tw-text-lg tw-px-4 tw-py-2 tw-gap-2 tw-font-bold">
          <font-awesome-icon
            v-if="header"
            class="my-auto tw-mr-2"
            :icon="header.icon"
            :style="{ color: header.iconColor }"
          />
          {{ headerText }}

          <HelpIconTooltip v-if="tooltipText" :text="tooltipText" />
        </span>
        <button
          v-if="!isGuidedTour"
          @click="emit('closeModal')"
          data-test="modal-close"
          class="tw-cursor-pointer tw-px-4 tw-text-lg tw-font-bold btn-close"
        ></button>
      </div>
      <div class="sm:tw-p-8 tw-p-2 tw-w-full">
        <slot></slot>
      </div>
      <div
        v-if="isGuidedTour"
        class="tw-flex tw-justify-end tw-space-x-2 tw-p-2"
      >
        <BaseButton
          v-if="!tourStore.isLastStep"
          variant="neutral"
          size="medium"
          :text="($t('modals.tour_label_skip') as string)"
          @click="emit('skip')"
          data-test="tour-skip"
        >
        </BaseButton>
        <BaseButton
          v-if="!isTourComplete || tourStore.isLastStep"
          variant="secondary"
          size="medium"
          :icon="tourStore.isLastStep ? 'flag-checkered' : ''"
          :text="tourStore.isLastStep ? ($t('modals.tour_label_finish') as string) : ($t('modals.tour_label_save_for_later') as string) "
          @click="emit('save-for-later')"
          data-test="tour-save-for-later"
        >
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalKeyListener } from "@/utils/GlobalKeyListener";
import HelpIconTooltip from "../../views/MATHS/HelpIconTooltip.vue";
import BaseButton from "../BaseButton/BaseButton.vue";
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  header: {
    type: Object as () => { icon: string; iconColor: string },
    default: () => ({ icon: "", iconColor: "black" }),
  },
  headerText: {
    type: String,
  },
  tooltipText: {
    type: String,
    default: "",
  },
  isGuidedTour: {
    type: Boolean,
    default: false,
  },
  isTourComplete: {
    type: Boolean,
    default: false,
  },
});

const tourStore = useGuidedTourStore();
const emit = defineEmits<{
  (e: "closeModal"): void;
  (e: "skip"): void;
  (e: "save-for-later"): void;
}>();

const onBackgroundClick = () => {
  if (!props.isGuidedTour) {
    emit("closeModal");
  }
};

useGlobalKeyListener("Escape", () => {
  if (!props.isGuidedTour) {
    emit("closeModal");
  }
});
</script>
