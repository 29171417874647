import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import * as Sentry from "@sentry/vue";
// Load the Leaflet

import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/leaflet.markercluster.js";

// Global libraries
import VueAxios from "vue-axios"; // XMLHttpRequests helper
import axios from "axios"; // XMLHttpRequests helper

// 3rd party libraries
import { fontawesome } from "./fontawesome"; // Fontawesome Web Iconfont
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { AvailableLanguages } from "./types";

// tailwind & styles
import "./tailwind.css";
import "@/assets/css-snippets/AlarmBellSwing.css";
import { createPinia } from "pinia";
import { useGlobalConfigsStore } from "./storeModules";
import { DEFAULT_NEUTRAL_COLOR_SCHEME, applyColorScheme } from "./themes";
import { getBrand } from "./api/dxapi";

getBrandInfo();
// get initial whitelabel info from db
export async function getBrandInfo() {
  let brand = "";
  // accept custom brands for development environmenst:
  const url = window.location.hostname;
  if (
    (url === "localhost" && import.meta.env.DEV) ||
    url.includes("testing.bu5")
  ) {
    const newUrl = new URL(window.location.href);
    const wl = newUrl.searchParams.get("wl");
    if (wl) {
      brand = wl;
    } else {
      // for localhost the server cannot decide based on url, so we set dulconnex as default
      brand = "dulconnex";
    }
  }
  try {
    const r = await getBrand("", { brand });
    if (typeof r != "string") {
      applyColorScheme(r.colorScheme);
      const globalStore = useGlobalConfigsStore();
      globalStore.allowedMenuItems = r.allowedMenuItems;
      globalStore.company = r.company;
      globalStore.brand = r.brand;
    } else {
      throw new Error("Get-Brand response was a string: " + r);
    }
  } catch (error) {
    console.warn(error);
    applyColorScheme(DEFAULT_NEUTRAL_COLOR_SCHEME);
  }
}

const app = createApp(App);
// pinia
const pinia = createPinia();

Sentry.init({
  app,
  environment: window.location.hostname ,
 dsn: "https://870c1dfac3fdf54812200af788edd59b@o4508342566518784.ingest.de.sentry.io/4508342570713168",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    // TODO: Clear this feature with the product team.
    // Sentry.feedbackIntegration({
    //   attach: true,
    //   colorScheme: "system",
    // }),
  ],
  tunnel: "/sentry",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
});
app.use(pinia);
app.use(I18NextVue, {
  i18next,
  rerenderOn: ["languageChanged", "loaded"],
});

const globalConfigStore = useGlobalConfigsStore();

app.use(router);
app.use(VueAxios, axios);
app.component("font-awesome-icon", fontawesome);
app.mount("#app");
i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    ns: ["translation"],
    defaultNS: "translation",
    fallbackNS: "translation",
    debug: true,
    saveMissing: false,
    backend: {
      loadPath: "/api/i18n/translations?lang={{lng}}",
    },
  });

const fetchAvailableLanguages = async () => {
  try {
    const response = await axios.post("/api/i18n/languages");
    const languages = response.data;
    if (import.meta.env.MODE === "development") {
      // Save all the available languages for development purposes.
      globalConfigStore.$patch({ availableLanguages: languages });
    } else {
      // Manually filter out the languages that are not yet supported
      // The threshold is for new features. Sometimes a new feature has missing translations in prod. No need to block the entire language for that.
      const filteredLanguages: AvailableLanguages = {};

      Object.keys(languages)
        .filter(
          (languageCode) => languages[languageCode].translated.production > 0.9
        )
        .forEach((languageCode) => {
          filteredLanguages[languageCode] = languages[languageCode];
        });

      globalConfigStore.$patch({ availableLanguages: filteredLanguages });
    }
  } catch (error) {
    console.error("Failed to fetch available languages:", error);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loadCookieBannerScript = () => {
  const url = window.location.hostname;
  if (url.includes("kluthe")) {
    return;
  }

  const script1 = document.createElement("script");
  script1.src =
    "https://cdn.cookielaw.org/consent/ba8a5e06-375e-4840-a5ce-018b9a112ae3/OtAutoBlock.js";
  script1.type = "text/javascript";

  const script2 = document.createElement("script");
  script2.src =
    "https://cdn.cookielaw.org/consent/ba8a5e06-375e-4840-a5ce-018b9a112ae3/otSDKStub.js";
  script2.type = "text/javascript";
  script2.setAttribute(
    "data-domain-script",
    "ba8a5e06-375e-4840-a5ce-018b9a112ae3-test"
  );

  const container = document.getElementById("script-container");
  if (container) {
    container.appendChild(script1);
    container.appendChild(script2);
  }
};
fetchAvailableLanguages();
//loadCookieBannerScript();
