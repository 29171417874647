import { DxAPIConnector } from "@/api";
import { AvailableLanguages, EditingItem } from "@/types";
import { defineStore } from "pinia";
import { LOGIN, PAGES } from "./constants";
import { RouteLocationNormalized } from "vue-router";

export interface WLPreviewData {
  companyName: string;
  productName: string;
  productLogo: string | ArrayBuffer;
  companyLogo: string | ArrayBuffer;
  favIcon: string | ArrayBuffer;
  primaryColor: string;
  secondaryColor: string;
  primaryContrast: string;
}

export interface WLCompany {
  legalName: string;
  mailSender: string;
  mailReceiver: string;
  phone: string;
  address: string;
  contactMail: string;
  websiteTranslatable: Record<string, string>;
  imprintTranslatable: Record<string, string>;
  dataPrivacyTranslatable: Record<string, string>;
}

export const useGlobalConfigsStore = defineStore("globalConfigs", {
  state: () => ({
    decimals: 2,
    isAuth: undefined as undefined | boolean,
    isLoading: true,
    session: "",
    translation: {} as object,
    backlink: null as null | RouteLocationNormalized,
    language: "en",
    availableLanguages: {} as AvailableLanguages,
    baseUrl: import.meta.env.BASE_URL,
    brand: "",
    company: undefined as undefined | WLCompany,
    allowedMenuItems: [] as string[],
    coordinates: [49.422776, 8.64028], // TODO: This should be a local constant
    OWMAPPID: "1e54a65c85d0885600a4573240fb9fb9", // TODO: this should be in .env
    editingItem: {} as Partial<EditingItem>, // Global editing Variable. Determines, whether editing screen is shown or "normal" display of values
    connector: undefined as undefined | DxAPIConnector,
    _wlPreviewData: {
      companyName: "" as string,
      productName: "" as string,
      productLogo: "" as string | ArrayBuffer,
      companyLogo: "" as string | ArrayBuffer,
      favIcon: "" as string | ArrayBuffer,
      primaryColor: "#01305c" as string,
      secondaryColor: "#ea7b16" as string,
      primaryContrast: "#FFFFFF" as string,
    } as WLPreviewData,
  }),
  actions: {
    setLanguage(language: string) {
      const lang =
        language || language != null || language !== "null" ? language : "en";
      localStorage.setItem("prominent.user.language", lang); // keep it until refactoring replaces the localStorage dependency
      this.language = lang;
    },
    setEditingItem(item: Partial<EditingItem>) {
      this.editingItem = item;
    },
    removeEditingItem() {
      this.editingItem = {};
    },
  },
  getters: {
    menuItemsByWL(state) {
      return [
        ...PAGES.filter((p) =>
          state.allowedMenuItems.includes(p.path.replace("/", ""))
        ),
        LOGIN,
      ]; // everyone has the option to log in!
    },
  },
});
