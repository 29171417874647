import i18next from "i18next";
import { RouteLocationNormalizedLoaded } from "vue-router";

export interface BreadCrumb {
  name: string;
  path: string;
}

// When routes expect path params, Vue Router returns the full path.
// E.g.: /inventory-management/list/:sortby?/:orderby?
// We do not want to push this path to the router as Sort by function reads the url param and displays it.
// So we need to remove the params from the path.
const removeParamsFromBreadCrumb = (path: string) => {
  const idx = path.indexOf(":");
  return idx >= 0 ? path.substring(0, idx) : path;
};
export const breadCrumbDecider = (
  routeObject: RouteLocationNormalizedLoaded,
  dayTime?: DayTime
): BreadCrumb[] => {
  const crumbs = [];
  if (!routeObject || !routeObject.name) {
    return [];
  }
  if (
    !routeObject.matched ||
    routeObject.matched.length === 0 ||
    routeObject.name === "Dashboard"
  ) {
    crumbs.push({
      name: i18next.t(`breadcrumbs.${dayTime}`),
      path: "/",
    });
  } else {
    const matchedRouteNames = routeObject.matched;
    matchedRouteNames.forEach((matchedRouteName) => {
      if (matchedRouteName.name) {
        crumbs.push({
          name: i18next.t(
            `breadcrumbs.${matchedRouteName.name.toString().toLowerCase()}`
          ),
          path: removeParamsFromBreadCrumb(matchedRouteName.path),
        });
      }
    });
  }
  // Add query name
  if (routeObject.query.name) {
    crumbs.push({
      name: `${routeObject.query.name}`,
      path: `?id=${routeObject.query.id}&name=${routeObject.query.name}`,
    });
  }
  // Add tag name
  if (routeObject.query.tag) {
    crumbs.push({
      name: `#${routeObject.query.tag}`,
      path: `?tag=${routeObject.query.tag}`,
    });
  }
  return crumbs;
};

type DayTime = "morning" | "day" | "afternoon" | "evening";

export const getDaytime = (date: Date): DayTime => {
  const hours = date.getHours();
  let time: DayTime = "morning";
  if (hours > 10) {
    time = "day";
  }
  if (hours > 14) {
    time = "afternoon";
  }
  if (hours < 4 || hours > 17) {
    time = "evening";
  }
  return time;
};
