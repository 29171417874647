<template>
  <div class="list-icon_item dropdown" id="navLang">
    <a
      href="#"
      data-modal="timestamp"
      class="btnModal dropdown-toggle"
      id="btnModalLang"
      data-test="open-language-selector"
      data-bs-toggle="dropdown"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
      data-tooltip="tooltip"
      data-placement="bottom"
      title="Language / Sprache"
    >
      <div class="tw-w-7 tw-h-5 tw-mt-3">
        <FlagDisplay :flag="globalStore.language.toString().toLowerCase()" />
      </div>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-right dropdown-submenu dropdown-language tw-fixed"
      aria-labelledby="btnModalLang"
    >
      <span class="dropdown-arrow"></span>
      <div class="dropdown-header tw-bg-primary-500 tw-text-primary-contrast">
        <FontAwesomeIcon
          icon="custom-language"
          class="tw-absolute tw-text-white tw-opacity-10 tw-text-8xl tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
        />
        <h4 v-html="$t('navi.header_language_headline')"></h4>
      </div>
      <div class="dropdown-body tw-z-40">
        <div class="row">
          <div class="col-sm-12 subnavi">
            <ul class="row clearfix">
              <li
                v-for="(lang, index) in availableLangs"
                class="col-md-6"
                :class="
                  $i18next.language === index.toString()
                    ? 'tw-bg-primary-100'
                    : ''
                "
                :key="index"
              >
                <a
                  href="#"
                  :data-test="'language-selector-' + index.toString()"
                  @click.prevent="languageChanger(index.toString())"
                >
                  <div class="tw-h-8 tw-w-8 tw-rounded-full tw-overflow-hidden">
                    <FlagDisplay
                      squared
                      :flag="index.toString().toLowerCase()"
                    />
                  </div>
                  <span>{{ lang.nativeName }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>
<script setup lang="ts">
import FlagDisplay from "@/components/library/FlagDisplay/FlagDisplay.vue";
import { useGlobalConfigsStore, useUserConfigStore } from "@/storeModules";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18next from "i18next";
import { computed } from "vue";

const globalStore = useGlobalConfigsStore();
const userStore = useUserConfigStore();
const availableLangs = computed(() => globalStore.availableLanguages);

const languageChanger = (val: string) => {
  i18next.changeLanguage(val);
  localStorage.setItem("prominent.user.language", val);
  globalStore.setLanguage(val);
  if (globalStore.isAuth && globalStore.connector) {
    globalStore.connector.general.setLanguage({ language: val.toUpperCase() }); // i18n returns the lang in lowercase. BE needs it in uppercase.
    userStore.userSettings.language = val.toUpperCase();
  }
};
</script>
