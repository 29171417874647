<template>
  <div
    class="tw-group tw-relative tw-inline-block"
    @mouseenter="verifyPosition"
    @mouseleave="resetPosition"
  >
    <slot></slot>
    <div
      v-if="text"
      class="tw-text-black tw-shadow-1 tw-absolute tw-rounded-[5px] tw-py-1.5 tw-px-3.5 tw-bg-gray-300 tw-text-sm tw-hidden group-hover:tw-block tw-z-50 tw-font-medium"
      :class="[positionStyles[position as keyof typeof positionStyles].container, color]"
    >
      <span
        class="tw-absolute tw-bg-gray-300 -tw-z-10 tw-rotate-45 tw-h-2 tw-w-2"
        :class="[positionStyles[position as keyof typeof positionStyles].indicator, color]"
      ></span>
      <div
        ref="tooltipRef"
        class="tw-text-balance tw-break-normal tw-max-w-xs"
        :style="`${tooltipWidth ? `width: ${tooltipWidth}px;` : ''}`"
        :class="[`${tooltipWidth ? '' : 'tw-min-w-max'}`]"
        v-html="text"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType, ref } from "vue";

const tooltipRef = ref<null | HTMLDivElement>(null);
const tooltipWidth = ref(0);
defineProps({
  text: {
    type: String,
    required: true,
  },
  position: {
    type: String as PropType<"top" | "bottom" | "left" | "right">,
    default: "bottom",
  },
  color: {
    type: String,
  },
});

const resetPosition = () => {
  tooltipWidth.value = 0;
};
const verifyPosition = () => {
  if (!tooltipRef.value) {
    return;
  }

  const { width, x } = tooltipRef.value.getBoundingClientRect();

  // Artificial padding to make sure the tooltip doesn't go out of bounds
  const paddingPX = 40;

  // Check if the tooltip starts out of the screen (Horizontally)
  if (x < 0) {
    tooltipWidth.value = (window.innerWidth - paddingPX / 2) / 2 + x;
  }
  // Check if the tooltip ends up out of the screen (Horizontally)
  if (x + width > window.innerWidth - paddingPX) {
    tooltipWidth.value = window.innerWidth - x - paddingPX;
  }
};

const positionStyles = {
  bottom: {
    container: "tw-top-full tw-left-1/2 tw-mt-3 -tw-translate-x-1/2",
    indicator: "-tw-top-1 tw-left-1/2 -tw-translate-x-1/2",
  },
  top: {
    container: "tw-bottom-full tw-left-1/2 tw-mb-3 -tw-translate-x-1/2",
    indicator: "-tw-bottom-1 tw-left-1/2 -tw-translate-x-1/2",
  },
  right: {
    container: "tw-left-full tw-top-1/2 tw-ml-3 -tw-translate-y-1/2",
    indicator: "-tw-left-1 tw-top-1/2 -tw-translate-y-1/2",
  },
  left: {
    container: "tw-right-full tw-top-1/2 tw-mr-3 -tw-translate-y-1/2",
    indicator: "-tw-right-1 tw-top-1/2 -tw-translate-y-1/2",
  },
};
</script>
