<template>
  <div class="tw-p-1">
    <div class="tw-font-bold !tw-text-base tw-mb-3">
      {{ $t(tourStore.getCurrentStep?.textTitleTranslationKey ?? "") }}
    </div>
    <div
    :class="[
        'tw-flex',
        isFirstOrLastStep ? 'tw-items-center tw-justify-center tw-space-x-4' : 'tw-flex-col tw-items-center tw-justify-center'
      ]"
    >
      <img
        v-if="tourStore.getCurrentStep?.options?.imgPath"
        :src="tourStore.getCurrentStep?.options?.imgPath"
        alt="Option Image"
        class="tw-max-h-48 tw-max-w-60 tw-object-cover tw-mb-1"
      />
      <p
        class="tw-text-md tw-font-semibold tw-mb-0 tw-p-2 tw-text-gray-800 !tw-text-base tw-leading-relaxed tw-max-w-prose tw-rounded tw-shadow-inner tw-bg-primary-50"
        :class="{
          'tw-text-center': isFirstOrLastStep,
          'tw-text-left': !isFirstOrLastStep
        }"
      >
        <span v-if="tourStore.getCurrentStep" v-html="$t(tourStore.getCurrentStep?.textContentTranslationKey)"></span>
      </p>
    </div>
  </div>

  <div class="tw-flex tw-justify-center tw-items-center tw-gap-4">
    <RoundIconButton
      v-if="tourStore.currentStep !== 0"
      fa-icon="chevron-left"
      @click="tourStore.decrementStep"
      :outlined="false"
    />
    <div class="tw-font-semibold tw-text-base tw-text-primary-400">
      {{ tourStore.currentStep + 1 }} / {{ tourStore.activeTour?.steps.length }}
    </div>
    <RoundIconButton
      v-if="!tourStore.isLastStep"
      :fa-icon="'chevron-right'"
      @click="handleNextOrSave"
      :outlined="false"
    />
  </div>
</template>

<script setup lang="ts">
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";
import RoundIconButton from "../RoundIconButton/RoundIconButton.vue";
import { computed } from "vue";

const tourStore = useGuidedTourStore();

const isFirstOrLastStep = computed(() => {
  const currentStepIndex = tourStore.currentStep + 1;
  return currentStepIndex === 1 || currentStepIndex === tourStore.activeTour?.steps.length;
});

const handleNextOrSave = () => {
  if (tourStore.isLastStep) {
    emit("save-tour");
  } else {
    tourStore.incrementStep();
  }
};

const emit = defineEmits<{
  (e: "save-tour"): void;
}>();
</script>
