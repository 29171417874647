import { ColorScheme } from "@/themes";
import { AlarmsConnector } from "./AlarmsConnector";
import { DeviceConnector } from "./DeviceConnector";
import { GeneralConnector } from "./GeneralConnector";
import { InventoryManagementConnector } from "./InventoryManagementConnector";
import { UISettingsConnector } from "./UISettingsConnector";
import { asyncCall, asyncOriginalCall } from "./call";
import { BrandInfo, ServerResponse, Session } from "./types";
import * as Sentry from "@sentry/vue";
/**
 * @target "/api/login"
 * @param urlPrefix
 * @param options user, pass
 * @returns
 */
export const login = async (
  urlPrefix: string,
  options: {
    user: string;
    pass: string;
    brand: string;
    extendSessionBy?: string;
  }
): Promise<Session | ServerResponse> => {
  try {
    const session: Session = await asyncOriginalCall(
      urlPrefix + "/api/login",
      JSON.parse(JSON.stringify(options))
    );
    return session;
  } catch (error) {
    Sentry.captureException(error, {
      data: { url: urlPrefix, brand: options.brand, user: options.user },
    });
    throw error as ServerResponse;
  }
};

/**
 * @target "/api/signup"
 * @param urlPrefix
 * @param options  username, password, timezone, email, accesscode
 * @returns
 */
export const signup = async (
  urlPrefix: string,
  options: {
    username: string;
    password: string;
    timezone: string;
    email: string;
    accesscode: string;
  }
) => {
  const result: ServerResponse = await asyncOriginalCall(
    urlPrefix + "/api/signup",
    options
  );
  return result;
};

/**
 * @target "/api/reset-password"
 * @param urlPrefix
 * @param options user, email
 * @returns
 */
export const requestResetPassword = async (
  urlPrefix: string,
  options: { user: string; email: string }
) => {
  const result: ServerResponse = await asyncCall(
    urlPrefix + "/api/reset-password",
    options
  );
  return result;
};

/**
 * @target "/api/reset-password"
 * @param urlPrefix
 * @param options token, newpassword
 * @returns
 */
export const resetPassword = async (
  urlPrefix: string,
  options: { token: string; newpassword: string }
) => {
  return await asyncOriginalCall(urlPrefix + "/api/reset-password", options);
};

/**
 * @target "/api/verify-signup"
 * @param urlPrefix
 * @param options token
 * @returns
 */
export const verifySignUp = async (
  urlPrefix: string,
  options: { token: string }
) => {
  const result: ServerResponse = await asyncCall(
    urlPrefix + "/api/verify-signup",
    options
  );

  return result;
};

export const getBrand = async (
  urlPrefix: string,
  options: { brand: string } = { brand: "" }
) => {
  const result: ServerResponse | BrandInfo = await asyncCall(
    urlPrefix + "/api/get-brand",
    options
  );
  return result;
};

export const grantAccess = async (
  urlPrefix: string,
  options: { token: string }
) => {
  const result = await asyncCall(urlPrefix + "/api/grant-access", options);
  return result;
};

export const verifySignup = async (
  urlPrefix: string,
  options: { token: string }
) => {
  return (await asyncCall(urlPrefix + "/api/verify-signup", options)) as string;
};

export class DxAPIConnector {
  private urlPrefix: string;
  private session: { id: string };
  readonly alarms: AlarmsConnector;
  readonly devices: DeviceConnector;
  readonly general: GeneralConnector;
  readonly inventoryManagement: InventoryManagementConnector;
  readonly uiSettings: UISettingsConnector;

  constructor(urlPrefix: string, session: string) {
    if (!session) throw new Error("invalid session");
    this.urlPrefix = urlPrefix;
    this.session = { id: session };
    this.alarms = new AlarmsConnector(urlPrefix, session);
    this.devices = new DeviceConnector(urlPrefix, session);
    this.general = new GeneralConnector(urlPrefix, session);
    this.inventoryManagement = new InventoryManagementConnector(
      urlPrefix,
      session
    );
    this.uiSettings = new UISettingsConnector(urlPrefix, session);
  }

  async logout() {
    await asyncCall(this.urlPrefix + "/api/logout", {
      session: this.session.id,
    });
  }
}
