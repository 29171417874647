<template>
  <div>
    <div
      class="tw-flex tw-flex-col tw-items-start tw-justify-center tw-w-full md:tw-w-2/3 tw-mx-auto tw-p-2 sm:tw-p-4"
      v-for="service in SERVICES_LIST"
      :key="service.id"
    >
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center tw-justify-between tw-py-4 tw-flex-wrap"
      >
        <BaseToggleSlider
          :label="service.name"
          :model-value="selection[service.id]"
          @update:modelValue="selection[service.id] = $event"
          :label-right-to-toggle="true"
          :data-test="`toggle-${service.id}`"
        />
        <div
          class="tw-text-xs tw-m-2"
          v-html="$t(service.description, '')"
        ></div>
      </div>
      <div>
        <span
          class="tw-text-xs tw-font-semibold"
          v-html="($t('consent.what_can_be_seen_title', { service: service.name }) as string)"
        ></span>
        <div class="tw-text-xs">
          <span
            v-for="item in service.dataTheyGet"
            :key="item"
            v-html="`${$t(item)}, `"
          >
          </span>
        </div>
      </div>
    </div>
    <div
      class="tw-flex tw-flex-row tw-justify-end tw-items-end tw-py-4"
      :class="[
        stickyButtons
          ? 'tw-sticky tw-bottom-0 tw-bg-white tw-bg-opacity-95 tw-shadow'
          : '',
      ]"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <div class="tw-py-4">
          <label
            for="remember"
            data-test="remember-label"
            v-html="($t('consent.remember_choice_text') as string)"
          ></label>
          <input
            class="tw-mx-2 tw-scale-125"
            id="remember"
            type="checkbox"
            defaultChecked
            v-model="remember"
            data-test="remember-checkbox"
          />
        </div>
        <div
          class="tw-flex tw-flex-col sm:tw-flex-row tw-items-stretch tw-justify-stretch tw-space-y-1 sm:tw-space-y-0"
        >
          <BaseButton
            class="tw-mx-2"
            :variant="'primary'"
            :text="($t('consent.button_accept_all') as string)"
            @click="() => toggleAll(true)"
            data-test="accept-all"
          />
          <BaseButton
            class="tw-mx-2"
            :variant="'primary'"
            :text="($t('consent.button_accept_selection') as string)"
            @click="saveChoices"
            data-test="accept-selection"
          />
          <BaseButton
            class="tw-mx-2"
            :variant="'primary'"
            :text="($t('consent.button_deny_all') as string)"
            @click="() => toggleAll(false)"
            data-test="deny-all"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from "vue";
import BaseToggleSlider from "../BaseToggleSlider/BaseToggleSlider.vue";
import BaseButton from "../BaseButton/BaseButton.vue";
import { useUISettingsStore } from "@/storeModules";
import { getConsentList } from "@/constants/consentRequiredServicesList";

const uiStore = useUISettingsStore();

const selection = ref({ ...uiStore.userConsent });
const reference = computed(() => uiStore.userConsent);

const remember = ref(true);
const emits = defineEmits<{
  (event: "save"): void;
}>();
const isDXOrange = "ReactNativeWebView" in window;
const SERVICES_LIST = getConsentList(isDXOrange);

defineProps({
  stickyButtons: {
    type: Boolean,
    default: false,
  },
});

const toggleAll = (val: boolean) => {
  SERVICES_LIST.forEach((service) => {
    selection.value[service.id] = val;
  });
  saveChoices();
};

const saveChoices = () => {
  if (remember.value) {
    localStorage.setItem("rememberConsentChoice", remember.value.toString());
  } else {
    localStorage.removeItem("rememberConsentChoice");
    SERVICES_LIST.forEach((service) => {
      localStorage.removeItem(service.id);
    });
  }
  if (remember.value) {
    Object.entries(selection.value).forEach(([key, value]) => {
      localStorage.setItem(key, value.toString());
    });
  }
  uiStore.setUserConsent(selection.value);
  emits("save");
};

watch(
  reference,
  () => {
    selection.value = reference.value;
  },
  { deep: true }
);
</script>
