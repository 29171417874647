<template>
  <button
    class="tw-flex tw-justify-center tw-text-base tw-bg-primary-500 tw-w-11 tw-h-11 tw-rounded-full hover:tw-bg-primary-600 tw-text-primary-300 active:tw-border-primary-300"
  >
    <div
      class="tw-m-auto tw-flex tw-flex-col tw-text-clip tw-overflow-hidden tw-text-primary-contrast tw-text-xl"
    >
      <font-awesome-icon icon="bars" />
    </div>
  </button>
</template>
