<template>
  <div :class="`tw-text-primary-contrast`">
    <router-link
      :to="{ name: props.targetRouteName }"
      exact-active-class=""
      activeClass="active"
    >
      <div class="py-3 hover:tw-bg-primary-700 tw-pl-4">
        <div class="tw-flex tw-items-center">
          <FontAwesomeIcon :icon="icon"></FontAwesomeIcon>
          <span
            class="tw-font-semibold tw-uppercase tw-ml-2 tw-tracking-wider"
            :data-test="`collapse-area-${props.targetRouteName}`"
            ><slot></slot
          ></span>
          <CollapseButton
            v-if="collapsable"
            v-model="collapsed"
            @update:model-value="(v) => emit(v ? 'collapsed' : 'opened')"
            :data-test="`collapse-button-${props.targetRouteName}`"
          />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CollapseButton from "@/components/library/CollapseButton/CollapseButton.vue";
import { ref } from "vue";

const props = defineProps({
  targetRouteName: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  collapsable: {
    type: Boolean,
    default: false,
  },
  startsOpen: {
    type: Boolean,
    default: false,
  },
});

const collapsed = ref(props.startsOpen);
const emit = defineEmits(["collapsed", "opened"]);
</script>

<style scoped lang="postcss">
.active {
  @apply tw-text-secondary-500;
}

a {
  color: inherit;
}
</style>
