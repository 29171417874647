export type OnlineStatusVariants = "device" | "virtual-device";
export type OnlineStatusVariantInfo = {
  onlineTextKey: string;
  offlineTextKey: string;
  onlineClass: string;
  offlineClass: string;
};

export const Variants: Record<OnlineStatusVariants, OnlineStatusVariantInfo> = {
  device: {
    onlineTextKey: "widgets.map_label_status_online",
    offlineTextKey: "widgets.map_label_status_offline",
    offlineClass: "tw-bg-status-red-500",
    onlineClass: "tw-bg-status-green-500",
  },
  "virtual-device": {
    onlineTextKey: "virtua-devices.status.active",
    offlineTextKey: "virtua-devices.status.problem",
    onlineClass: "tw-bg-status-blue-500",
    offlineClass: "tw-bg-status-yellow-500",
  },
};
