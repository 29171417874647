import checkIsMobile from "is-mobile";
import { ref } from "vue";

export const useIsMobile = () => {
  const isMobile = ref(checkIsMobile());
  const isReactNative = ref(window?.ReactNativeWebView ? true : false);

  return {
    isMobile,
    isReactNative,
  };
};
