import {
  DXTour,
  PositionPrioValues,
} from "@/components/library/GuidedTour/GuidedTourUtils";
import { useDeviceStore, useUISettingsStore } from "@/storeModules";
import { TOUR_CATEGORIES, TOUR_ID } from "@/constants/tour"
import { router } from "@/router";
import { FCSingleSymbol, FCConstant, FCDataSource } from "@/components/views/MATHS/MathComponents";
import { dosedAmount1hDataSource, liquidLevelVolume1hDataSource, liquidLevelVolumeCurrentDataSource } from "@/constants/math";

const POSITION_PRIORITIES_ABOVE_ELEMENT = [
  "top_left",
  "top_right",
  "bottom_right",
  "bottom_left",
] as PositionPrioValues[];

// this example is a tour that moves over quite a few places, so the data modification is being done via stores see below for a more contained example
export function createAddVirtualDevicesTour(): DXTour {
  const uiStore = useUISettingsStore();
  const tour = new DXTour(
    "tour.what-is-a-virtual-device.tour-title",
    TOUR_ID.VIRTUAL_DEVICE_TOUR,
    TOUR_CATEGORIES.MATH_FUNCTIONALITY,
    1
  );
  tour.totalSteps = 6;
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.1.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.1.content",
    options: {
      imgPath: "/assets/img/guided-tour/math-functionality.png",
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.2.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.2.content",
    options: {
      elementTourID: "add-device-modal-open",
      beforeStep: () => {
        uiStore.addDeviceModalOpen = false
      }, // necessary to make the go back work
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.3.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.3.content",
    options: {
      elementTourID: "add-device-physical-button",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: () => {
        uiStore.addDeviceModalOpen = true;
        uiStore.addDeviceDataEntryCurrentTab = "physical";
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.4.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.4.content",
    options: {
      elementTourID: "add-device-virtual-button",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: () => {
        uiStore.addDeviceModalOpen = true;
        uiStore.addDeviceDataEntryCurrentTab = "virtual";
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.5.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.5.content",
    options: {
      elementTourID: "add-device-new-virtual-switch",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: () => {
        uiStore.addDeviceModalOpen = true;
        uiStore.addDeviceDataEntryCurrentTab = "virtual";
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-device.step.6.title",
    textContentTranslationKey: "tour.what-is-a-virtual-device.step.6.content",
    options: {},
  });
  return tour;
}

export function createAddVirtualVariableTour(): DXTour {
  const deviceStore = useDeviceStore();
  const virtualDeviceList = deviceStore.getDevices.filter(device => device.type.startsWith('virtual'));
  const firstVirtualDeviceId = virtualDeviceList.length > 0 ? virtualDeviceList[0].id : null;
  const mathEditorStore = useUISettingsStore();
  const tour = new DXTour(
    "tour.what-is-a-virtual-variable.tour-title",
    TOUR_ID.VIRTUAL_VARIABLE_TOUR,
    TOUR_CATEGORIES.MATH_FUNCTIONALITY,
    2
  );
  tour.totalSteps = 5;
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-variable.step.1.title",
    textContentTranslationKey: "tour.what-is-a-virtual-variable.step.1.content",
    options: {
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Device Values', query: { id: mathEditorStore.deviceID } });
     },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-variable.step.2.title",
    textContentTranslationKey: "tour.what-is-a-virtual-variable.step.2.content",
    options: {
      elementTourID: "virtual-device-status",
      beforeStep: async () => {
        await router.push({ name: 'Device Values', query: { id: mathEditorStore.deviceID } });
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-variable.step.3.title",
    textContentTranslationKey: "tour.what-is-a-virtual-variable.step.3.content",
    options: {
      elementTourID: "manage-virtual-variables",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        await router.push({ name: 'Device Values', query: { id: mathEditorStore.deviceID } });
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-variable.step.4.title",
    textContentTranslationKey: "tour.what-is-a-virtual-variable.step.4.content",
    options: {
      elementTourID: "add-virtual-variables",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        await router.push({ name: 'Virtual Variables', query: { id: mathEditorStore.deviceID } });
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.what-is-a-virtual-variable.step.5.title",
    textContentTranslationKey: "tour.what-is-a-virtual-variable.step.5.content",
    options: {},
  });
  return tour;
}

export function createMathEditorTour(): DXTour {
  const deviceStore = useDeviceStore();
  const virtualDeviceList = deviceStore.getDevices.filter(device => device.type.startsWith('virtual'));
  const firstVirtualDeviceId = virtualDeviceList.length > 0 ? virtualDeviceList[0].id : null;
  const mathEditorStore = useUISettingsStore();
  const tour = new DXTour(
    "tour.what-is-a-math-editor.tour-title",
    TOUR_ID.MATH_EDITOR_TOUR,
    TOUR_CATEGORIES.MATH_FUNCTIONALITY,
    3
  );
  tour.totalSteps = 15;
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.1.title",
    textContentTranslationKey: "tour.math-editor-features.step.1.content",
    options: {
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
        mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
        }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
     },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.2.title",
    textContentTranslationKey: "tour.math-editor-features.step.2.content",
    options: {
      elementTourID: "data-source-component",
      imgPath: "/assets/img/guided-tour/math-editor-data-source-modal.png",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });

      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.3.title",
    textContentTranslationKey: "tour.math-editor-features.step.3.content",
    options: {
      elementTourID: "constant-component",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          } 
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.4.title",
    textContentTranslationKey: "tour.math-editor-features.step.4.content",
    options: {
      elementTourID: "data-source-in-the-dragbox",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      imgPath: "/assets/img/guided-tour/math-editor-drag-datasource.png",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.addToFunctionList(new FCDataSource());
        mathEditorStore.setGuidedTourTargetID('data-source-in-the-dragbox');
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.5.title",
    textContentTranslationKey: "tour.math-editor-features.step.5.content",
    options: {
      elementTourID: "symbol-in-the-dragbox",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      imgPath: "/assets/img/guided-tour/math-editor-drag-symbol.png",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.addToFunctionList(new FCDataSource());
        mathEditorStore.addToFunctionList(new FCSingleSymbol('+'));
        mathEditorStore.setGuidedTourTargetID('symbol-in-the-dragbox');
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.6.title",
    textContentTranslationKey: "tour.math-editor-features.step.6.content",
    options: {
      elementTourID: "constant-in-the-dragbox",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      imgPath: "/assets/img/guided-tour/math-editor-drag-constant.png",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.addToFunctionList(new FCDataSource());
        mathEditorStore.addToFunctionList(new FCSingleSymbol('+'));
        mathEditorStore.addToFunctionList(new FCConstant());
        mathEditorStore.setGuidedTourTargetID('constant-in-the-dragbox');
        mathEditorStore.setDragComponentToTrash(false);
      },
    },
  });

  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.7.title",
    textContentTranslationKey: "tour.math-editor-features.step.7.content",
    options: {
      elementTourID: "removal-of-elements-from-dragbox",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      imgPath: "/assets/img/guided-tour/math-editor-drag-remove-component.png",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.addToFunctionList(new FCDataSource());
        mathEditorStore.addToFunctionList(new FCSingleSymbol('+'));
        mathEditorStore.addToFunctionList(new FCConstant());
        mathEditorStore.setDragComponentToTrash(true);
      },
    },
  });

  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.8.title",
    textContentTranslationKey: "tour.math-editor-features.step.8.content",
    options: {
      elementTourID: "calculation-result-bar",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.addToFunctionList(new FCDataSource());
        mathEditorStore.addToFunctionList(new FCSingleSymbol('+'));
        mathEditorStore.addToFunctionList(new FCConstant());
        mathEditorStore.updateFunctionList(mathEditorStore.functionList);
        mathEditorStore.setDragComponentToTrash(false);
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.9.title",
    textContentTranslationKey: "tour.math-editor-features.step.9.content",
    options: {
      elementTourID: "save-caluclation",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          } 
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.saveCalculationModalOpen = false;
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.10.title",
    textContentTranslationKey: "tour.math-editor-features.step.10.content",
    options: {
      elementTourID: "save-calculation-as-new-or-existing-virtual-variable",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.saveCalculationModalOpen = true;
        mathEditorStore.isPredefinedCalculationListOpen = false;
      }
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.11.title",
    textContentTranslationKey: "tour.math-editor-features.step.11.content",
    options: {
      elementTourID: "predefined-calculation-dropdown",
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.saveCalculationModalOpen = false;
        mathEditorStore.isPredefinedCalculationListOpen = false;
        mathEditorStore.openPredefinedCalculationWidget = false;
        mathEditorStore.isCalculationCreating = false;
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.12.title",
    textContentTranslationKey: "tour.math-editor-features.step.12.content",
    options: {
      elementTourID: "predefined-calculation-tank-leakage",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.isTourPredefinedWidgetOpen = false;
        mathEditorStore.isPredefinedCalculationListOpen = true;
        mathEditorStore.openPredefinedCalculationWidget = false;
        mathEditorStore.isCalculationCreating = true;
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.13.title",
    textContentTranslationKey: "tour.math-editor-features.step.13.content",
    options: {
      elementTourID: "choose-device-time-tank-leakage",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.isTourPredefinedWidgetOpen = true;
        mathEditorStore.isPredefinedCalculationListOpen = false;
        mathEditorStore.openPredefinedCalculationWidget = true;
        mathEditorStore.isCalculationCreating = true;
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.14.title",
    textContentTranslationKey: "tour.math-editor-features.step.14.content",
    options: {
      elementTourID: "prepare-predefined-calculation",
      positionPriority: POSITION_PRIORITIES_ABOVE_ELEMENT,
      beforeStep: async () => {
        if(!mathEditorStore.deviceID) {
          mathEditorStore.setDeviceID(firstVirtualDeviceId?.toString());
          }
        await router.push({ name: 'Maths Editor', query: { device: mathEditorStore.deviceID } });
        mathEditorStore.clearFunctionList();
        mathEditorStore.isTourPredefinedWidgetOpen = false;
        mathEditorStore.isCalculationCreating = true;
        mathEditorStore.openPredefinedCalculationWidget = false;
        mathEditorStore.addToFunctionList(new FCSingleSymbol('('));
        mathEditorStore.addToFunctionList(liquidLevelVolume1hDataSource);
        mathEditorStore.addToFunctionList(new FCSingleSymbol('-'));
        mathEditorStore.addToFunctionList(liquidLevelVolumeCurrentDataSource);
        mathEditorStore.addToFunctionList(new FCSingleSymbol(')'));
        mathEditorStore.addToFunctionList(new FCSingleSymbol('-'));
        mathEditorStore.addToFunctionList(dosedAmount1hDataSource);
        mathEditorStore.updateFunctionList(mathEditorStore.functionList);
      },
    },
  });
  tour.addStep({
    textTitleTranslationKey: "tour.math-editor-features.step.15.title",
    textContentTranslationKey: "tour.math-editor-features.step.15.content",
    options: {
      beforeStep: () => {}
    },
  });
  return tour;
}
