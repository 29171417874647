<template>
  <div id="main">
    <ErrorBoundary>
      <router-view></router-view>
    </ErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import debounce from "@/utils/functional/Debounce";
import ErrorBoundary from "../library/ErrorBoundary/ErrorBoundary.vue";

/**
 * Adjusts the position of toasts according to the current scroll position
 */
function handleScroll() {
  const header = document.getElementById("header");
  const subnavi = document.getElementById("subnavi");
  const toasts = document.getElementById("toasts");
  if (header == null || toasts == null) return;
  if (
    window.pageYOffset <=
    header.offsetHeight + (subnavi ? subnavi.offsetHeight : 0)
  ) {
    toasts.style.marginTop = "" + Math.round(header.offsetHeight) * 2 + "px";
  } else {
    toasts.style.marginTop = "" + Math.round(header.offsetHeight) + "px";
  }
}

onMounted(() => {
  window.addEventListener("scroll", debounce(handleScroll, 50));
  handleScroll();
});

onUnmounted(() => {
  window.removeEventListener("scroll", debounce(handleScroll, 50));
});
</script>
<style>
#main {
  height: max(auto, 100%, 100vh);
  box-sizing: border-box;
}
</style>
