<template>
  <BaseLabel
    :inputId="inputId!"
    :label="label || ''"
    :labelAlignment="labelAlignment"
    :additionalData="additionalData"
  >
    <textarea
      :class="`${
        disabled ? 'tw-opacity-50' : ''
      } ${additionalClasses} tw-input-base tw-outline-none`"
      :id="inputId"
      :disabled="disabled"
      :rows="rows"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :required="required"
      :value="modelValue"
      @input="handleInput"
      :isValid="isValid"
    />
  </BaseLabel>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import BaseLabel from "../BaseLabel/BaseLabel.vue";
import { BaseLabelProps } from "../BaseLabel/BaseLabelUtils";
import { ResizeTypes } from "./BaseTextAreaUtils";

const emit = defineEmits(["update:modelValue"]);

const handleInput = (event: Event) => {
  const target = event.target;
  if (target instanceof HTMLTextAreaElement) {
    const value = target.value;
    emit("update:modelValue", value);
  }
};

defineProps({
  disabled: { type: Boolean, required: false },
  rows: { type: Number, required: false, default: 4 },
  maxLength: { type: Number, required: false },
  showMaxLength: { type: Boolean, required: false },
  placeholder: { type: String, required: false },
  required: { type: Boolean, required: false },
  modelValue: { type: String, required: true },
  resize: {
    type: String as PropType<ResizeTypes>,
    required: false,
    default: "none",
  },
  isValid: { type: Boolean, required: false, default: undefined },
  additionalClasses: { type: String, default: "" },
  ...BaseLabelProps,
});
</script>
