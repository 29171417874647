<template>
  <div class="tw-w-4 tw-my-auto">
    <FontAwesomeIcon :icon="icon" />
  </div>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const props = defineProps({
  deviceType: {
    type: String,
    required: true,
  },
});

const icon = computed(() => findIconByDeviceType(props.deviceType));
</script>
<script lang="ts">
import { DEVICE_DISPLAY_DATA } from "@/constants";
export const findIconByDeviceType = (deviceType: string) => {
  if (!deviceType) return "hard-drive";
  const baseDeviceType = deviceType.split("_")[0];
  if (Object.keys(DEVICE_DISPLAY_DATA).includes(baseDeviceType)) {
    return DEVICE_DISPLAY_DATA[
      baseDeviceType as keyof typeof DEVICE_DISPLAY_DATA
    ].icon;
  }
  return "hard-drive";
};
</script>
