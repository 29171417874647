<template>
  <ErrorBoundary>
    <div>
      <PublicLayout
        v-if="
          (!globalConfigStore.isAuth || $route.meta.isPublic === true) &&
          i18nLoaded
        "
      />
      <Skeleton v-else-if="globalConfigStore.isLoading"></Skeleton>
      <Layout v-else></Layout>
      <Toasts></Toasts>
      <div v-if="refresh && refresh.length > 0">
        <div class="fixed t1 l1 bg-orange p-2 rounded">
          <div class="spinner-border" style="color: white" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
      <div id="pointedModalSpace" />
      <ConsentModal v-if="globalConfigStore.isAuth" />
    </div>
  </ErrorBoundary>
</template>

<script setup lang="ts">
import Layout from "./components/layout/Layout.vue";
import Skeleton from "./components/layout/Skeletons/Skeleton.vue";
import Toasts from "./components/layout/Toasts.vue";
import { computed, ref, watch } from "vue";

import { DXVariable } from "./types";
import i18next, { t } from "i18next";
import PublicLayout from "./components/layout/PublicLayout.vue";
import {
  useGlobalConfigsStore,
  useDeviceStore,
  useUISettingsStore,
  useIMStore,
  useUserConfigStore,
} from "./storeModules";
import { useRouter } from "vue-router";
import ErrorBoundary from "./components/library/ErrorBoundary/ErrorBoundary.vue";
import { useGuidedTourStore } from "./storeModules/guidedtours/GuidedTourStore";
import ConsentModal from "./components/library/ConsentModal/ConsentModal.vue";
import { clearLocalStorage } from "./utils/localStorage";
const globalConfigStore = useGlobalConfigsStore();
const deviceStore = useDeviceStore();
const uiSettingsStore = useUISettingsStore();
const imStore = useIMStore();
const userConfigStore = useUserConfigStore();
const guidedTourStore = useGuidedTourStore();
const router = useRouter();
const loadingDashboardData = ref(false);
const devices = ref(false);
const variables = ref(false);
const inventory = ref(false);
const news = ref(false);
const i18nLoaded = ref(false);

const RELOAD_INTERVAL_SECONDS = {
  devicesValues: 20,
  devices24h: 60,
  alarms: 20,
  issues: 20,
  inventory: 20,
  news: 120,
  devices: 20,
};
const dataFetchingIntervalIDs = [] as NodeJS.Timeout[];

const refresh = computed(() => {
  return uiSettingsStore.refreshElements;
});

watch(refresh, () => {
  refreshItems(refresh.value);
});

const loadDashboardData = async () => {
  loadingDashboardData.value = true;
  try {
    uiSettingsStore.fetchDashboardData();
  } catch (error) {
    uiSettingsStore.setError({
      message: t("error.connection_fail_handle_uisettings") ?? "",
      type: "error",
    });
  }
};

const loadDevices = async () => {
  try {
    if (globalConfigStore.connector) {
      const devicesData =
        await globalConfigStore.connector.devices.getDevices();
      if (Array.isArray(devicesData)) {
        deviceStore.devices = devicesData;
        devices.value = true; // set devices for async check
      } else {
        deviceStore.devices = [];
      }
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_load_devices_text"),
      type: "error",
    });
  }
};

const loadVariables = async () => {
  try {
    if (globalConfigStore.connector) {
      const variablesData =
        await globalConfigStore.connector.devices.getVariables();
      if (Array.isArray(variablesData)) {
        deviceStore.variables = variablesData as DXVariable[];
        variables.value = true; // set variables for async check
      }
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_load_current_values_text"),
      type: "error",
    });
  }
};

const loadAlarms = async () => {
  try {
    if (globalConfigStore.connector) {
      const alarms = await globalConfigStore.connector.alarms.getAlarms();
      if (alarms) {
        deviceStore.alarms = alarms ?? [];
      }
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_load_alarms"),
      type: "error",
    });
  }
};

const loadIssues = async () => {
  try {
    if (globalConfigStore.connector) {
      const issues = await globalConfigStore.connector.devices.getAllIssues();
      if (typeof issues === "string") {
        throw new Error("Get Issues Call Failed");
      }
      const items = deviceStore.setIssues(issues);
      if (!items) {
        return;
      }
      const devicesWithNewIssues = Object.keys(items);
      // We do not create deviceInstances by default. So we need to check if there are deviceInstances in the store that have new issues.
      if (devicesWithNewIssues.length > 0) {
        const deviceInstancesToBeUpdated = Object.keys(
          deviceStore.deviceInstances
        ).filter((device) => devicesWithNewIssues.includes(device));
        deviceInstancesToBeUpdated.forEach((device) =>
          deviceStore.deviceInstances[device].getDeviceIssues()
        );
      }
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_load_issues"),
      type: "error",
    });
  }
};

const loadInventory = async () => {
  try {
    if (globalConfigStore.connector) {
      const inventoryData =
        await globalConfigStore.connector.inventoryManagement.getInventory();
      if (typeof inventoryData == "string")
        throw new Error("Invalid IM response");
      imStore.setInventory(inventoryData);
      inventory.value = true;
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_load_inventory"),
      type: "error",
    });
  }
};

const loadNews = async () => {
  try {
    if (globalConfigStore.connector) {
      const newsData =
        await globalConfigStore.connector.general.getWhatsNewContent();
      if (!Array.isArray(newsData)) {
        userConfigStore.newsList = [];
        return;
      }
      userConfigStore.newsList = newsData;
    }
  } catch (error) {
    checkSessionInTheResponse(error as string);
    uiSettingsStore.setError({
      message: t("error.connection_fail_get_whats-new"),
      type: "error",
    });
  } finally {
    news.value = true;
  }
};

const loadDeviceTypes = async () => {
  try {
    if (globalConfigStore.connector) {
      const types = await globalConfigStore.connector.devices.getDeviceTypes();
      if (typeof types === "string") throw new Error("Get Device Call Failed");
      deviceStore.devicesList = types;
    }
  } catch (error) {
    console.error(error);
  }
};

async function refreshItems(arr: string[]) {
  if (arr.length > 0) {
    const refreshList = [
      loadVariables(),
      loadDevices(),
      loadInventory(),
      loadAlarms(),
    ];
    await Promise.all(refreshList);
    uiSettingsStore.refreshElements = [];
  }
}

/**
 * Periodically reloads data from backend to stay up to date
 */
function startRepeatingAPICalls() {
  if (dataFetchingIntervalIDs.length == 0) {
    dataFetchingIntervalIDs.push(
      setInterval(loadAlarms, RELOAD_INTERVAL_SECONDS.alarms * 1000)
    );
    dataFetchingIntervalIDs.push(
      setInterval(loadIssues, RELOAD_INTERVAL_SECONDS.issues * 1000)
    );
    dataFetchingIntervalIDs.push(
      setInterval(loadInventory, RELOAD_INTERVAL_SECONDS.inventory * 1000)
    );
    dataFetchingIntervalIDs.push(
      setInterval(loadNews, RELOAD_INTERVAL_SECONDS.news * 1000)
    );
    dataFetchingIntervalIDs.push(
      setInterval(loadDevices, RELOAD_INTERVAL_SECONDS.devices * 1000)
    );
  }
}

function stopRepeatingAPICalls() {
  dataFetchingIntervalIDs.forEach((i) => clearInterval(i));
}

const getAPIData = async () => {
  if (!globalConfigStore.connector) {
    console.error("No connector");
    return;
  }

  // we need devices and ui-settings first for the other endpoints
  await Promise.all([
    loadDashboardData(), // get-dashboards
    loadDevices(), // get-devices
  ]);
  const parallelLoadingList = [
    loadDeviceTypes(),
    loadVariables(), // get-variables
    loadAlarms(), // get-alarms file-wide
    loadIssues(), // get-issues file-wide
    loadInventory(), // get-inventory-management Inventory Management
    loadNews(), // get changelog etc.
    guidedTourStore.initializeTours(),
  ];
  await Promise.all(parallelLoadingList);
};

const checkSessionInTheResponse = (error: string) => {
  if (error == "Session invalid") {
    // TODO: Create a global logout function and replace this.
    // Mimic the logout flow
    globalConfigStore.isAuth = false;
    globalConfigStore.session = "";
    clearLocalStorage();
    stopRepeatingAPICalls();
    window.location.reload();
    router.push({ name: "Logout" });
    return;
  }
};

i18next.on("loaded", () => {
  i18nLoaded.value = true;
});

watch(
  () => globalConfigStore.isAuth,
  async (newV) => {
    // login
    if (newV) {
      globalConfigStore.isLoading = true;
      await getAPIData();
      startRepeatingAPICalls();
      globalConfigStore.isLoading = false;
    }
    // logout
    else if (newV === false) {
      stopRepeatingAPICalls();
    }
  }
);

watch(
  () => uiSettingsStore.haveFullRefresh,
  async (newV) => {
    if (newV) {
      globalConfigStore.isLoading = true;
      stopRepeatingAPICalls();
      await getAPIData();
      startRepeatingAPICalls();
      globalConfigStore.isLoading = false;
      uiSettingsStore.haveFullRefresh = false;
    }
  }
);
</script>
