<template>
  <ModalPointed
    :title="($t(tourStore.activeTour?.titleTranslationKey ?? '') as string)"
    :open="isActive"
    :user-can-close="false"
    :isGuidedTour="true"
    :isTourComplete="isTourComplete"
    :position="position"
    @skip="skipOrSaveForLater('skip')"
    @save-for-later="skipOrSaveForLater('save')"
  >
    <template #button>
      <div class="tw-relative">
        <slot></slot>
        <div
          :class="
            isActive
              ? 'tw-border-solid tw-border-2 tw-border-secondary tw-rounded tw-h-full tw-w-full tw-absolute tw-z-40 tw-top-0 tw-left-0 padded-full'
              : ''
          "
        ></div>
      </div>
    </template>
    <GuidedTourContent />
  </ModalPointed>
  <div :class="isActive ? '!tw-bg-pink-500' : ''"></div>
</template>

<script setup lang="ts">
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";
import { computed, ref, PropType } from "vue";
import ModalPointed from "../ModalPointed/ModalPointed.vue";
import GuidedTourContent from "./GuidedTourContent.vue";
import { useGlobalConfigsStore } from "@/storeModules";
import { clearAndClose } from "@/utils/guidedTours/tourUtils";
import { modalPosition } from "../ModalPointed/ModalPointed";

const props = defineProps({
  targetID: { type: String, required: true },
  position: { type: String as PropType<modalPosition>, default: "" },
});

const tourStore = useGuidedTourStore();
const globalStore = useGlobalConfigsStore();
const targetID = ref(props.targetID);

const isActive = computed(() => {
  const activeElementID = tourStore.getActiveElementID;
  const isActive =
    !tourStore.activeTour?.isSkipped && targetID.value === activeElementID;
  return isActive;
});

const isTourComplete = computed(
  () => tourStore?.activeTour?.currentStep === tourStore?.activeTour?.totalSteps
);

async function skipOrSaveForLater(skipOrSave: "skip" | "save") {
  clearAndClose();
  tourStore.setSkipped(true);
  if (skipOrSave === "skip") {
    return;
  }
  const tour = tourStore.activeTour;
  if (tour) {
    await globalStore.connector?.general.setTourInfo({
      guidedTourUiId: tour.id,
      name: tour.titleTranslationKey,
      section: tour.category,
      totalSteps: tour.steps.length,
      currentStep: tourStore.currentStep + 1,
      rank: tour.rank,
    });
    await tourStore.getCompletedOrSavedTours();
  }
}
</script>

<style scoped lang="postcss">
.padded-full {
  --highlight-padding: 7px;
  height: calc(100% + var(--highlight-padding) * 2);
  width: calc(100% + var(--highlight-padding) * 2);
  margin-left: calc(-1 * var(--highlight-padding));
  margin-top: calc(-1 * var(--highlight-padding));
}
</style>
