<template>
  <!-- Render error handler -->
  <div v-if="isError" class="tw-p-4">
    <slot name="error" v-bind="{ errorName, errorText }">
      <!-- Fallback content -->
      <div
        class="tw-w-full tw-h-full tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-lg"
        role="alert"
      >
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          class="tw-text-status-red tw-text-2xl"
        />
        <span
          data-test="error-text"
          class="tw-font-semibold"
          v-html="$t([errorText, 'im.tankapp.add_error_message'])"
        ></span>
        <div v-if="suggestions.length" class="tw-text-base tw-py-2">
          <ul>
            <li
              v-for="suggestion in suggestions"
              :key="suggestion"
              v-html="$t(suggestion)"
            ></li>
          </ul>
        </div>
      </div>
    </slot>
  </div>

  <div v-else>
    <!-- Render the child component if no error has been captured -->
    <slot name="default"> </slot>
  </div>
</template>
<script setup lang="ts">
import { onErrorCaptured, ref } from "vue";
import { SUGGESTIONS_BY_ERRORS, SuggestionKey } from "./SuggestionFactory";
import * as Sentry from "@sentry/vue";

const isError = ref<boolean>(false);
const errorText = ref<string>("");
const errorName = ref<string>("");
const suggestions = ref<string[]>([]);

const handleError = (error: Error) => {
  errorText.value = error.message;
  errorName.value = error.name;

  isError.value = true;
};

const props = defineProps({
  stack: {
    type: String,
  },
});

onErrorCaptured((error, _instance, info) => {
  console.error("Error Captured on ", props.stack, error, info);
  if (Object.keys(SUGGESTIONS_BY_ERRORS).includes(error.message)) {
    suggestions.value = SUGGESTIONS_BY_ERRORS[error.message as SuggestionKey];
  }
  // Send error to Sentry
  Sentry.captureException(error);
  handleError(error);
  return false;
});
</script>
