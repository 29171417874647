<template>
  <Tooltip :text="tooltipText || title || ''" :position="tooltipPosition">
    <button
      :data-test="dataTest"
      class="tw-flex tw-justify-center tw-text-base tw-border tw-border-solid tw-border-primary-100 tw-w-11 tw-h-11 tw-rounded-full"
      :class="[
        outlined
          ? 'tw-border tw-border-solid tw-border-primary-100'
          : 'tw-border-0',
        variant
          ? variantColors[variant]
          : ' tw-bg-white hover:tw-bg-primary-100 tw-text-primary-300 active:tw-border-primary-300',
      ]"
      :title="title"
      :type="eventType"
    >
      <div
        class="tw-m-auto tw-flex tw-flex-col tw-text-clip tw-overflow-hidden"
      >
        <font-awesome-icon :icon="faIcon" />
        <div
          v-if="text"
          class="tw-text-[0.6rem] tw-mb-[-0.4rem] tw-mt-[-0.2rem] tw-text-center tw-uppercase"
        >
          {{ text }}
        </div>
      </div>
    </button>
  </Tooltip>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import Tooltip from "../Tooltip/Tooltip.vue";
import {
  BaseButtonVariant,
  ButtonEventTypes,
} from "../BaseButton/BaseButtonUtils";

const variantColors = {
  primary: "tw-text-primary-contrast tw-bg-primary-500 hover:tw-bg-primary-700",
  secondary:
    "tw-text-primary-contrast tw-bg-secondary-500 hover:tw-bg-secondary-700",
  neutral: "tw-text-primary-contrast tw-bg-white hover:tw-bg-primary-100",
  statusGreen:
    "tw-text-primary-contrast tw-bg-status-green-500 hover:tw-bg-status-green-700",
  statusYellow:
    "tw-text-primary-contrast tw-bg-status-yellow-500 hover:tw-bg-status-yellow-700",
  statusRed:
    "tw-text-primary-contrast tw-bg-status-red-500 hover:tw-bg-status-red-700",
  statusBlue:
    "tw-text-primary-contrast tw-bg-status-blue-500 hover:tw-bg-status-blue-700",
};

defineProps({
  faIcon: { type: String, required: true },
  text: { type: String, default: "" },
  outlined: { type: Boolean, default: true },
  title: { type: String, default: "" },
  tooltipText: { type: String },
  tooltipPosition: {
    type: String as PropType<"top" | "bottom" | "left" | "right">,
    default: "bottom",
  },
  variant: {
    type: String as PropType<BaseButtonVariant>,
  },
  eventType: {
    type: String as PropType<ButtonEventTypes>,
    required: false,
    default: "button",
  },
  dataTest: String,
});
</script>
