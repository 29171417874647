import { useDeviceStore, useGlobalConfigsStore } from "@/storeModules";

export interface ResultSuccess {
  type: "ResultSuccess";
  deviceID: number;
}

export interface ResultExists {
  type: "ResultExists";
  deviceID: number;
}

export interface ResultAdminApproval {
  type: "ResultAdminApproval";
}

export interface ResultError {
  type: "ResultError";
  message: string;
}

export type AddDeviceResult =
  | ResultSuccess
  | ResultExists
  | ResultAdminApproval
  | ResultError;

export async function addDeviceBySerialNumber(options: {
  name: string;
  type: string;
  serialNumber: string;
  tag: string;
}): Promise<AddDeviceResult> {
  // check if device already exists
  const deviceStore = useDeviceStore();
  const possibleExistentDevice = deviceStore.devices.find(
    (d) =>
      d.sn === options.serialNumber &&
      d.type.toLowerCase().startsWith(options.type.toLowerCase())
  );
  if (possibleExistentDevice != undefined) {
    return { type: "ResultExists", deviceID: possibleExistentDevice.id };
  }
  const data = {
    name: options.name,
    devicetype: options.type,
    sn: options.serialNumber,
    tag: options.tag,
  };
  try {
    const connector = useGlobalConfigsStore().connector;
    if (connector) {
      const response = await connector.devices.addDevice({
        name: data.name,
        devicetype: data.devicetype,
        sn: data.sn,
        tag: data.tag,
      });
      if (
        response.includes(
          "The device will be added to your account once the owner has agreed."
        ) ||
        response.includes(
          "Das Gerät wird Ihrem Konto hinzugefügt werden, sobald der Besitzer damit einverstanden ist."
        )
      ) {
        return { type: "ResultAdminApproval" };
      } else {
        return { type: "ResultSuccess", deviceID: parseInt(response) };
      }
    }
  } catch (error) {
    return { type: "ResultError", message: String(error) };
  }
  return { type: "ResultError", message: "TBD!" };
}
