<template>
  <div @keydown="handleKeyDown" class="tw-text-base">
    <BaseLabel
      :inputId="inputId!"
      :label="label || ''"
      :labelAlignment="labelAlignment"
      :additionalData="additionalData"
      :secondaryLabel="secondaryLabel"
    >
      <input
        ref="inputRef"
        @focus="handleFocus"
        @blur="handleFocusLoss"
        :class="`${disabled ? 'tw-opacity-50' : ''}  tw-input-base`"
        :id="inputId"
        type="text"
        :disabled="disabled"
        :placeholder="placeholder"
        :required="required"
        :data-test="dataTest"
        v-model="textInput"
        :isValid="isValid"
        autocomplete="off"
      />
      <div class="tw-absolute tw-z-10 tw-bg-gray-200 -tw-mt-4">
        <div v-for="(suggestion, index) in suggestedElements" :key="suggestion">
          <div
            @click.stop="() => (textInput = suggestion)"
            class="tw-flex tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-bg-primary hover:tw-text-primary-contrast"
            :class="
              index === activeIndex
                ? 'tw-bg-primary tw-text-primary-contrast'
                : ''
            "
            style="white-space: pre"
          >
            <div>{{ suggestionsBoldSplit[index][0] }}</div>
            <b>{{ suggestionsBoldSplit[index][1] }}</b>
            <div>{{ suggestionsBoldSplit[index][2] }}</div>
          </div>
        </div>
      </div>
    </BaseLabel>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, onMounted, ref, watch } from "vue";
import { BaseLabelProps } from "../BaseLabel/BaseLabelUtils";
import BaseLabel from "../BaseLabel/BaseLabel.vue";

const inputRef = ref<null | HTMLInputElement>(null);

const props = defineProps({
  disabled: { type: Boolean, required: false },
  placeholder: { type: String, required: false },
  modelValue: { type: String, required: true },
  required: { type: Boolean, required: false },
  value: { type: String, required: false },
  isValid: { type: Boolean, required: false, default: undefined },
  autocompleteList: { type: Array as PropType<string[]>, required: true },
  onlyValuesFromList: { type: Boolean, default: false },
  dataTest: { type: String, default: "" },
  maxResults: { type: Number, default: 5 },
  openListOnFocus: { type: Boolean, default: false },
  focusOnMounted: { type: Boolean, default: false },
  ...BaseLabelProps,
});

const emit = defineEmits(["update:modelValue"]);

const textInput = ref(props.modelValue);
const activeIndex = ref(-1);
const openListOnEmptyInput = ref(false);
const focus = ref(false);

const suggestedElements = computed(() => {
  if (
    !focus.value ||
    (focus.value && !openListOnEmptyInput.value && textInput.value === "")
  )
    return [];
  const filteredList = props.autocompleteList
    .filter((e) => e.toLowerCase().includes(textInput.value.toLowerCase()))
    .slice(0, props.maxResults);
  if (filteredList.length === 1 && filteredList[0] === textInput.value)
    return [];
  return filteredList;
});

const suggestionsBoldSplit = computed(() =>
  suggestedElements.value.map((e) => getBoldPart(e, textInput.value))
);

// reset active index, when input changes
watch(textInput, () => {
  activeIndex.value = -1;
});

// watcher to be able to limit input to list items
watch(textInput, () => {
  if (!props.onlyValuesFromList) {
    emit("update:modelValue", textInput.value);
  } else {
    if (props.autocompleteList.includes(textInput.value)) {
      emit("update:modelValue");
    }
  }
});

function handleFocus() {
  focus.value = true;
  openListOnEmptyInput.value = props.openListOnFocus;
}

function handleFocusLoss() {
  // if we trigger this to early we can not click on our suggestions anymore
  setTimeout(() => {
    focus.value = false;
  }, 150);
}

/**
 * Splits v into three parts: the one before comparand, comparand itself and the part after the comparand.
 * This function ignores capitalization
 * @param v
 * @param comparand
 */
function getBoldPart(v: string, comparand: string): [string, string, string] {
  const index = v.toLowerCase().indexOf(comparand.toLowerCase());
  if (index == -1) return [v, "", ""];
  return [
    v.slice(0, index),
    v.slice(index, index + comparand.length),
    v.slice(index + comparand.length),
  ] as [string, string, string];
}

function handleKeyDown(e: KeyboardEvent) {
  const key = e.key;
  switch (key) {
    case "ArrowDown":
      if (activeIndex.value < suggestedElements.value.length - 1) {
        activeIndex.value += 1;
        e.preventDefault();
      }
      break;
    case "ArrowUp":
      if (activeIndex.value > 0) {
        activeIndex.value -= 1;
        e.preventDefault();
      }
      break;
    case "Enter":
      if (activeIndex.value == -1) {
        return;
      }
      textInput.value = suggestedElements.value[activeIndex.value];
      e.preventDefault();
  }
}

onMounted(() => {
  if (props.focusOnMounted && inputRef.value != null) {
    inputRef.value.focus();
    inputRef.value.select();
  }
});
</script>
