import { onMounted, onUnmounted } from "vue";

export const useGlobalKeyListener = (key: string, onPressed: () => void) => {
  const keyFilteredCallback = (e: KeyboardEvent) => {
    if (e.key == key) onPressed();
  };

  onMounted(() => window.addEventListener("keydown", keyFilteredCallback));
  onUnmounted(() => {
    window.removeEventListener("keypress", keyFilteredCallback);
  });
};
