import { Session, Settings } from "./api/types";
import { DxAPIConnector } from "./api";
import { useGlobalConfigsStore, useUserConfigStore } from "./storeModules";

export const isUserAuthenticated = async () => {
  const userData = window.localStorage.getItem("prominent.userData");
  if (!userData) {
    return false;
  }

  const globalConfigStore = useGlobalConfigsStore();
  const userConfigStore = useUserConfigStore();

  const userObject = JSON.parse(atob(userData)) as Session;
  globalConfigStore.$patch({ session: userObject.id });
  const connector = new DxAPIConnector("", userObject.id);

  try {
    const isSessionValid = await connector.general.getSettings();

    if (
      typeof isSessionValid === "string" &&
      isSessionValid.startsWith("error")
    ) {
      return false;
    }
    globalConfigStore.$patch({ connector });
    globalConfigStore.$patch({ isAuth: true });
    userConfigStore.$patch({ userData: userObject });
    userConfigStore.userSettings = isSessionValid as Settings;

    return true;
  } catch (error) {
    console.error("errror", error);
    return false;
  }
};
