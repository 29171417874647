import {
  DXDevice,
  DXDevice24h,
  DXDeviceType,
  DXIssue,
  DXValueHistory,
  PendingValue,
} from "@/types";
import { asyncCall } from "./call";
import {
  CurrentValue,
  Device,
  DeviceConfig,
  DeviceIdentifiers,
  DeviceLog,
  Issue,
  NewDevice,
  NewDeviceLog,
  ServerResponse,
  SparePartOrder,
  TLAInfo,
  Variable,
  VirtualVariable,
} from "./types";
export class DeviceConnector {
  private sessionID: string;
  private urlPrefix: string;

  constructor(urlPrefix: string, sessionID: string) {
    this.urlPrefix = urlPrefix;
    this.sessionID = sessionID;
  }

  // TODO: Not sure about this one.
  /**
   * @target "/api/set-device-config-multi"
   * @param options
   * @returns
   */
  async setDeviceConfigMulti(options: {
    device: DeviceIdentifiers["device"];
    keyValuePairs: { [key: string]: string };
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-device-config-multi",
      {
        session: this.sessionID,
        device: options.device,
        ...options.keyValuePairs,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-value"
   * @param options
   * @returns
   */
  async setValue(options: {
    id: DeviceIdentifiers["device"];
    index: number;
    subindex: number;
    value: string | number;
    code: number;
    isCancelation?: boolean;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-value",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  // TODO: Not sure about this one.
  /**
   * @target "/api/copy-device-config"
   * @param options
   * @returns
   */
  async copyDeviceConfig(options: {
    from: DeviceIdentifiers["device"];
    to: DeviceIdentifiers["device"];
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/copy-device-config",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  
  /**
   * @target "/api/get-value-history"
   * @param options
   * @returns
   */
  async getValueHistory(options: {
    device: DeviceIdentifiers["device"];
    index: number;
    subindex: number;
    from: string;
    to: string;
  }) {
    const result: ServerResponse | DXValueHistory = await asyncCall(
      this.urlPrefix + "/api/get-value-history",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }
  /**
   * @target "/api/get-variables"
   * @returns
   */
  async getVariables() {
    const result: ServerResponse | Variable[] = await asyncCall(
      this.urlPrefix + "/api/get-variables",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * @target "/api/add-device"
   * @param options
   * @returns
   */
  async addDevice(options: NewDevice) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-device",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }
  /**
   * @target "/api/add-device-log"
   * @param options
   * @returns
   */
  async addDeviceLog(options: NewDeviceLog) {
    const formData = new FormData();
    formData.append("device", options.device.toString());
    formData.append("message", options.message);
    formData.append("attachment", options.attachment);
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-device-log",
      { session: this.sessionID },
      formData
    );
    return result;
  }

  /**
   * @target "/api/rename-tag"
   * @param options
   * @returns
   */
  async renameTag(options: { from: string; to: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/rename-tag",
      { session: this.sessionID, ...options }
    );
    return result;
  }

  /**
   * @target "/api/add-device-tag"
   * @param options
   * @returns
   */
  async addDeviceTag(options: {
    device: DeviceIdentifiers["device"];
    tag: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-device-tag",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/remove-device-tag"
   * @param options
   * @returns
   */
  async removeDeviceTag(options: {
    device: DeviceIdentifiers["device"];
    tag: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/remove-device-tag",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  async getDeviceTypes() {
    const result: ServerResponse | DXDeviceType[] = await asyncCall(
      this.urlPrefix + "/api/get-device-types",
      { session: this.sessionID }
    );
    return result;
  }

  /**
   * @target "/api/get-all-issues"
   * @returns
   */
  async getAllIssues() {
    const result: ServerResponse | DXIssue[] = await asyncCall(
      this.urlPrefix + "/api/get-all-issues",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-current-values"
   * @param options
   * @returns
   */
  async getCurrentValues(options: { device: DeviceIdentifiers["device"] }) {
    const result: CurrentValue[] = await asyncCall(
      this.urlPrefix + "/api/get-current-values",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-24h-statistics"
   * @param options
   * @returns
   */
  async getCurrent24h(options: {
    device: DeviceIdentifiers["device"];
    index: string;
    subindex: string;
  }) {
    const result: Record<number, DXDevice24h> = await asyncCall(
      this.urlPrefix + "/api/get-24h-statistics",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-24h-all-variables"
   * @param options
   * @returns
   */
  async get24hAllVariables(options: { device: DeviceIdentifiers["device"] }) {
    const result: ServerResponse | Record<string, Record<number, DXDevice24h>> =
      await asyncCall(this.urlPrefix + "/api/get-24h-all-variables", {
        session: this.sessionID,
        ...options,
      });
    return result;
  }

  /**
   * @target "/api/get-device-config"
   * @param options
   * @returns
   */
  async getDeviceConfig(options: { device: DeviceIdentifiers["device"] }) {
    const result: ServerResponse | DeviceConfig = await asyncCall(
      this.urlPrefix + "/api/get-device-config",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-device-log"
   * @param options
   * @returns
   */
  async getDeviceLog(options: {
    device: DeviceIdentifiers["device"];
    from?: string;
    to?: string;
  }): Promise<DeviceLog[]> {
    const result: DeviceLog[] = await asyncCall(
      this.urlPrefix + "/api/get-device-log",
      {
        session: this.sessionID,
        ...options,
      }
    );
    if (!Array.isArray(result)) return [];
    return result;
  }

  /**
   * @target "/api/get-devices"
   * @returns
   */
  async getDevices() {
    const result: ServerResponse | DXDevice[] = await asyncCall(
      this.urlPrefix + "/api/get-devices",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-issues"
   * @param options
   * @returns
   */
  async getIssues(options: { device: DeviceIdentifiers["device"] }) {
    const result: ServerResponse | Issue[] = await asyncCall(
      this.urlPrefix + "/api/get-issues",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/remove-device"
   * @param options
   * @returns
   */
  async removeDevice(options: { id: DeviceIdentifiers["device"] }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/remove-device",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-device-config"
   * @param options
   * @returns
   */
  async setDeviceConfig(options: {
    device: DeviceIdentifiers["device"];
    key: string;
    value: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-device-config",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-device-name"
   * @param options
   * @returns
   */
  async setDeviceName(options: {
    device: DeviceIdentifiers["device"];
    name: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-device-name",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-device-offline-mail"
   * @param options
   * @returns
   */
  async setDeviceOfflineMail(options: {
    device: DeviceIdentifiers["device"];
    value: number;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-device-offline-mail",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-device-smoothing"
   * @param options
   * @returns
   */
    async setNoiseReduction(options: {
      device: DeviceIdentifiers["device"];
      value: number;
    }) {
      const result: ServerResponse = await asyncCall(
        this.urlPrefix + "/api/set-device-smoothing",
        {
          session: this.sessionID,
          ...options,
        }
      );
      return result;
    }

  /**
   * Requires identcode to fetch spare parts.
   * @target "/api/get-spare-parts"
   * @param options
   * @returns
   */
  async getSpareParts(options: { identcode: string }) {
    const result: { [key: string]: string } = await asyncCall(
      this.urlPrefix + "/api/get-spare-parts",
      {
        ...options,
      }
    );
    return result;
  }

  /**
   * Sends an email with the spare part list. It doesn't do any orders.
   * @target "/api/order-spare-parts"
   * @param options
   * @returns
   */
  async orderSpareParts(options: {
    device: DeviceIdentifiers["device"];
    email: string;
    items: SparePartOrder[];
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/order-spare-parts",
      {},
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  async getDeviceLinkedTankApp(options: { device_id: number }) {
    const result: TLAInfo = await asyncCall(
      this.urlPrefix + "/api/get-device-linked-tank-app",
      {
        ...options,
        session: this.sessionID,
      }
    );
    return result;
  }

  // special snowflake methods for reports, since they are special as well

  dateToReportName(d: Date) {
    const zeroPad = (v: number) => (v > 9 ? v.toString() : "0" + v);
    return `${d.getFullYear()}-${zeroPad(d.getMonth() + 1)}-${zeroPad(
      d.getDate()
    )}`;
  }

  getTagString(tag: string) {
    // the reportgenerator demands this format!
    return (tag !== "" ? `tag_${tag}` : "dash_all-devices").replace(
      /\s+/g,
      "-"
    );
  }

  getReportName(tag: string, start: Date, end: Date, userID: string) {
    const startString = this.dateToReportName(start);
    const endString = this.dateToReportName(end);
    const tagString = this.getTagString(tag);

    return `${userID}_${tagString}_${startString}_${endString}.xlsx`;
  }

  async reportExists(options: {
    tag: string;
    start: Date;
    end: Date;
    userID: string;
  }) {
    const endpoint =
      this.urlPrefix + "/reports/" + this.sessionID + "/public/doc/report/";
    const reportName = this.getReportName(
      options.tag,
      options.start,
      options.end,
      options.userID
    );
    try {
      const res = await fetch(endpoint + reportName, { method: "HEAD" });
      return {
        exists: res.status === 200,
        url: endpoint + reportName,
        tagString: this.getTagString(options.tag),
      };
    } catch (_) {
      return { exists: false, url: "", tagString: "" };
    }
  }

  async addVirtualDevice(options: {
    description?: string;
    name: string;
    comment?: string;
    tag: string;
  }) {
    const res: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-virtual-device",
      { ...options, session: this.sessionID }
    );
    return res;
  }

  async getVirtualDevices() {
    const res: ServerResponse | Device[] = await asyncCall(
      this.urlPrefix + "/api/get-virtual-devices",
      { session: this.sessionID }
    );
    return res;
  }

  async updateVirtualDevice(options: { name: string; id: number }) {
    const res: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/update-virtual-device",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return res;
  }

  async deleteVirtualDevice(options: { id: number }) {
    return (await asyncCall(this.urlPrefix + "/api/delete-virtual-device", {
      session: this.sessionID,
      ...options,
    })) as ServerResponse;
  }

  async addVirtualVariable(
    options: {
      deviceId: number;
      sppindex: number;
      sppsubindex: number;
      decimalPlaces: number;
      isAlarmRelevant: number;
      rank: number;
      mainRank: number;
      isWhitelisted: number;
      showPreview: number;
      varName: string;
      varGroup: string;
      unit: string;
    },
    calculationJSON: string
  ) {
    return (await asyncCall(
      this.urlPrefix + "/api/add-virtual-variable",
      { session: this.sessionID, ...options },
      calculationJSON,
      { "Content-Type": "text/plain" }
    )) as ServerResponse;
  }

  async getVirtualVariables(options: {
    deviceId: number;
    sppindex?: number;
    sppsubindex?: number;
  }) {
    return (await asyncCall(this.urlPrefix + "/api/get-virtual-variable", {
      deviceId: options.deviceId,
      sppindex: options.sppindex ?? -1,
      sppsubindex: options.sppsubindex ?? -1,
      session: this.sessionID,
    })) as VirtualVariable[];
  }

  async updateVirtualVariable(
    options: {
      deviceId: number;
      sppindex: number;
      sppsubindex: number;
      decimalPlaces: number;
      isAlarmRelevant: number;
      rank: number;
      mainRank: number;
      isWhitelisted: number;
      showPreview: number;
      oldSppindex: number;
      oldSppsubindex: number;
      varName: string;
      varGroup: string;
      unit: string;
    },
    calculationJSON: string
  ) {
    return await asyncCall(
      this.urlPrefix + "/api/update-virtual-variable",
      {
        ...options,
        session: this.sessionID,
      },
      calculationJSON,
      { "Content-Type": "text/plain" }
    );
  }

  async deleteVirtualVariable(options: {
    varName: string;
    deviceId: number;
    sppindex: number;
    sppsubindex: number;
  }) {
    return (await asyncCall(this.urlPrefix + "/api/delete-virtual-variable", {
      session: this.sessionID,
      ...options,
    })) as ServerResponse;
  }

  async getPendingValues(options: { deviceID: number }) {
    return (await asyncCall(this.urlPrefix + "/api/get-pending-values", {
      session: this.sessionID,
      ...options,
    })) as ServerResponse | PendingValue[];
  }

  async getPredefinedCalculations() {
    const predefinedCalculations:any = await asyncCall(
      this.urlPrefix + "/api/get-predetermined-calculations",
      { session: this.sessionID }
    );
    return predefinedCalculations;
  }

  async isDeviceUsedByVirtualVariables(options: {deviceId: number;}) {
    return (await asyncCall(this.urlPrefix + "/api/is-device-used-by-virtual-variable", {
      deviceId: options.deviceId,
      session: this.sessionID
    })) as string;
  }
}
