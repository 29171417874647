import {
  DXDevice,
  DXIDStack,
  DXIMChemical,
  DXIMProcess,
  DXIMSite,
  DXIMTank,
  DXIMWarehouse,
} from "@/types";
import { defineStore } from "pinia";
import { useDeviceStore } from "../devices/DeviceStore";

export type DXInventory = {
  process: DXIDStack<DXIMProcess>;
  warehouse: DXIDStack<DXIMWarehouse>;
  tanks: DXIDStack<DXIMTank>;
  sites: DXIDStack<DXIMSite>;
  chemicals: DXIDStack<DXIMChemical>;
  unit: string;
  devicesWithLevelSensor: number[];
};

export const useIMStore = defineStore("inventoryManagement", {
  state: () => ({
    process: {} as DXIDStack<DXIMProcess>,
    warehouse: {} as DXIDStack<DXIMWarehouse>,
    tanks: {} as DXIDStack<DXIMTank>,
    sites: {} as DXIDStack<DXIMSite>,
    chemicals: {} as DXIDStack<DXIMChemical>,
    unit: "L" as string,
    devicesWithLevelSensor: [] as number[],
    tankAppImages: [],
  }),
  getters: {
    getInventoryProcessRaw(state) {
      const processRaw = state?.process;
      if (processRaw && Object.keys(processRaw).length > 0) {
        return processRaw;
      }
      return [];
    },
    getInventoryProcessByID: (state) => (id: number) => {
      const processes = state?.process;
      if (processes && Object.keys(processes).includes(id.toString())) {
        return processes[id];
      }
      return;
    },
    getInventoryManagementDevices(state) {
      const deviceStore = useDeviceStore();
      const devicesWithLevelSensor = state.devicesWithLevelSensor;
      if (deviceStore?.devices && devicesWithLevelSensor) {
        return deviceStore.devices
          .filter((device) => devicesWithLevelSensor.includes(device.id))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
      }
      return [];
    },
    getInventoryProcess(state) {
      const deviceStore = useDeviceStore();
      const deviceIds = deviceStore.devices.map((device) => device.id);
      const processRaw = state.process;
      if (processRaw) {
        return Object.values(processRaw).filter((process) =>
          deviceIds.includes(process.level_device_id)
        );
      }
      return [];
    },
    getInventoryProcessSortbyLevel() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        return processes.sort((a, b) =>
          compare(a.volumeLevel ?? 0, b.volumeLevel ?? 0, orderby)
        );
      };
    },
    getInventoryProcessSortbyPercent() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        return processes.sort((a, b) =>
          compare(a.percentLevel ?? 0, b.percentLevel ?? 0, orderby)
        );
      };
    },
    getInventoryProcessSortbySite() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        return processes.sort((a, b) => compare(a.site_id, b.site_id, orderby));
      };
    },
    getInventoryProcessSortbyCapacity() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        return processes.sort((a, b) =>
          compare(a.remaining_days ?? 0, b.remaining_days ?? 0, orderby)
        );
      };
    },
    getInventoryProcessSortbyName() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        return processes.sort((a, b) =>
          compare(a.name.toLowerCase(), b.name.toLowerCase(), orderby)
        );
      };
    },
    getInventoryProcessSortbyChemical() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        const chemicals = this.chemicals;
        if (chemicals && processes) {
          return processes.sort((a, b) =>
            compare(
              chemicals[a.chem_id].name.toLowerCase(),
              chemicals[b.chem_id].name.toLowerCase(),
              orderby
            )
          );
        }
        return [];
      };
    },
    getInventoryProcessSortbyCriticality() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        const tanks = this.tanks;
        if (tanks && processes) {
          return processes.sort((a, b) =>
            compare(
              Math.round(
                ((a.volumeLevel ?? 0) * 100) /
                  (tanks[a.tank_id].volumeSize ?? 1)
              ),
              Math.round(
                ((b.volumeLevel ?? 0) * 100) /
                  (tanks[b.tank_id].volumeSize ?? 1)
              ),
              orderby
            )
          );
        }
        return [];
      };
    },
    getInventoryProcessSortbyWarehouse() {
      return (orderby: "asc" | "desc" = "desc") => {
        const processes = this.getInventoryProcess;
        const warehouses = this.warehouse;

        /**
         * For all matching warehouses add in store volumes
         * @param site_id
         * @param chem_id
         * @returns
         */
        const getWarehouseLevel = (site_id: number, chem_id: number) => {
          return Object.values(warehouses)
            .filter((w) => w.site_id === site_id && w.chem_id === chem_id)
            .map((w) => w.items.reduce((a, b) => a + b.in_store * b.size, 0))
            .reduce((a, b) => a + b, 0);
        };

        if (warehouses && processes) {
          return processes.sort((a, b) =>
            compare(
              getWarehouseLevel(a.site_id, a.chem_id),
              getWarehouseLevel(b.site_id, b.chem_id),
              orderby
            )
          );
        }
        return [];
      };
    },
    getInventoryProcessBySiteSlugSortbyLevel() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyLevel(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryProcessBySiteSlugSortbyPercent() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyPercent(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    // originally was sorting processes by etaD, but I could not find that key anywhere else so we sort by remaining_days for now
    getInventoryProcessBySiteSlugSortbyCapacity() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyCapacity(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryProcessBySiteSlugSortbyName() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyName(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryProcessBySiteSlugSortbyChemical() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyChemical(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryProcessBySiteSlugSortbyCriticality() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyCriticality(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryProcessBySiteSlugSortbyWarehouse() {
      return (site_id: number, orderby: "desc" | "asc") => {
        return this.getInventoryProcessSortbyWarehouse(orderby).filter(
          (e) => e.site_id === site_id
        );
      };
    },
    getInventoryWarehouse(state) {
      const warehouse = state?.warehouse;
      return warehouse ? Object.values(warehouse) : [];
    },
    getInventoryChemicals(state) {
      return state.chemicals
        ? Object.values(state.chemicals).sort((a, b) =>
            compare(a.name.toLowerCase(), b.name.toLowerCase(), "asc")
          )
        : [];
    },
    getInventorySites: (state) => (sortby: string, orderby: "desc" | "asc") => {
      const sites = state?.sites;
      if (sites && Object.values(sites).length !== 0) {
        const availableKeys = Object.values(sites).reduce(
          (acc, v) => [...acc, ...Object.keys(v)],
          [] as string[]
        );
        if (availableKeys.includes(sortby)) {
          return Object.values(sites).sort((a, b) => {
            const aValue = (a as Record<string, string | number>)[sortby];
            const bValue = (b as Record<string, string | number>)[sortby];
            return compare(
              aValue.toString().toLowerCase(),
              bValue.toString().toLowerCase(),
              orderby
            );
          });
        } else if (sortby === undefined) return Object.values(sites);
        else throw new Error("Invalid sortby parameter: " + sortby);
      } else return [];
    },
    getInventoryTanks(state) {
      const tanks = state?.tanks;
      if (tanks) {
        return Object.values(tanks).sort((a, b) =>
          compare(a.name.toLowerCase(), b.name.toLowerCase(), "asc")
        );
      }
      return [];
    },
    // todo rename to get process by id
    getInventoryItemBySlug: (state) => (slug: number) => {
      const processes = state.process;
      if (processes && slug in processes) {
        return processes[slug] ?? false;
      }
      return false;
    },
    // todo rename to something like get chemical by process id
    getInventoryChemicalBySlug() {
      return (processID: number) => {
        const chemicals = this.chemicals;
        const process = this.getInventoryItemBySlug(processID);
        if (chemicals && process) {
          return chemicals[process.chem_id] ?? false;
        } else return false;
      };
    },
    getInventoryChemicalByChemId: (state) => (id: number) => {
      const chemicals = state.chemicals;
      if (chemicals && id in chemicals) {
        return chemicals[id];
      }
      return;
    },
    getInventoryTankByTankId: (state) => (id: number) => {
      const tanks = state.tanks;
      if (tanks && id in tanks) {
        return tanks[id] ?? false;
      }
      return;
    },
    // todo rename to something like getSiteByProcessID
    getInventorySiteBySlug() {
      return (processID: number) => {
        const sites = this.sites;
        const process = this.getInventoryItemBySlug(processID);
        if (sites && process) {
          return sites[process.site_id] ?? false;
        }
        return false;
      };
    },
    // todo rename to something like getTankByProcessID
    getInventoryTankBySlug() {
      return (processID: number) => {
        const tanks = this.tanks;
        const process = this.getInventoryItemBySlug(processID);
        if (tanks && process) {
          return tanks[process.tank_id] ?? false;
        }
        return false;
      };
    },
    getInventoryTankById: (state) => (id: number) => {
      return state.tanks?.[id] ?? false;
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdVolumeSize(): (tankID: number) => number {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.volumeSize ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdTankName() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.name ?? "";
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdTankType() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.type ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdDistanceSize() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.distanceSize ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdVolumeLo() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.volumeLo ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdDistanceLo() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.distanceLo ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdVolumeLolo() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.volumeLolo ?? 0;
      };
    },
    // todo remove this!!! this is not necessary!
    getInventoryTankByIdDistanceLolo() {
      return (tankID: number) => {
        return this.getInventoryTankById(tankID)?.distanceLolo ?? 0;
      };
    },
    // this function was heavily broken before!!!
    getInventoryTankByName: (state) => (name: string) => {
      const tanks = state.tanks;
      if (tanks) {
        return Object.values(tanks).filter((e) => e.name === name);
      }
      return [];
    },
    // todo refactor to use chemical id and site id instead of whole object
    getInventoryWarehouseByChemIDSiteID:
      (state) => (chemical: DXIMChemical, site: DXIMSite) => {
        const warehouses = state.warehouse;
        if (warehouses) {
          return (
            Object.values(warehouses).find(
              (e) => e.chem_id == chemical.id && e.site_id == site.id
            ) ?? false
          );
        }
        return false;
      },
    // todo rename to something like getInventoryWarehouseItemByChemIDSiteID because the entire warehouse not only items are/is returned
    getInventoryWarehouseItemByChemIDSiteID:
      (state) => (chemID: number, siteID: number) => {
        const warehouses = state.warehouse;
        if (warehouses) {
          return Object.values(warehouses).find(
            (e) => e.chem_id == chemID && e.site_id == siteID
          );
        }
        return;
      },
    getInventoryManagementDeviceTypeSortbyTag:
      (state) => (isEditTankApp: boolean, preselectedDeviceId: number) => {
        const deviceStore = useDeviceStore();
        if (
          state == undefined ||
          state.devicesWithLevelSensor == undefined ||
          state.process == undefined
        )
          return {};

        let eligibleDevices = deviceStore.devices;
        // remove non-im-devices
        eligibleDevices = eligibleDevices.filter((e) =>
          state.devicesWithLevelSensor.includes(e.id)
        );
        // remove "used" devices
        const usedDeviceIds = Object.values(state.process).map(
          (e) => e.level_device_id
        );
        eligibleDevices = eligibleDevices.filter(
          (e) => !usedDeviceIds.includes(e.id)
        );
        // readd preselected device
        if (preselectedDeviceId && isEditTankApp) {
          const preselectedDevice = deviceStore.devices.find(
            (e) => e.id == preselectedDeviceId
          );
          if (preselectedDevice) eligibleDevices.push(preselectedDevice);
        }
        // group by tags
        const byTags = {} as Record<string, DXDevice[]>;
        eligibleDevices.forEach((device) => {
          // no tag
          if (device.tags.length == 0) {
            byTags[""] = [...(byTags[""] ?? []), device];
          }
          device.tags.forEach((tag) => {
            byTags[tag] = [...(byTags[tag] ?? []), device];
          });
        });
        return byTags;
      },
    getInventorySiteBySiteSlug: (state) => (id: number) => {
      const sites = state.sites;
      if (sites && id in sites) {
        return sites[id];
      }
      return;
    },
    // todo remove this since it does the exact same as the method above
    getInventorySiteBySiteID() {
      return (id: number) => this.getInventorySiteBySiteSlug(id);
    },
  },
  actions: {
    setInventory(data: DXInventory) {
      this.process = data.process;
      this.warehouse = data.warehouse;
      this.tanks = data.tanks;
      this.sites = data.sites;
      this.chemicals = data.chemicals;
      this.unit = data.unit || "L";
      this.devicesWithLevelSensor = data.devicesWithLevelSensor;
    },
    setAddTank(tank: DXIMTank) {
      if (this.tanks === undefined) {
        this.tanks = {};
      }
      this.tanks[tank.id] = tank;
    },
    setAddChemical(chem: DXIMChemical) {
      if (this.chemicals === undefined) this.chemicals = {};
      this.chemicals[chem.id] = chem;
    },
    // todo rename to something like setInventoryWarehouse because it does not actually set the item
    setInventoryWarehouseItem(item: DXIMWarehouse) {
      if (typeof this.warehouse === "undefined") this.warehouse = {};
      this.warehouse[item.id] = item;
    },
    // todo rename to something like removeProcess / removeTLA
    setInventoryItemRemove(id: number) {
      if (this.process[id] !== undefined) {
        delete this.process[id];
        return true;
      }
      return false;
    },
    setInventoryChemicalRemove(id: number) {
      if (this.chemicals[id] !== undefined) {
        delete this.chemicals[id];
        return true;
      }
      return false;
    },
    setInventoryTankRemove(id: number) {
      if (this.tanks[id] !== undefined) {
        delete this.tanks[id];
        return true;
      }
      return false;
    },
    setInventorySiteRemove(id: number) {
      if (this.sites[id] !== undefined) {
        delete this.sites[id];
        return true;
      }
      return false;
    },
  },
});

/**
 * Generic compare function for strings and numbers
 * @param a
 * @param b
 * @param orderby
 * @returns -1 or 1
 */
function compare<T extends number | string>(
  a: T,
  b: T,
  orderby: "desc" | "asc"
) {
  const comparator = a > b ? 1 : -1;
  const factor = orderby === "desc" ? -1 : 1;
  return factor * comparator;
}

export type IMStoreType = Omit<
  ReturnType<typeof useIMStore>,
  keyof ReturnType<typeof defineStore>
>;
