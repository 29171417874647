/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/vue";

async function call(
  url: string,
  params: Record<string, unknown>,
  callBack: (err: any, ret: any) => void,
  body?: object | string,
  additionalHeaders?: Record<string, string>
) {
  try {
    const response = await axios.request({
      method: "POST",
      url,
      params,
      headers: additionalHeaders,
      data: body,
    });
    const ret = response.data;
    if (typeof ret === "string" && ret.startsWith("error: ")) {
      callBack(ret.substring(7), null);
    } else {
      callBack(null, ret);
    }
  } catch (err) {
    handleAxiosError(err as AxiosError, callBack);
    Sentry.captureException(err, { data: { url } });
  }
}

/**
 * Handles async calls with axios.
 * @param url
 * @param params
 * @returns
 */
export async function asyncCall(
  url: string,
  params: Record<string, unknown>,
  body?: object | string,
  additionalHeaders?: Record<string, string>
): Promise<any> {
  return new Promise((resolve, reject) => {
    call(
      url,
      params,
      (err: string, ret: any) => {
        if (err != null) {
          reject(err);
        } else {
          resolve(ret);
        }
      },
      body,
      additionalHeaders
    );
  });
}

/**
 * Handles errors caused by axios and calls the callback with the proper error text.
 * @param err
 * @param callBack
 */
function handleAxiosError(
  err: AxiosError,
  callBack: (err: any, ret: any) => void
) {
  if (err.response) {
    callBack(err.response.statusText, null);
  } else if (err.request) {
    callBack("No response received", null);
  } else {
    callBack(err.message, null);
  }
}

function originalCall(
  url: string,
  params: Record<string, string>,
  cont: (err: any, ret: string | null) => void
) {
  const body = Object.keys(params)
    .map((i) => encodeURIComponent(i) + "=" + encodeURIComponent(params[i]))
    .join("&");
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body,
  })
    .then((ret) => ret.text())
    .then((ret: string) => {
      if (ret.startsWith("error: ")) {
        cont(capitalizeFirstLetter(ret.substring(7)), null);
      } else {
        cont(null, ret == "" ? {} : JSON.parse(ret));
      }
    })
    .catch((err) => {
      cont(err, null);
    });
}

export async function asyncOriginalCall(
  url: string,
  params: Record<string, string>
): Promise<any> {
  return new Promise((resolve, reject) => {
    originalCall(url, params, (err, ret) => {
      if (err != null) reject(err);
      else resolve(ret);
    });
  });
}

function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
