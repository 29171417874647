<template>
  <div>
    <div ref="targetRef" @click="handleClick">
      <slot name="button" :toggle="toggleModalOpen">
        <RoundIconButton fa-icon="user" @click="toggleModalOpen()" />
      </slot>
    </div>
    <div v-if="isOpen" class="tw-relative">
       <!-- Background Overlay -->
       <div
        v-if="isGuidedTour"
        class="tw-fixed tw-overflow-scroll tw-no-scrollbar tw-bg-black tw-bg-opacity-20 tw-z-50 sm:tw-p-8 tw-p-2 tw-cursor-pointer tw-flex tw-top-0 tw-left-0 tw-w-full tw-h-full"
      ></div>
      <div
        class="tw-absolute tw-z-[100] tw-flex tw-items-center"
        :class="`${getOuterFlexDirectionClass(
          caretPos
        )} ${getOuterTranslationClass(modalPos)} ${
          isVisible ? 'tw-opacity-100' : 'tw-opacity-0'
        } ${getModalWidthClass(width)}`"
        :style="`margin-left: ${modalOffsetX}px; margin-top: ${modalOffsetY}px;`"
        ref="modalBodyRef"
      >
        <!-- Caret -->
        <div
          v-if="caretPos != 'off'"
          class="tw-w-0 tw-h-0 tw-border-primary-500 tw-border-solid tw-border-l-8 tw-border-l-transparent tw-border-r-8 tw-border-r-transparent tw-border-b-8 tw-border-t-0"
          :class="getCaretRotationClass(caretPos)"
        />
        <!-- Modal Body -->
        <div
          @click.stop
          class="tw-bg-primary tw-shadow-lg tw-rounded-lg tw-border-solid tw-border-primary tw-w-full"
          :class="getRoundedCornerClass(modalPos)"
          role="alertdialog"
          aria-modal="true"
          aria-describedby="pointe-modal-title"
        >
          <div
            v-if="title != ''"
            id="pointe-modal-title"
            class="tw-font-bold tw-text-primary-contrast tw-text-base tw-bg-primary-500 tw-py-2 tw-px-2"
          >
            {{ title }}
          </div>
          <div class="tw-bg-white tw-p-2">
            <slot />
          </div>
          <div
            v-if="isGuidedTour"
            class="tw-flex tw-justify-end tw-space-x-2 tw-p-2 tw-bg-primary-400"
          >
            <BaseButton
              variant="neutral"
              size="small"
              :text="($t('modals.tour_label_skip') as string)"
              @click="emit('skip')"
            >
            </BaseButton>
            <BaseButton
              v-if="!isTourComplete"
              variant="secondary"
              size="small"
              :text="($t('modals.tour_label_save_for_later') as string)"
              @click="emit('save-for-later')"
            >
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RoundIconButton from "../RoundIconButton/RoundIconButton.vue";
import { getPageDimensions } from "../ContextMenu/ContextMenuUtils";
import { useUISettingsStore } from "@/storeModules";
import { PropType, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import {
  PointedModalWidth,
  modalPosition,
  caretPosition,
  getCaretRotationClass,
  getModalWidthClass,
  getOuterFlexDirectionClass,
  getOuterTranslationClass,
  getRoundedCornerClass,
} from "./ModalPointed";
import { useGlobalKeyListener } from "@/utils/GlobalKeyListener";
import BaseButton from "@/components/library/BaseButton/BaseButton.vue";
import { useIsMobile } from "@/utils/useIsMobile";

const { isMobile } = useIsMobile();
const targetRef = ref<null | HTMLDivElement>(null);
const modalBodyRef = ref<null | HTMLDivElement>(null);

const props = defineProps({
  width: {
    type: String as PropType<PointedModalWidth>,
    default: "medium",
  },
  title: { type: String, default: "" },
  open: { type: Boolean, default: false },
  userCanClose: { type: Boolean, default: true },
  isGuidedTour: {
    type: Boolean,
    default: false,
  },
  isTourComplete: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String as PropType<modalPosition>,
    default: "",
  },
});

const position = ref(props.position);
const emit = defineEmits<{
  (e: "close-modal"): void;
  (e: "open-modal"): void;
  (e: "skip"): void;
  (e: "save-for-later"): void;
}>();

const uiStore = useUISettingsStore();

const isOpen = ref(false);
const isVisible = ref(false);
const modalPos = ref<modalPosition>("top-middle");
const caretPos = ref<caretPosition>("top");

const modalOffsetX = ref(0);
const modalOffsetY = ref(0);

useGlobalKeyListener("Escape", () => {
  closeModal();
});

watch(
  () => props.open,
  async (newVal) => {
    if (newVal !== isOpen.value) {
      if (newVal) {
        await openModalAndPlace();
      } else {
        closeModal();
      }
    }
  },
  { immediate: true }
);

// close all modals hook
uiStore.$onAction((action) => {
  if (action.name == "closeAllPointedModals" && props.userCanClose)
    closeModal();
});

function toggleModalOpen() {
  if (isOpen.value) closeModal();
  else openModalAndPlace();
}

function closeModal() {
  isOpen.value = false;
  emit("close-modal");
}

async function openModalAndPlace() {
  uiStore.closeAllPointedModals();
  isVisible.value = false;
  isOpen.value = true;
  await nextTick();
  if (position.value) {
    placeModal(position.value as modalPosition);
  } else {
  // positions will be checked in this order
  const basePriorityList: modalPosition[] = [
    "bottom-middle",
    "middle-right",
    "middle-left",
    "top-middle",
    "bottom-right",
    "bottom-left",
    "top-right",
    "top-left",
  ];

  const priorityList: modalPosition[] = isMobile.value
  ? [...basePriorityList, "mid-screen"]
  : basePriorityList;

  const fallbackPosition: modalPosition = isMobile.value ? "mid-screen" : "bottom-middle";
  const mainContainerRef = document.getElementById("main");

  const isModalInbounds = (): boolean => {
    if (!modalBodyRef.value || !mainContainerRef) return false;
    const modalBBox = modalBodyRef.value?.getBoundingClientRect();
    const mainContainerBBox = mainContainerRef.getBoundingClientRect();
    const pageSize = getPageDimensions(); // we use page-size to ensure the modal
    const margin = 10;
    return (
      modalBBox.x - margin >= mainContainerBBox.x &&
      modalBBox.y - margin >= mainContainerBBox.y &&
      modalBBox.x + modalBBox.width + margin <= pageSize.width &&
      modalBBox.y + modalBBox.height + margin <= pageSize.height
    );
  };
  let validPositionFound = false;
  for (const position of priorityList) {
    placeModal(position);
    await nextTick();
    await nextTick();
    if (isModalInbounds()) {
      validPositionFound = true;
      break;
    }
  }
  if (!validPositionFound) {
    placeModal(fallbackPosition); // fallback
  }
}
  isVisible.value = true;
  scrollToModal();
  emit("open-modal");
}

async function placeModal(position: modalPosition) {
  modalPos.value = position;
  await nextTick();
  // get target BBOX
  if (!targetRef.value) return false;
  const targetBBox = targetRef.value.getBoundingClientRect();
  modalOffsetX.value = 0;
  modalOffsetY.value = 0;
  caretPos.value = "off";
  switch (position) {
    case "top-left":
      modalOffsetY.value = -targetBBox.height;
      break;
    case "top-middle":
      modalOffsetY.value = -targetBBox.height;
      modalOffsetX.value = targetBBox.width / 2;
      caretPos.value = "bottom";
      break;
    case "top-right":
      modalOffsetY.value = -targetBBox.height;
      modalOffsetX.value = targetBBox.width;
      break;
    case "middle-left":
      modalOffsetY.value = -targetBBox.height / 2;
      caretPos.value = "right";
      break;
    case "middle-right":
      modalOffsetY.value = -targetBBox.height / 2;
      modalOffsetX.value = targetBBox.width;
      caretPos.value = "left";
      break;
    case "bottom-left":
      break;
    case "bottom-middle":
      modalOffsetX.value = targetBBox.width / 2;
      caretPos.value = "top";
      break;
    case "bottom-right":
      modalOffsetX.value = targetBBox.width;
      break;
    case "mid-screen":
      caretPos.value = "off";
      // center the modal on the screen horizontally
      modalOffsetX.value =
        -targetBBox.x +
        (window.innerWidth -
          (modalBodyRef.value?.getBoundingClientRect().width ?? 0)) /
          2;
      // center the modal on the screen vertically
      modalOffsetY.value =
        -targetBBox.y + (window.innerHeight - targetBBox.height) / 2;
      break;
  }
}

function scrollToModal() {
  if (modalBodyRef.value) {
    modalBodyRef.value.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

function handleClick(event: MouseEvent) {
  const clickedElement = event.target as HTMLElement;
  if (clickedElement && clickedElement.closest('[data-interactive="true"]')) {
    //if the clicked element is interactive, let the event propagate
    return;
  }
  //if the clicked element is non-interactive, prevent and stop the event
  event.preventDefault();
  event.stopPropagation();
}

const closeModalIfOpen = () => {
  if (isOpen.value) uiStore.closeAllPointedModals();
};

if (props.userCanClose) {
  onMounted(() => {
    window.addEventListener("click", closeModalIfOpen);
  });

  onUnmounted(() => {
    window.removeEventListener("click", closeModalIfOpen);
  });
}
</script>
