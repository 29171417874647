<template>
  <footer class="tw-bg-white tw-p-2" id="footer" data-include="footer">
    <div class="tw-flex">
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-text-sm sm:tw-text-base"
      >
        <div
          class="tw-flex tw-flex-row tw-flex-wrap sm:tw-gap-x-1 tw-items-center tw-justify-center"
        >
          <span>Copyright &copy; by </span>
          <a
            id="announcements"
            :href="
              globalStore.company?.websiteTranslatable[globalStore.language] ||
              globalStore.company?.websiteTranslatable['en']
            "
            class="tw-inline-block tw-px-1"
            target="_blank"
            v-html="
              globalStore._wlPreviewData.companyName ||
              globalStore.company?.legalName
            "
            data-test="footer-legal-name"
          ></a>
          <span data-test="footer-year">
            {{ new Date(Date.now()).getFullYear() }}. All Rights Reserved.
            &nbsp;
          </span>
        </div>
        <div
          class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-1 tw-items-center tw-justify-center"
        >
          <a
            :href="
              globalStore.company?.imprintTranslatable[globalStore.language] ||
              globalStore.company?.imprintTranslatable['en']
            "
            id="imprint"
            target="_blank"
            data-test="footer-imprint"
          >
            <div v-html="$t('footer.label_imprint')" />
          </a>

          <span class="tw-text-sm">|</span>
          <a
            :href="
              globalStore.company?.dataPrivacyTranslatable[
                globalStore.language
              ] || globalStore.company?.dataPrivacyTranslatable['en']
            "
            target="_blank"
            id="dataPrivacy"
            data-test="footer-data-privacy"
          >
            <div v-html="$t('footer.label_data_privacy')" />
          </a>
          <!-- OneTrust Cookies Settings button -->
          <!-- <a href="javascript:void(0)" class="ot-sdk-show-settings">
            <div v-html="$t('footer.label_cookie_preference')"></div>
          </a> -->
        </div>
      </div>
      <div class="tw--mt-12 tw-ml-auto tw-pl-2 tw-z-20">
        <a
          data-test="footer-logo-link"
          class=""
          :href="
            globalStore.company?.imprintTranslatable[globalStore.language] ||
            globalStore.company?.imprintTranslatable['en']
          "
          target="_blank"
        >
          <img
            data-test="footer-logo"
            :src="
              globalStore._wlPreviewData.companyLogo as string ||
              `/api/get-logo.svg?type=company&brand=${brand}`
            "
            class="tw-max-h-24 tw-w-24"
            alt="logo"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useGlobalConfigsStore } from "@/storeModules";
const globalStore = useGlobalConfigsStore();
const brand = globalStore.brand;
</script>

<style lang="postcss" scoped>
a {
  @apply tw-text-primary-600 hover:tw-underline tw-italic tw-font-semibold;
}
</style>
