<template>
  <div
    class="tw-shrink-0 md:tw-w-96 tw-absolute md:tw-relative tw-z-40 tw-w-full tw-min-h-full tw-max-h-full tw-overflow-auto tw-bg-primary-500 tw-transition- tw-duration-300"
    :class="
      additionalClasses +
      ' ' +
      (uiStore.sideBarCollapsed
        ? 'md:-tw-ml-96 md:tw-translate-x-0 -tw-translate-x-full'
        : 'md:tw-ml-0')
    "
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useUISettingsStore } from "@/storeModules";
defineProps({ additionalClasses: { type: String, default: "" } });

const uiStore = useUISettingsStore();
</script>
