<!-- Adapted from https://stackoverflow.com/questions/52020624/smooth-vue-collapse-transition-on-v-if -->
<template>
  <Transition
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    enter-active-class="transition-collapse-enter-active"
    leave-active-class="transition-collapse-leave-active"
  >
    <slot></slot>
  </Transition>
</template>

<script setup lang="ts">
function beforeEnter(el: Element) {
  if (el instanceof HTMLElement)
    requestAnimationFrame(() => {
      if (!el.style.height) {
        el.style.height = "0px";
      }
      el.style.display = "";
    });
}

function enter(el: Element) {
  if (el instanceof HTMLElement)
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.height = el.scrollHeight + "px";
      });
    });
}

function afterEnter(el: Element) {
  if (el instanceof HTMLElement) el.style.height = "";
}
function beforeLeave(el: Element) {
  if (el instanceof HTMLElement)
    requestAnimationFrame(() => {
      if (!el.style.height) {
        el.style.height = el.offsetHeight + "px";
      }
    });
}

function leave(el: Element) {
  if (el instanceof HTMLElement)
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.height = "0px";
      });
    });
}

function afterLeave(el: Element) {
  if (el instanceof HTMLElement) el.style.height = "";
}
</script>

<!-- Cannot be scoped -->
<style lang="postcss">
.transition-collapse-enter-active,
.transition-collapse-leave-active {
  @apply tw-overflow-hidden tw-ease-in-out tw-duration-500;
}
</style>
