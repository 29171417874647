import {
  Coordinates,
  Markers,
  Partition,
  VariableID,
  WidgetBase,
  WidgetType,
} from "@/api/types";

export const widgetDataMappingByType = (widgetType: WidgetType) => {
  switch (widgetType) {
    case "bar":
    case "gauge":
      return ["variable", "partition"];
    case "graph":
    case "single":
      return ["variable"];
    case "map":
      return ["markers"];
    case "weather":
      return ["locationName", "unit", "coordinates"];
    case "tank_level_application":
      return ["processID"];
    case "active_alarms":
      return ["showWarnings", "showAlarms", "showErrors"];
    default:
      return [];
  }
};

export class WidgetHandler {
  cardID: string;
  dashboardID: string;
  dashboardIMOnly: boolean;
  readonly widgetID: string;
  readonly type: WidgetBase["type"];
  coordinates?: Coordinates;
  partition?: Partition;
  variable?: VariableID;
  locationName?: string;
  unit?: "metric" | "imperial" | "standard";
  processID?: string;
  markers?: Markers;
  locationDetails: {
    country: string;
    name: string;
  } = { country: "", name: "" };

  showWarnings?: boolean;
  showAlarms?: boolean;
  showErrors?: boolean;

  constructor(
    widget: WidgetBase,
    cardID: string,
    dashboardID: string,
    dashboardIMOnly: boolean
  ) {
    this.cardID = cardID;
    this.dashboardID = dashboardID;
    this.dashboardIMOnly = dashboardIMOnly;
    if (!widget.id) {
    }
    this.widgetID = widget.id;
    this.type = widget.type;
    this.setWidgetSpecificData(widget.data);
  }

  setWidgetSpecificData(data: WidgetBase["data"]) {
    if (!data) {
      return;
    }
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (key in data) {
        this[key as keyof this] = data[key as keyof WidgetBase["data"]];
      }
    });
  }

  getWidgetSpecificData() {
    const returnData: Partial<WidgetBase["data"]> = {};
    widgetDataMappingByType(this.type).forEach((key) => {
      Object.assign(returnData, { [key]: this[key as keyof this] });
    });
    return returnData;
  }
}
