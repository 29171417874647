export const SCREEN_SIZES = {
  sm: 640,
  md: 992,
  mdl: 1292,
  lg: 1432,
  xl: 2500,
};

export const SCREEN_SIZES_PIXEL = Object.fromEntries(
  Object.entries(SCREEN_SIZES).map(([key, value]) => [key, `${value}px`])
);

// screen size composable
export function getScreenSize() {
  return { height: window.innerHeight, width: window.innerWidth };
}
