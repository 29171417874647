import { WidgetType } from "@/api/types";

const PLATFORM_CONSENT: ServiceData[] = [
  {
    id: "openweather",
    name: "OpenWeatherMap",
    description: "consent.openweather_description",
    type: "api",
    dataTheyGet: [
      "consent.item_ip_address",
      "consent.item_browser",
      "consent.item_os",
      "consent.item_referrer",
      "consent.item_date_time",
    ],
  },
  {
    id: "openstreet",
    name: "OpenStreetMap",
    description: "consent.openstreet_description",
    type: "api",
    dataTheyGet: [
      "consent.item_ip_address",
      "consent.item_browser",
      "consent.item_os",
      "consent.item_referrer",
      "consent.item_date_time",
    ],
  },
  {
    id: "photon",
    name: "Photon",
    description: "consent.photon_description",
    type: "api",
    dataTheyGet: [
      "consent.item_ip_address",
      "consent.item_browser",
      "consent.item_os",
      "consent.item_referrer",
      "consent.item_date_time",
    ],
  },
];

const DX_ORANGE_CONSENT: ServiceData[] = [
  {
    id: "firebase",
    name: "Firebase Cloud Messaging",
    description: "consent.firebase_description",
    type: "api",
    dataTheyGet: [
      "consent.item_ip_address",
      "consent.item_browser",
      "consent.item_os",
      "consent.item_referrer",
      "consent.item_date_time",
      "Firebase installation IDs",
    ],
  },
];

export function getConsentList(dxOrange = false) {
  return dxOrange
    ? [...PLATFORM_CONSENT, ...DX_ORANGE_CONSENT]
    : PLATFORM_CONSENT;
}

export type ServiceData = {
  id: SERVICE_ID;
  name: string;
  description: string;
  type: string;
  dataTheyGet: string[];
};

export type SERVICE_ID = "openweather" | "openstreet" | "photon" | "firebase";

export const WIDGET_SERVICE_CONSENT_MAPPING: WidgetServiceMapping = {
  map: ["openstreet"],
  weather: ["openweather", "photon"],
};

export type WidgetServiceMapping = {
  [K in "map" | "weather" as WidgetType]?: SERVICE_ID[];
};
