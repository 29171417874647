import { PropType } from "vue";

export interface BaseLabelPropsInterface {
  label: string;
  inputId: string;
  labelAlignment: "vertical" | "horizontal";
  additionalData?: string;
  secondaryLabel?: string;
}
export const BaseLabelProps = {
  inputId: {
    type: String as PropType<BaseLabelPropsInterface["inputId"]>,
    required: true,
  },
  label: {
    type: String as PropType<BaseLabelPropsInterface["label"]>,
    required: true,
  },
  labelAlignment: {
    type: String as PropType<BaseLabelPropsInterface["labelAlignment"]>,
    required: false,
    default: "horizontal",
  },
  additionalData: {
    type: String as PropType<BaseLabelPropsInterface["additionalData"]>,
    required: false,
  },
  secondaryLabel: {
    type: String as PropType<BaseLabelPropsInterface["secondaryLabel"]>,
    required: false,
  },
};
