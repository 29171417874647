import { PublicMenuItem } from "@/components/layout/PublicMenu/PublicMenuUtils";

export const LOGIN: PublicMenuItem =   {
  path: "/welcome",
  icon: "key",
  translationKey: "navi.login",
  alignment: "right"
}
export const PAGES: PublicMenuItem[] = [
  {
    path: "/faq",
    translationKey: "navi.faq",
    icon: "",
    alignment: "left"
  },
  {
    path: "/documents",
    translationKey: "navi.documents",
    icon: "",
    alignment: "left"
  },
  {
    path: "/contact",
    translationKey: "navi.contact",
    icon: "",
    alignment: "left"
  },

  {
    path: "/signup",
    icon: "user-plus",
    translationKey: "navi.create",
    alignment: "right"
  },
];
