import { WidgetType } from "@/api/types";

export type SuggestionKey = keyof typeof SUGGESTIONS_BY_ERRORS;

export const SUGGESTIONS_BY_ERRORS = {
  "im.widget.process_not_found": [
    "suggestions.check_access",
    "suggestions.remove_widget",
  ],
  "errors.consent_required": ["suggestions.change_consent_in_profile"],
};

export const CONSENT_SUGGESTIONS_BY_WIDGET_TYPE: Partial<
  Record<WidgetType, string>
> = {
  map: "OpenStreetMap",
  weather: "OpenWeatherMap & Photon",
};
