<template>
  <div class="animate-400 tw-bg-primary-500 tw-pb-16" data-test="sidebar">
    <div
      v-html="$t('navi.subtitle_navigation')"
      class="tw-tracking-widest tw-uppercase md:tw-pt-8 tw-pt-16 tw-pl-4 tw-mb-2 tw-text-primary-contrast"
    ></div>
    <div v-for="(topLevelItem, index) of items" :key="index">
      <NavigationMainItem
        @click="toggleIsNavigationOpen"
        :icon="topLevelItem.logo"
        :target-route-name="topLevelItem.navigationPathName"
        :collapsable="
          topLevelItem.children.length > 0 || topLevelItem.type === 'DEVICE'
        "
        :starts-open="menuItemCollapsed[index]"
        @collapsed="toggleMenu(index, topLevelItem.navigationPathName, true)"
        @opened="toggleMenu(index, topLevelItem.navigationPathName, false)"
        >{{ t(topLevelItem.i18nkey) }}</NavigationMainItem
      >
      <!-- Subitems -->
      <TransitionCollapse name="grow-h">
        <div
          v-if="topLevelItem.type === 'NESTED' && menuItemCollapsed[index]"
          class="tw-bg-primary-700"
        >
          <router-link
            @click="toggleIsNavigationOpen"
            v-for="(child, index) in topLevelItem.children"
            :to="{ name: child.targetRouteName }"
            exact
            :key="index"
            active-class="active-link"
          >
            <div
              class="tw-text-inherit tw-pl-6 tw-py-2 hover:tw-bg-primary-600"
            >
              {{ t(child.i18nkey) }}
            </div>
          </router-link>
        </div>
        <div
          v-else-if="topLevelItem.type == 'DEVICE' && menuItemCollapsed[index]"
        >
          <NavigationDeviceList
            @navigated="toggleIsNavigationOpen"
            :devices-by-tags="topLevelItem.devicesByTags"
            :alarms="topLevelItem.alarms"
            :issues="topLevelItem.issues"
          />
        </div>
      </TransitionCollapse>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import NavigationMainItem from "./NavigationMainItem.vue";
import TransitionCollapse from "@/components/library/TransitionCollapse/TransitionCollapse.vue";
import NavigationDeviceList from "./NavigationDeviceList.vue";
import { t } from "i18next";
import { type NavigationToplevelItem } from "./NavigationUtil";
import { PropType } from "vue";
import { useUISettingsStore } from "@/storeModules";
import { SCREEN_SIZES, getScreenSize } from "@/screenSizes";
import {
  createCollapseMenu,
  readCollapseMenu,
  toggleCollapseMenu,
} from "@/utils/userpreferences/collapseMenu";

const props = defineProps({
  items: { type: Array as PropType<NavigationToplevelItem[]>, required: true },
});

const toggleMenu = (index: number, name: string, v: boolean) => {
  menuItemCollapsed.value[index] = v;
  toggleCollapseMenu(name, v);
};

const parseMenuCollapsedStatus = () => {
  const arr: boolean[] = [];
  props.items.forEach((entry) => {
    const userPreference = readCollapseMenu(entry.navigationPathName);
    if (userPreference === null) {
      arr.push(true);
      createCollapseMenu(entry.navigationPathName, true);
    } else {
      arr.push(userPreference);
    }
  });
  return arr;
};

const menuItemCollapsed = ref(parseMenuCollapsedStatus());

/**
 * Checks if the navigation should be toggled or not before running the function.
 */
const toggleIsNavigationOpen = () => {
  if (getScreenSize().width >= SCREEN_SIZES.md) {
    return;
  }
  const uiStore = useUISettingsStore();
  uiStore.sideBarCollapsed = !uiStore.sideBarCollapsed;
};
</script>

<style lang="postcss" scoped>
a {
  @apply tw-text-primary-contrast;
}

.active-link {
  @apply tw-text-secondary-500;
}
</style>
