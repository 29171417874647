type ColorWithShades = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  DEFAULT: string;
};
export type ColorScheme = {
  primaryColor: ColorWithShades;
  secondaryColor: ColorWithShades;
  contrastColor: string;
};

export const DEFAULT_NEUTRAL_COLOR_SCHEME: ColorScheme = {
  primaryColor: {
    DEFAULT: "#27292E",
    "50": "#999999",
    "100": "#777777",
    "200": "#4A4E57",
    "300": "#3E424A",
    "400": "#33353C",
    "500": "#27292E",
    "600": "#202226",
    "700": "#191A1D",
    "800": "#121315",
    "900": "#0B0B0D",
  },
  secondaryColor: {
    "50": "#eff7ff",
    "100": "#dcecfd",
    "200": "#c1dffc",
    "300": "#96ccfa",
    "400": "#64b0f6",
    "500": "#4392f1",
    "600": "#2b73e5",
    "700": "#225dd3",
    "800": "#224cab",
    "900": "#214387",
    DEFAULT: "#4392f1",
  },
  contrastColor: "#ffffff",
};

export function applyColorScheme(colorScheme: ColorScheme) {
  const root = document.querySelector(":root") as HTMLElement;
  if (root) {
    const applyColorWithShades = (prefix: string, color: ColorWithShades) =>
      Object.entries(color).forEach((e) => {
        root.style.setProperty(`--${prefix}-${e[0]}`, e[1]);
      });
    applyColorWithShades("primary", colorScheme.primaryColor);
    applyColorWithShades("secondary", colorScheme.secondaryColor);
    root.style.setProperty("--contrast-color", colorScheme.contrastColor);
  }
}
