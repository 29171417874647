<!--  -->
<template>
  <!-- Blow up flags slightly to ensure they always fill the circle 2.27 ≈ 2.5*(100/110) for correct translation after scaling -->
  <div
    class="tw-scale-110 tw-translate-x-[-2.27%] tw-translate-y-[-2.27%] tw-w-full tw-h-full flag-bg"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  flag: { type: String, required: true },
  squared: { type: Boolean, default: false },
});

const svgType = computed(() => {
  if (props.squared) return "1x1";
  return "4x3";
});

const flagSVGPath = computed(() => {
  // catch and rout language specific flags
  let countryCode = props.flag;
  if (countryCode == "en") countryCode = "gb";
  return "url(" + `/assets/img/flags/${svgType.value}/${countryCode}.svg` + ")";
});
</script>

<style scoped>
.flag-bg {
  background-image: v-bind(flagSVGPath);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
</style>
