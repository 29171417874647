const toggleCollapseMenu = (name: string, value: boolean) => {
  localStorage.setItem(
    `isMenuItemsCollapsed.${name}`,
    value ? "true" : "false"
  );
};

const createCollapseMenu = (name: string, val?: boolean) => {
  const desiredVal = typeof val === "boolean" ? val.toString() : "true";
  localStorage.setItem(`isMenuItemsCollapsed.${name}`, desiredVal);
};

const removeCollapseMenu = (name: string) => {
  localStorage.removeItem(`isMenuItemsCollapsed.${name}`);
};

const readCollapseMenu = (name: string) => {
  const val = localStorage.getItem(`isMenuItemsCollapsed.${name}`);
  if (val === null) return null;
  else return val === "true";
};

const createCollapseMenuIfNotExist = (name: string, val?: boolean) => {
  if (readCollapseMenu(name) === null) {
    createCollapseMenu(name, typeof val === "boolean" ? val : true);
  }
};

const renameCollapseMenu = (name: string, newName: string) => {
  const val = readCollapseMenu(name);
  removeCollapseMenu(name);
  createCollapseMenu(newName, typeof val === "boolean" ? val : true);
};

export {
  toggleCollapseMenu,
  createCollapseMenu,
  removeCollapseMenu,
  readCollapseMenu,
  createCollapseMenuIfNotExist,
  renameCollapseMenu,
};
