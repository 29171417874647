<template>
  <div class="tw-relative tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12">
    <svg class="tw-absolute tw-w-full tw-h-full tw-transform tw-rotate-180">
      <circle
        class="tw-text-gray-300"
        stroke-width="4"
        stroke="currentColor"
        fill="transparent"
        r="16"
        cx="50%"
        cy="50%"
      />
      <circle
        class="tw-text-blue-500"
        stroke-width="4"
        stroke="currentColor"
        fill="transparent"
        r="16"
        cx="50%"
        cy="50%"
        :stroke-dasharray="strokeDashArray"
        :stroke-dashoffset="strokeDashOffset"
      />
    </svg>
    <div class="tw-text-center">
      <div class="tw-text-xs tw-font-bold">
        {{ currentStep }}/{{ totalSteps }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from "vue";

const props = defineProps<{
  currentStep: number;
  totalSteps: number;
}>();

const radius = 16;
const circumference = 2 * Math.PI * radius;

const strokeDashArray = computed(() => {
  const stepPercentage = (props.currentStep / props.totalSteps);
  const dashArray = circumference * stepPercentage;
  const gapSize = circumference - dashArray;
  
  return `${dashArray} ${gapSize}`;
});

const strokeDashOffset = computed(() => {
  const stepPercentage = (props.currentStep / props.totalSteps);
  const dashOffset = circumference * (1 - stepPercentage);
  return dashOffset;
});
</script>

<style scoped></style>
