import { ServerResponse } from "http";
import { asyncCall } from "./call";
import { NewAlarm } from "./types";
import { DXAlarm } from "@/types";

export class AlarmsConnector {
  private sessionID: string;
  private urlPrefix: string;

  constructor(urlPrefix: string, sessionID: string) {
    this.urlPrefix = urlPrefix;
    this.sessionID = sessionID;
  }

  /**
   * Gets the alarmID and deletes the alarm.
   * @target "/api/delete-alarm"
   * @param alarmID as options.id
   * @returns
   */
  async deleteAlarm(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/delete-alarm",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Gets alarms from the server.
   * @target "/api/get-alarms"
   * @returns
   */
  async getAlarms() {
    const result: DXAlarm[] = await asyncCall(
      this.urlPrefix + "/api/get-alarms",
      {
        session: this.sessionID,
      }
    );
    if (!Array.isArray(result)) return [];
    return result;
  }

  /**
   * This function is used for creating alarms.
   * When you create a new alarm, options.id is not needed.   *
   * @target "/api/save-alarm"
   * @param options.id undefined
   * @param options.index
   * @param options.device
   * @param options.subindex
   * @param options.limit
   * @param options.interval
   * @param options.threshold
   * @param options.name
   * @param options.varname
   * @param options.operator
   * @param options.mailto
   * @param options.comment optional
   * @param options.active 1/0
   * @param options.autogenerated 1/0
   * @returns
   */
  async createAlarm(options: NewAlarm) {
    const result: number = await asyncCall(this.urlPrefix + "/api/save-alarm", {
      session: this.sessionID,
      ...options,
    });
    return result;
  }

  /**
   * This function is used for updating alarms.
   * ID is required. If you do not pass an id, it will create a new alarm.
   * @target "/api/save-alarm"
   * @alias createAlarm()
   * @param options.id
   * @param options.index
   * @param options.device
   * @param options.subindex
   * @param options.limit
   * @param options.interval
   * @param options.threshold
   * @param options.name
   * @param options.varname
   * @param options.operator
   * @param options.mailto
   * @param options.comment optional
   * @param options.active 1/0
   * @param options.autogenerated 1/0
   * @returns
   */
  async updateAlarm(options: NewAlarm) {
    return this.createAlarm(options);
  }
}
