import { useUISettingsStore } from "@/storeModules";
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";

export function clearAndClose() {
  const uiStore = useUISettingsStore();
  const tourStore = useGuidedTourStore();
  tourStore.tourStarted = false;
  uiStore.addDeviceModalOpen = false;
  uiStore.saveCalculationModalOpen = false;
  uiStore.clearFunctionList();
  uiStore.setGuidedTour(false);
  uiStore.openPredefinedCalculationWidget = false;
  uiStore.isPredefinedCalculationListOpen = false;
  uiStore.isDragingComponentsToTrash = false;
  uiStore.isCalculationCreating = false;
}
