<template>
  <button
    :class="`tw-border-none tw-duration-200 active:tw-shadow-[inset_0_3px_5px_rgba(0,0,0,0.125)] tw-text-xs sm:tw-text-sm tw-font-bold tw-uppercase tw-tracking-[2px] tw-cursor-not-allowed active:tw-cursor-pointer ${
      variantColors[variant || 'primary']
    } ${disabled || isWaiting ? 'tw-opacity-50' : ''} ${
      sizePadding[size || 'large']
    }`"
    :disabled="disabled || isWaiting"
    :type="eventType"
    :data-test="dataTest"
  >
    <font-awesome-icon
      v-if="isWaiting"
      class="tw-animate-spin"
      :icon="['fas', 'spinner']"
    />

    <font-awesome-icon
      v-if="icon && !isWaiting"
      class="my-auto tw-mr-2"
      :icon="['fas', icon]"
    />
    {{ text }}
  </button>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import {
  BaseButtonIcon,
  BaseButtonVariant,
  ButtonEventTypes,
} from "./BaseButtonUtils";

const variantColors = {
  primary: "tw-bg-primary-500 hover:tw-bg-primary-600 tw-text-primary-contrast",
  secondary: "tw-bg-secondary-500 hover:tw-bg-secondary-600 tw-text-white",
  neutral: "tw-bg-gray-200 hover:tw-bg-gray-200 tw-text-primary-600",
  statusGreen:
    "tw-bg-status-green-500 hover:tw-bg-status-green-600 tw-text-white",
  statusYellow:
    "tw-bg-status-yellow-500 hover:tw-bg-status-yellow-600  tw-text-white",
  statusRed: "tw-bg-status-red-500 hover:tw-bg-status-red-600 tw-text-white",
  statusBlue: "tw-bg-status-blue-500 hover:tw-bg-status-blue-600 tw-text-white",
};

const sizePadding = {
  small: "tw-py-1 tw-px-2 !tw-normal-case",
  medium: "tw-py-2 tw-px-6",
  large: "tw-py-3 tw-px-8",
};

defineProps({
  text: { type: String, default: "" },
  icon: { type: String as PropType<BaseButtonIcon>, required: false },
  variant: { type: String as PropType<BaseButtonVariant>, required: false },
  disabled: { type: Boolean, required: false },
  eventType: {
    type: String as PropType<ButtonEventTypes>,
    required: false,
    default: "button",
  },
  isWaiting: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String as PropType<"small" | "medium" | "large">,
    default: "large",
  },
  dataTest: {
    type: String,
    default: "",
  },
});
</script>
