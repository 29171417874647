import { DxAPIConnector } from "@/api";
import { DashboardNew, CardNew, WidgetBase, WidgetType } from "@/api/types";
import { WidgetHandler } from "./WidgetHandler";
import { CardHandler } from "./CardHandler";
import { WidgetCreator } from "./WidgetCreator";
import { t } from "i18next";

export class DashboardHandler {
  id?: DashboardNew["id"];
  title: DashboardNew["title"];
  imOnly: DashboardNew["imOnly"];
  cards: CardHandler[] = [];
  widgets: WidgetHandler[] = [];
  order: { cardID: string; widgetIDs: string[] }[] = [];
  connector: DxAPIConnector;
  setError: (options: { message: string; type: string }) => void;
  addCardToDashboard: (dashboardID: string, targetCard: CardHandler) => void;
  addWidgetToCard: (cardID: string, targetWidget: WidgetHandler) => void;
  constructor(options: {
    connector: DxAPIConnector;
    setError: (options: { message: string; type: string }) => void;
    addCardToDashboard: (dashboardID: string, targetCard: CardHandler) => void;
    addWidgetToCard: (cardID: string, targetWidget: WidgetHandler) => void;
    dashboard: {
      id?: DashboardNew["id"];
      title: DashboardNew["title"];
      imOnly: DashboardNew["imOnly"];
    };
    cards?: CardNew[];
    widgets?: WidgetBase[];
    order?: { cardID: string; widgetIDs: string[] }[];
  }) {
    const { dashboard, cards, widgets, order } = options;
    this.id = dashboard.id;
    this.title = dashboard.title;
    this.imOnly = dashboard.imOnly || false;
    this.connector = options.connector;
    this.setError = options.setError;
    this.addCardToDashboard = options.addCardToDashboard;
    this.addWidgetToCard = options.addWidgetToCard;

    this.order = order || [];
    this.widgets =
      widgets?.map(
        (widget) =>
          new WidgetHandler(widget, widget.cardID, this.id!, this.imOnly)
      ) || [];
    this.cards = this.orderCards(cards || []);
  }

  /**
   * Applies the card & widget order during the creation of the dashboard.
   * @param cards
   * @returns
   */
  private orderCards(cards: CardNew[]) {
    const orderArray = this.order.map((e) => e.cardID.toString());

    const orderedCards = orderArray
      .map((id) => {
        return cards.find((card) => card.id == id);
      })
      .filter((card) => card !== undefined) as CardNew[];

    return orderedCards.map((card) => {
      const unorderedWidgets = this.widgets.filter(
        (widget) => widget.cardID == card.id
      );
      const widgetOrder = this.order.find(
        (o) => o.cardID == card.id
      )?.widgetIDs;

      const orderedWidgets = this.orderWidgetsByOrderObject(
        unorderedWidgets,
        widgetOrder || []
      );
      return new CardHandler(
        card.id,
        this.id!,
        card.title,
        card.size,
        orderedWidgets
      );
    });
  }

  /**
   * Applies the order to the widgets in the dashboard.
   * @param unorderedObjects
   * @param order
   * @returns
   */
  private orderWidgetsByOrderObject(
    unorderedObjects: WidgetHandler[],
    order: string[]
  ) {
    const idToIndexMap = new Map<string, number>();

    // Create a mapping from id to its index in the order array
    order.forEach((id, index) => {
      idToIndexMap.set(id, index);
    });

    // Sort the array based on the mapping
    const orderedObjects = unorderedObjects.sort((a, b) => {
      const aIndex = idToIndexMap.get(a.widgetID) ?? Number.MAX_VALUE;
      const bIndex = idToIndexMap.get(b.widgetID) ?? Number.MAX_VALUE;
      return aIndex - bIndex;
    });

    return orderedObjects;
  }

  /**
   * Updates the order of the dashboard.
   * @param newOrder
   */
  async updateOrder(newOrder: { cardID: string; widgetIDs: string[] }[]) {
    try {
      await this.connector.uiSettings.setDashboardOrder({
        dashboardID: this.id!,
        order: JSON.stringify(newOrder),
      });
      this.order = newOrder;
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("dashboard.order_update_error") as string,
        type: "error",
      });
    }
  }

  /**
   * Saves the dashboard to the DB and returns the id
   * @returns id
   */
  async createDashboard() {
    try {
      const id = await this.connector?.uiSettings.createDashboard(this.title);
      this.id = id;
      this.setError({
        message: t("success.success_save_dashboard_title") as string,
        type: "success",
      });
      return id;
    } catch (error) {
      console.error(error);
      this.setError({
        message: error as string,
        type: "error",
      });
    }
  }

  /**
   * Removes the dashboard from the DB
   */
  async deleteDashboard() {
    try {
      await this.connector?.uiSettings.removeDashboard(this.id!);
      this.setError({
        message: t("dashboard.delete_success") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: error as string,
        type: "error",
      });
    }
  }

  async updateDashboard(title: string) {
    try {
      if (!title || title === this.title) return;
      const trimmed = title.trim();
      if (!trimmed || trimmed === this.title) return;
      await this.connector?.uiSettings.updateDashboard({
        id: this.id!,
        title: trimmed,
      });
      this.title = trimmed;
      this.setError({
        message: t("success.success_save_dashboard_title") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Creates a new card and saves it to the BE
   * If saving to the BE is successful, it adds the card to the dashboard
   * @param cardOptions title<string> and size<CardSize>
   */
  async createCard(cardOptions: {
    title: CardNew["title"];
    size: CardNew["size"];
  }) {
    try {
      const newCardId = await this.connector.uiSettings.createCard({
        dashboardID: this.id!,
        title: cardOptions.title,
        size: cardOptions.size,
      });

      this.cards.push(
        new CardHandler(
          newCardId,
          this.id!,
          cardOptions.title,
          cardOptions.size,
          []
        )
      );
      this.order.push({
        cardID: newCardId,
        widgetIDs: [],
      });
      this.setError({
        message: t("im.card.add_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.card.add_error_message") as string,
        type: "error",
      });
    }
  }

  /**
   * Adds cards internally
   * @param card
   */
  addCard(card: CardHandler) {
    this.cards.push(card);
    this.order.push({
      cardID: card.id,
      widgetIDs: card.widgets.map((widget) => widget.widgetID),
    });
  }

  /**
   * Updates an existing card.
   * @param cardOptions
   * @returns
   */
  async updateCard(cardOptions: {
    id: CardNew["id"];
    title: CardNew["title"];
    size: CardNew["size"];
    dashboardID: CardNew["dashboardID"];
  }) {
    try {
      /**
       * Update the record in the BE
       */
      await this.connector.uiSettings.updateCard({
        id: cardOptions.id,
        title: cardOptions.title,
        dashboardID: cardOptions.dashboardID,
        size: cardOptions.size,
      });

      /**
       * Update the record in the store
       */
      const targetCard = this.cards.find((card) => card.id == cardOptions.id);
      // A card is always edited in the parent dashboard. So this early fail is mostly useless except some errors/inconsistencies in the store.
      if (!targetCard) {
        return;
      }
      targetCard.size = cardOptions.size;
      targetCard.title = cardOptions.title;

      // In case the card has been moved into a different dashboard.
      if (cardOptions.dashboardID !== this.id) {
        //! remove me
        // this.uiStore
        //   .getNewDashById(cardOptions.dashboardID)
        //   ?.addCard(targetCard);

        this.addCardToDashboard(cardOptions.dashboardID, targetCard);
        this.cards = this.cards.filter((card) => card.id != cardOptions.id);
      }

      this.setError({
        message: t("im.card.edit_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.card.edit_error_message") as string,
        type: "error",
      });
    }
  }

  /**
   * Removes a card from the dashboard.
   * Widget removal is handled by the BE
   * @param cardID
   */
  async removeCard(cardID: string) {
    try {
      await this.connector.uiSettings.removeCard(cardID);
      this.cards = this.cards.filter((card) => card.id != cardID);
      this.order = this.order.filter((order) => order.cardID != cardID);
      this.setError({
        message: t("im.card.remove_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.card.remove_error_message") as string,
        type: "error",
      });
    }
  }

  /**
   * Returns the card with the given id
   * @param cardID
   * @returns
   */
  getCardByID(cardID: string) {
    return this.cards.find((card) => card.id == cardID);
  }
  /**
   * Removes a single widget from the dashboard and the card.
   * @param widgetID
   */
  async removeWidget(widgetID: string) {
    try {
      await this.connector.uiSettings.removeWidget(widgetID);
      const widgetToBeDeleted = this.widgets.find(
        (widget) => widget.widgetID == widgetID
      );
      this.widgets = this.widgets.filter(
        (widget) => widget.widgetID != widgetID
      );
      this.cards
        .find((card) => card.id === widgetToBeDeleted?.cardID)
        ?.removeWidget(widgetToBeDeleted!);

      this.removeWidgetFromOrder(widgetID, widgetToBeDeleted?.cardID);
      this.setError({
        message: t("im.widget.remove_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.widget.remove_error_message") as string,
        type: "error",
      });
    }
  }

  removeWidgetFromOrder(widgetID: string, cardID?: string) {
    const targetOrder = this.order.find((order) => order.cardID === cardID);
    if (!targetOrder) return;

    const targetIndexInOrder = targetOrder.widgetIDs.findIndex(
      (wID) => wID === widgetID
    );
    if (targetIndexInOrder >= 0) {
      targetOrder.widgetIDs.splice(targetIndexInOrder, 1);
    }
  }

  /**
   * Accepts a WidgetCreator Class instance as an argument.
   * Prepares the widgetSpecific data and saves it to the BE.
   * @param widgetCreatorInstance WidgetCreator
   * @returns
   */
  async addWidget(widgetCreatorInstance: WidgetCreator) {
    try {
      const submissionData = widgetCreatorInstance.prepareWidgetSpecificData();

      if (!submissionData) {
        return;
      }

      // Create the widget in the BE and get the ID.
      const id = await this.connector?.uiSettings.createWidget(submissionData);

      // Create the WidgetHandler instance.
      const newWidget = new WidgetHandler(
        {
          id,
          type: widgetCreatorInstance.type,
          data: widgetCreatorInstance.getWidgetSpecificData() as WidgetBase["data"],
          cardID: widgetCreatorInstance.cardID,
        },
        widgetCreatorInstance.cardID,
        this.id!,
        this.imOnly
      );

      // Add the widget to the dashboard.
      this.widgets.push(newWidget);

      // Add the widget to the card.
      this.cards
        .find((card) => card.id == widgetCreatorInstance.cardID)
        ?.addWidget(newWidget);

      // Add the widget to the order.
      // By default we save the new widget to the last order.
      this.order
        .find(
          (order) => order.cardID.toString() == widgetCreatorInstance.cardID
        )
        ?.widgetIDs.push(id);
      this.setError({
        message: t("im.widget.add_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.widget.add_error_message") as string,
        type: "error",
      });
    }
  }

  /**
   * Updates a single widget.
   * Handles the parent change if necessary
   * @param options
   */
  async updateWidget(options: {
    widgetID: string;
    type: WidgetType;
    data: WidgetBase["data"];
    cardID: string;
  }) {
    try {
      await this.connector.uiSettings.updateWidget({
        ...options,
        data: JSON.stringify(options.data),
      });
      const targetWidget = this.widgets.find(
        (widget) => widget.widgetID == options.widgetID
      );
      if (!targetWidget) return;

      targetWidget.setWidgetSpecificData(options.data);
      if (targetWidget && targetWidget.cardID != options.cardID) {
        this.cards
          .find((card) => card.id == targetWidget.cardID)
          ?.removeWidget(targetWidget!);

        const destinationCard = this.cards.find(
          (card) => card.id == options.cardID
        );
        if (destinationCard) {
          destinationCard.addWidget(targetWidget);
        } else {
          //! remove me
          // this.uiStore
          //   .getDashboardByElementID(options.cardID, "card")
          //   ?.getCardByID(options.cardID)
          //   ?.addWidget(targetWidget);
          this.addWidgetToCard(options.cardID, targetWidget);
        }
        targetWidget.cardID = options.cardID;
      }
      this.setError({
        message: t("im.widget.edit_success_message") as string,
        type: "success",
      });
    } catch (error) {
      console.error(error);
      this.setError({
        message: t("im.widget.edit_error_message") as string,
        type: "error",
      });
    }
  }
}
