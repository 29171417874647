export type DeviceInfo = {
  [key: string]: DeviceDisplayInfo;
};

export interface DeviceDisplayInfo {
  displayName: string;
  links: {
    [key in "en" | "de" | "it" | "fr"]?: string;
  };
  icon:
    | "cio"
    | "controller"
    | "disinfection"
    | "hard-drive"
    | "level-sensor"
    | "pump"
    | "sensor"
    | "virtual";
}

export const DEVICE_DISPLAY_DATA: DeviceInfo = {
  AEGIS2: {
    displayName: "AEGIS II",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Measuring-Control-and-Sensor-Technology/Controllers/p-aegis-ii.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Mess-und-Regeltechnik-Sensortechnik/Mess-und-Regelger%C3%A4te/p-aegis-ii.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-misura-e-regolazione-sensori/Strumenti-di-misura-e-regolazione/p-aegis-ii.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Technique-de-mesure-et-de-r%C3%A9gulation-sondes/Appareils-de-mesure-et-de-r%C3%A9gulation/p-aegis-ii.html",
    },
    icon: "controller",
  },
  CDKD: {
    displayName: "Bello Zon® CDKd",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Disinfection-Systems-and-Oxidation-Systems/Chlorine-Dioxide-Systems/p-bello-zon-cdkd.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Desinfektionssysteme-und-Oxidationssysteme/Chlordioxidanlagen/p-bello-zon-cdkd.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-disinfezione-e-ossidazione/Impianti-di-biossido-di-cloro/p-bello-zon-cdkd.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Syst%C3%A8mes-de-d%C3%A9sinfection-et-d-oxydation/Syst%C3%A8mes-de-dioxyde-de-chlore/p-bello-zon-cdkd.html",
    },
    icon: "disinfection",
  },
  CDLB: {
    displayName: "Bello Zon® CDLb",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Disinfection-Systems-and-Oxidation-Systems/Chlorine-Dioxide-Systems/p-bello-zon-cdlb.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Desinfektionssysteme-und-Oxidationssysteme/Chlordioxidanlagen/p-bello-zon-cdlb.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-disinfezione-e-ossidazione/Impianti-di-biossido-di-cloro/p-bello-zon-cdlb.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Syst%C3%A8mes-de-d%C3%A9sinfection-et-d-oxydation/Syst%C3%A8mes-de-dioxyde-de-chlore/p-bello-zon-cdlb.html",
    },
    icon: "disinfection",
  },
  CDVD: {
    displayName: "Bello Zon® CDVd",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Disinfection-Systems-and-Oxidation-Systems/Chlorine-Dioxide-Systems/p-bello-zon-cdvd.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Desinfektionssysteme-und-Oxidationssysteme/Chlordioxidanlagen/p-bello-zon-cdvd.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-disinfezione-e-ossidazione/Impianti-di-biossido-di-cloro/p-bello-zon-cdvd.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Syst%C3%A8mes-de-d%C3%A9sinfection-et-d-oxydation/Syst%C3%A8mes-de-dioxyde-de-chlore/p-bello-zon-cdvd.html",
    },
    icon: "disinfection",
  },
  CIIa: {
    displayName: "CHLORINSITU IIa",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Disinfection-Systems-and-Oxidation-Systems/Electrolysis-Systems/p-chlorinsitu-iia-xl.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Desinfektionssysteme-und-Oxidationssysteme/Elektrolyseanlagen/p-chlorinsitu-iia-xl.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-disinfezione-e-ossidazione/Impianti-di-elettrolisi/p-chlorinsitu-iia-xl.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Syst%C3%A8mes-de-d%C3%A9sinfection-et-d-oxydation/Installations-d-%C3%A9lectrolyse/p-chlorinsitu-iia-xl.html",
    },
    icon: "disinfection",
  },
  DACB: {
    displayName: "DULCOMETER® diaLog DACb",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Measuring-Control-and-Sensor-Technology/Controllers/p-dulcometer-dialog-dacb.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Mess-und-Regeltechnik-Sensortechnik/Mess-und-Regelger%C3%A4te/p-dulcometer-dialog-dacb.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-misura-e-regolazione-sensori/Strumenti-di-misura-e-regolazione/p-dulcometer-dialog-dacb.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Technique-de-mesure-et-de-r%C3%A9gulation-sondes/Appareils-de-mesure-et-de-r%C3%A9gulation/p-dulcometer-dialog-dacb.html",
    },
    icon: "controller",
  },
  DFXA: {
    displayName: "DULCO flex Control - DFXa",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Metering-Pumps/Peristaltic-Metering-Pumps/p-dulcoflex-control.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Dosierpumpen/Schlauchdosierpumpen/p-dulcoflex-control.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Pompe-dosatrici/Pompe-peristaltiche-di-dosaggio/p-dulcoflex-control.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Pompes-doseuses/Pompes-doseuses-p%C3%A9ristaltiques/p-dulcoflex-control.html",
    },
    icon: "pump",
  },
  DFYA: {
    displayName: "DULCO flex Control - DFYa",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Metering-Pumps/Peristaltic-Metering-Pumps/p-dulcoflex-control-dfya.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Dosierpumpen/Schlauchdosierpumpen/p-dulcoflex-control-dfya.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Pompe-dosatrici/Pompe-peristaltiche-di-dosaggio/p-dulcoflex-control-dfya.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Pompes-doseuses/Pompes-doseuses-p%C3%A9ristaltiques/p-dulcoflex-control-dfya.html",
    },
    icon: "pump",
  },
  DIALOGX: {
    displayName: "DULCOMETER® diaLog X",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Measuring-Control-and-Sensor-Technology/Controllers/p-aegis-x.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Mess-und-Regeltechnik-Sensortechnik/Mess-und-Regelger%C3%A4te/p-aegis-x.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-misura-e-regolazione-sensori/Strumenti-di-misura-e-regolazione/p-aegis-x.html",
    },
    icon: "controller",
  },
  DXGW: {
    displayName: "DULCONNEX Gateway",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Digital-Solutions/p-dulconnex-gateway.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Digital-Solutions/p-dulconnex-gateway.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Soluzioni-digitali/p-dulconnex-gateway.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Solutions-num%C3%A9riques/p-dulconnex-gateway.html",
    },
    // TODO: Replace this icon once we get an new one.
    icon: "hard-drive",
  },
  DXIPC: {
    displayName: "DULCONNEX IPC Gateway",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Digital-Solutions/p-dulconnexipc-gateway.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Digital-Solutions/p-dulconnexipc-gateway.html",
    },
    // TODO: Replace this icon once we get an new one.
    icon: "hard-drive",
  },
  GAMMAX: {
    displayName: "gamma/ X",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Metering-Pumps/Solenoid-Driven-Metering-Pumps/p-gamma-x.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Dosierpumpen/Magnet-Membrandosierpumpen/p-gamma-x.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Pompe-dosatrici/Pompe-dosatrici-magnetiche-a-membrana/p-gamma-x.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Solutions-num%C3%A9riques/p-dulconnex-gateway.html",
    },
    icon: "pump",
  },
  GAMMAXL: {
    displayName: "gamma/ XL",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Metering-Pumps/Solenoid-Driven-Metering-Pumps/p-gamma-xl.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Dosierpumpen/Magnet-Membrandosierpumpen/p-gamma-xl.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Pompe-dosatrici/Pompe-dosatrici-magnetiche-a-membrana/p-gamma-xl.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Pompes-doseuses/Pompes-doseuses-%C3%A9lectromagn%C3%A9tiques-%C3%A0-membrane/p-gamma-xl.html",
    },
    icon: "pump",
  },
  "HIPECS-C300": {
    displayName: "hipecs CIO300",
    links: {},
    icon: "cio",
  },
  "HIPECS-C50": {
    displayName: "hipecs CIO50",
    links: {},
    icon: "cio",
  },
  "HIPECS-C57": {
    displayName: "hipecs CIO57",
    links: {},
    icon: "cio",
  },
  "HIPECS-C58": {
    displayName: "hipecs CIO58",
    links: {},
    icon: "cio",
  },
  "HIPECS-C60": {
    displayName: "hipecs CIO60",
    links: {},
    icon: "cio",
  },
  RADA: {
    displayName: "DULCOLEVEL",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Measuring-Control-and-Sensor-Technology/Level-Measurement/p-dulcolevel.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Mess-und-Regeltechnik-Sensortechnik/F%C3%BCllstandsmessung/p-dulcolevel.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-misura-e-regolazione-sensori/Misurazione-livello-di-riempimento/p-dulcolevel.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Technique-de-mesure-et-de-r%C3%A9gulation-sondes/Mesure-de-niveau/p-dulcolevel.html",
    },
    icon: "level-sensor",
  },
  SIGMAB: {
    displayName: "Sigma X",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Metering-Pumps/Motor-Driven-Metering-Pumps/p-sigma-x-s3cb-control-type-motor-driven.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Dosierpumpen/Motor-Membrandosierpumpen/p-sigma-x-s1cb-control-type-motor-driven.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Pompe-dosatrici/Pompe-dosatrici-a-motore-con-membrana/p-sigma-x-s3cb-control-type-motor-driven.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Pompes-doseuses/Pompes-doseuses-%C3%A0-membrane-%C3%A0-moteur/p-sigma-x-s3cb-control-type-motor-driven.html",
    },
    icon: "pump",
  },
  UVCB: {
    displayName: "Dulcodes LP/MP",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Disinfection-Systems-and-Oxidation-Systems/UV-Systems/p-dulcodes-lp.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Desinfektionssysteme-und-Oxidationssysteme/UV-Anlagen/p-dulcodes-lp.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-disinfezione-e-ossidazione/Impianti-UV/p-dulcodes-lp.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Syst%C3%A8mes-de-d%C3%A9sinfection-et-d-oxydation/Installations-aux-UV/p-dulcodes-lp.html",
    },
    icon: "disinfection",
  },
  DPP: {
    displayName: "DULCOPOOL Pro",
    links: {
      en: "https://www.prominent.com/en/Products/Products/Measuring-Control-and-Sensor-Technology/Controllers/p-dulcopool-pro.html",
      de: "https://www.prominent.de/de/Produkte/Produkte/Mess-und-Regeltechnik-Sensortechnik/Mess-und-Regelger%C3%A4te/p-dulcopool-pro.html",
      it: "https://www.prominent.it/it/Prodotti/Prodotti/Sistemi-di-misura-e-regolazione-sensori/Strumenti-di-misura-e-regolazione/p-dulcopool-pro.html",
      fr: "https://www.prominent.fr/fr/Produits/Produits/Technique-de-mesure-et-de-r%C3%A9gulation-sondes/Appareils-de-mesure-et-de-r%C3%A9gulation/p-dulcopool-pro.html",
    },
    icon: "controller",
  },
  virtual: {
    displayName: "Virtual Device",
    links: {},
    icon: "virtual",
  },
};
