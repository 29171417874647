/* eslint-disable no-case-declarations */
import { ASTNode } from "@/api/types";
import { useDeviceStore } from "@/storeModules";
import { DXDevice, DXDuration, DXVariable } from "@/types";
import { dateFromDate, timeFromDate } from "@/utils/DateFormatting";

export abstract class FunctionComponent {
  abstract clone(): FunctionComponent;
}

// dummy component for component list
export class PredefinedComponent extends FunctionComponent {
  public clone(): FunctionComponent {
    return new PredefinedComponent();
  }
}

export class FCSingleSymbol extends FunctionComponent {
  constructor(public readonly symbol: string) {
    super();
  }
  public clone() {
    return new FCSingleSymbol(this.symbol);
  }
}

export enum DSTimingType {
  CURRENT_VALUE = 0,
  TIME_RELATIVE = 1,
  TIME_ABSOLUTE = 2,
}

export enum ValueType {
  INSTANT = 'instant',
  AVERAGE = 'average'
}

export class FCConstant extends FunctionComponent {
  public value = NaN;
  public initialContextMenuOpened = false;
  public isComplete(): boolean {
    return !isNaN(this.value);
  }
  public clone() {
    const newConst = new FCConstant();
    newConst.value = this.value;
    newConst.initialContextMenuOpened = this.initialContextMenuOpened;
    return newConst;
  }
}

export abstract class FCDataDependant extends FunctionComponent {
  public device: DXDevice | undefined = undefined;
  public variable: DXVariable | undefined = undefined;
  public abstract isComplete(): boolean;
}

export class FCDataSource extends FCDataDependant {
  public relativeOffsetMS = -1;
  public absoluteDate = -1;
  public valueType: "instant" | "average" = 'instant';
  public timingtype: DSTimingType = DSTimingType.CURRENT_VALUE;
  public isComplete(): boolean {
    const timeOK =
      this.timingtype == DSTimingType.CURRENT_VALUE ||
      (this.timingtype == DSTimingType.TIME_ABSOLUTE &&
        this.absoluteDate != -1) ||
      (this.timingtype == DSTimingType.TIME_RELATIVE &&
        this.relativeOffsetMS != -1);

    const parsedDate = new Date(this.absoluteDate);
    const absoluteTimeInFuture =
      this.timingtype == DSTimingType.TIME_ABSOLUTE &&
      Date.now() < parsedDate.getTime();
    return (
      this.device != null &&
      this.variable != null &&
      timeOK &&
      !absoluteTimeInFuture
    );
  }

  public clone(): FunctionComponent {
    const newDS = new FCDataSource();
    newDS.device = this.device;
    newDS.variable = this.variable;
    newDS.valueType = this.valueType;
    newDS.relativeOffsetMS = this.relativeOffsetMS;
    newDS.absoluteDate = this.absoluteDate;
    newDS.timingtype = this.timingtype;
    return newDS;
  }
}

