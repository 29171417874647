<template>
  <FullPageModal
    :is-open="isOpen"
    :onCloseModal="() => tourStore.closeModal()"
    :header-text="($t(tourStore.activeTour?.titleTranslationKey ?? '') as string)"
    :isGuidedTour="true"
    :isTourComplete="isTourComplete"
    @skip="skipOrSaveForLater('skip')"
    @save-for-later="skipOrSaveForLater('save')"
  >
    <GuidedTourContent @save-tour="skipOrSaveForLater('save')" />
  </FullPageModal>
</template>

<script setup lang="ts">
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";
import FullPageModal from "../library/FullPageModal/FullPageModal.vue";
import { computed } from "vue";
import GuidedTourContent from "../library/GuidedTour/GuidedTourContent.vue";
import { useGlobalConfigsStore } from "@/storeModules";
import { clearAndClose } from "@/utils/guidedTours/tourUtils";

const tourStore = useGuidedTourStore();
const globalStore = useGlobalConfigsStore();

async function skipOrSaveForLater(skipOrSave: "skip" | "save") {
  clearAndClose();
  tourStore.setSkipped(true);
  const tour = tourStore.activeTour;
  if (skipOrSave === "skip" || isTourComplete.value) {
    return;
  }
  if (tour) {
    await globalStore.connector?.general.setTourInfo({
      guidedTourUiId: tour.id,
      name: tour.titleTranslationKey,
      section: tour.category,
      totalSteps: tour.steps.length,
      currentStep: tourStore.currentStep + 1,
      rank: tour.rank,
    });
    await tourStore.getCompletedOrSavedTours();
  }
}

const isTourComplete = computed(
  () => tourStore?.activeTour?.currentStep === tourStore?.activeTour?.totalSteps
);

const isOpen = computed(() => {
  if (tourStore.activeTour?.isSkipped) {
    return false;
  }
  const currentStep = tourStore.getCurrentStep;
  if (currentStep) {
    return currentStep.options?.elementTourID === undefined;
  }
  return false;
});
</script>
