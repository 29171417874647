<template>
  <nav
    class="tw-sticky tw-bg-primary-500 tw-top-0 tw-left-0 tw-w-screen tw-flex tw-flex-col tw-items-center tw-justify-end tw-h-auto"
  >
    <div
      class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-h-16 tw-w-full tw-space-x-2"
    >
      <router-link :to="{ name: 'Welcome' }">
        <img
          :src="`/api/get-logo.svg?type=brand&brand=${brand}`"
          alt="logo"
          class="tw-w-36 tw-scale-75 md:tw-scale-125"
        />
      </router-link>
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-4 tw-pr-8"
      >
        <PublicMenuItem
          v-for="miscItem in menuItems.filter((m) => m.alignment == 'right')"
          :key="miscItem.path"
          :path="miscItem.path"
          :icon="miscItem.icon"
          :translationKey="miscItem.translationKey"
        />

        <button
          class="tw-text-2xl tw-text-white tw-bg-primary-500 tw-fill-white"
          @click="isOpen = !isOpen"
        >
          <font-awesome-icon v-if="!isOpen" :icon="['fas', 'bars']" />
          <font-awesome-icon v-else :icon="['fas', 'xmark']" />
        </button>
      </div>
    </div>
    <div
      v-if="isOpen"
      :class="`tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-2 tw-pb-8 tw-w-full ${
        isOpen ? '' : 'tw-hidden'
      }`"
    >
      <div class="tw-text-gray-50 tw-rounded-full tw-justify-self-end">
        <LanguageSelector />
      </div>
      <PublicMenuItem
        v-for="miscItem in menuItems.filter((m) => m.alignment == 'left')"
        :key="miscItem.path"
        :path="miscItem.path"
        :icon="miscItem.icon"
        :translationKey="($t(miscItem.translationKey) as string)"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import LanguageSelector from "../LanguageSelector/LanguageSelector.vue";
import PublicMenuItem from "../PublicMenu/PublicMenuItem.vue";
import { PublicMenuItem as PublicMenuItemType } from "../PublicMenu/PublicMenuUtils";

const isOpen = ref<boolean>(false);
defineProps({
  menuItems: {
    type: Array as PropType<PublicMenuItemType[]>,
    required: true,
  },
  brand: { type: String, default: "prominent" },
});
</script>
