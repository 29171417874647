<template>
  <button
    @click.prevent.stop="
      () => {
        writableValueCopy = !writableValueCopy;
        emit('update:modelValue', writableValueCopy);
      }
    "
    :class="`tw-ml-auto tw-mr-2 tw-h-7 tw-w-7 tw-rounded-full tw-flex tw-bg-primary-400 hover:tw-bg-primary-500 tw-text-inherit active:tw-border-5 tw-border-solid tw-border-pink-500`"
  >
    <FontAwesomeIcon
      icon="fa-chevron-down"
      size="xs"
      :class="`tw-m-auto tw-transition-transform tw-duration-500 ${
        writableValueCopy ? 'tw-rotate-180' : 'tw-rotate-0'
      }`"
    />
  </button>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({ modelValue: { type: Boolean, required: true } });
const writableValueCopy = ref(props["modelValue"]);

watch(
  () => props["modelValue"],
  () => (writableValueCopy.value = props["modelValue"])
);

const emit = defineEmits(["update:modelValue"]);
</script>
