<template>
  <div v-if="devicesByTags">
    <div
      class="tw-text-primary-contrast hover:tw-bg-primary-500"
      v-for="(entry, tagIndex) in devicesByTags"
      :key="entry[0]"
    >
      <router-link
        @click="emit('navigated')"
        :to="{
          name: 'Device List',
          params: { tag: entry[0] || 'no-tag' },
        }"
      >
        <div class="tw-flex tw-pl-3">
          <div class="left-spacer">
            <div class="line-segment" />
            <FontAwesomeIcon
              class="tw-absolute tw-bg-primary-600 tw-p-1 tw-rounded-full"
              icon="hashtag"
            />
          </div>
          <div
            class="tw-py-4 tw-ml-2 tw-font-semibold"
            :class="entry[0] === '' ? 'tw-italic' : ''"
          >
            {{ getTagName(entry[0]) }}
          </div>
          <div class="tw-my-auto tw-ml-auto">
            <CollapseButton
              v-model="menuCollapsedStatus[tagIndex]"
              class="tw-bg-transparent"
              @update:model-value="(v) => handleToggle(entry[0] || 'no-tag', v)"
              :data-test="`collapse-button-${entry[0] || 'no-tag'}`"
            />
          </div>
        </div>
      </router-link>
      <!-- List devices -->
      <TransitionCollapse>
        <div
          v-if="menuCollapsedStatus[tagIndex]"
          class="tw-bg-primary-700 tw-pl-3"
        >
          <router-link
            @click="emit('navigated')"
            v-for="device in [...entry[1]].sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )"
            :key="device.sn"
            :to="{
              name: 'Device Values',
              query: { id: device.id },
            }"
          >
            <div
              class="tw-flex hover:tw-bg-primary-500"
              :data-test="device.name"
            >
              <div class="left-spacer">
                <div class="line-segment" />
                <div
                  :class="`tw-w-3 tw-h-3 tw-rounded-full tw-absolute ${getOnlineStatusClass(
                    device.online,
                    device.type.startsWith('virtual')
                  )}`"
                ></div>
              </div>

              <DeviceTypeIcon :deviceType="device.type" />
              <div class="tw-my-2 tw-mx-2">{{ device.name }}</div>

              <div class="tw-ml-auto" />

              <div class="tw-my-auto tw-flex">
                <!-- Alarm -->
                <router-link
                  v-if="
                    alarms &&
                    alarms.length &&
                    alarms
                      .filter((e) => e.alarming > 0)
                      .map((e) => e.deviceid)
                      .includes(device.id)
                  "
                  @click="emit('navigated')"
                  :to="{
                    name: 'Device Alarms',
                    query: { id: device.id },
                  }"
                >
                  <div>
                    <div
                      class="tw-text-status-red-500 tw-my-auto tw-mr-2 animate-alarm"
                    >
                      <FontAwesomeIcon icon="fa-bell" />
                    </div>
                  </div>
                </router-link>
                <!-- Issues -->
                <router-link
                  @click="emit('navigated')"
                  v-if="getActiveIssues(device.id).length > 0"
                  :to="{
                    name: 'Device Overview',
                    query: {
                      id: device.id,
                    },
                  }"
                >
                  <div>
                    <FontAwesomeIcon
                      icon="fa-triangle-exclamation"
                      class="tw-text-status-yellow-500 tw-my-auto tw-mr-2"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
      </TransitionCollapse>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DXAlarm, DXDevice, DXIssue } from "@/types";
import { PropType, computed, ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TransitionCollapse from "@/components/library/TransitionCollapse/TransitionCollapse.vue";
import CollapseButton from "@/components/library/CollapseButton/CollapseButton.vue";
import { watch } from "vue";
import { useDeviceStore, useUserConfigStore } from "@/storeModules";
import { Variants } from "@/components/library/OnlineStatusDisplay/OnlineStatusDisplayUtils";
import { useTranslation } from "i18next-vue";
import {
  createCollapseMenu,
  readCollapseMenu,
  toggleCollapseMenu,
} from "@/utils/userpreferences/collapseMenu";
import DeviceTypeIcon from "@/components/library/DeviceTypeIcon/DeviceTypeIcon.vue";

const props = defineProps({
  devicesByTags: {
    type: Object as PropType<
      ReturnType<typeof useDeviceStore>["getDevicesGroupByTags"]
    >,
    required: true,
  },
  alarms: {
    type: Object as PropType<DXAlarm[]>,
    required: true,
  },
  issues: {
    type: Object as PropType<{ [key: number]: DXIssue[] }>,
    required: true,
  },
});

const userStore = useUserConfigStore();
const { t } = useTranslation();

const parseMenuCollapsedStatus = () => {
  const arr: boolean[] = [];
  props.devicesByTags.forEach((entry) => {
    const userPreference = readCollapseMenu(entry[0] || "no-tag");
    if (userPreference === null) {
      arr.push(true);
      createCollapseMenu(entry[0] || "no-tag", true);
    } else {
      arr.push(userPreference.toString() === "true");
    }
  });
  return arr;
};
const menuCollapsedStatus = ref(parseMenuCollapsedStatus());

const getTagName = computed(() => (tag: string) => {
  return tag || t("navi.notag");
});

const handleToggle = (name: string, v: boolean) => {
  toggleCollapseMenu(name, v);
};

/**
 * Filters the active issues based on tsEnd.
 * We only want to show the indicator if the issue is still active.
 * If tsEnd is zero the issue is still active.
 * @param deviceID
 */
const getActiveIssues = (deviceID: DXDevice["id"]) => {
  const deviceIssues = props.issues[deviceID];
  if (!deviceIssues) return [];
  const activeIssues = props.issues[deviceID].filter((issue) => {
    return issue.tsEnd === 0;
  });
  return activeIssues;
};

watch(
  () => props.devicesByTags,
  () => {
    menuCollapsedStatus.value = parseMenuCollapsedStatus();
  }
);

const emit = defineEmits(["navigated"]);

function getOnlineStatusClass(online: boolean, isVirtual: boolean) {
  const variant = online ? "onlineClass" : "offlineClass";
  return Variants[isVirtual ? "virtual-device" : "device"][variant];
}
</script>

<style scoped lang="postcss">
a {
  @apply tw-text-inherit;
}
.left-spacer {
  @apply tw-w-6 tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-relative;
}
.line-segment {
  @apply tw-h-full tw-w-0 tw-border-0 tw-border-l tw-border-solid tw-border-primary-contrast tw-ml-[-1px];
}
</style>
