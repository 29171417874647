import { asyncCall } from "./call";
import { DashboardResponse, ServerResponse, WidgetType } from "./types";

export class UISettingsConnector {
  private sessionID: string;
  private urlPrefix: string;

  constructor(urlPrefix: string, sessionID: string) {
    this.urlPrefix = urlPrefix;
    this.sessionID = sessionID;
  }

  /**
   * Get's all the dashboard data.
   * @target "/api/get-dashboards"
   * @returns server response or DashboardData
   */
  async getDashboards() {
    const result: ServerResponse | DashboardResponse = await asyncCall(
      this.urlPrefix + "/api/get-dashboards",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * Creates a new dashboard and returns the newly assigned dashboard id.
   * @target "/api/create-dashboard"
   * @param title title of the dashboard
   * @returns
   */
  async createDashboard(title: string) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/create-dashboard",
      {
        session: this.sessionID,
        title,
      }
    );
    return result;
  }

  /**
   * Removes a dashboard.
   * @target "/api/remove-dashboard"
   * @param id
   * @returns
   */
  async removeDashboard(id: string) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/remove-dashboard",
      {
        session: this.sessionID,
        id,
      }
    );
    return result;
  }

  /**
   *
   * @param options id, title
   * @returns
   */
  async updateDashboard(options: { id: string; title: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/update-dashboard",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Creates a new card and returns the newly assigned card id.
   * @target "/api/create-card"
   * @param options dashboardID, title, size
   * @returns
   */
  async createCard(options: {
    dashboardID: string;
    title: string;
    size: "small" | "medium" | "large";
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/create-card",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Removes a card.
   * @target "/api/remove-card"
   * @param id
   * @returns
   */
  async removeCard(id: string) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/remove-card",
      {
        session: this.sessionID,
        id,
      }
    );
    return result;
  }

  /**
   * Updates the given card. It cannot change the dashboard that the card belongs.
   * @target "/api/update-card"
   * @param options
   * @returns
   */
  async updateCard(options: {
    id: string;
    title: string;
    dashboardID: string;
    size: "small" | "medium" | "large";
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/update-card",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Creates a new widget.
   * @target "/api/create-widget"
   * @param options
   * @returns
   */
  async createWidget(options: {
    type: WidgetType;
    data: string;
    cardID: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/create-widget",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Removes a widget.
   * @target "/api/remove-widget"
   * @param id
   * @returns
   */
  async removeWidget(id: string) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/remove-widget",
      {
        session: this.sessionID,
        id,
      }
    );
    return result;
  }

  /**
   * Updates a single widget.
   * @param options
   * @returns
   */
  async updateWidget(options: {
    widgetID: string;
    type: WidgetType;
    data: string;
    cardID: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/update-widget",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * Saves the new dashboard order object per dashboard.
   * @param options
   * @returns
   */
  async setDashboardOrder(options: { dashboardID: string; order: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-dashboard-order",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }
}
