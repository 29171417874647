export type PointedModalWidth = "small" | "medium" | "large";
export type caretPosition = "left" | "right" | "top" | "bottom" | "off";
export type modalPosition =
  | "top-left"
  | "top-middle"
  | "top-right"
  | "middle-left"
  | "middle-right"
  | "bottom-left"
  | "bottom-middle"
  | "bottom-right"
  | "mid-screen";

export function getOuterFlexDirectionClass(caretPos: caretPosition) {
  switch (caretPos) {
    case "top":
      return "tw-flex-col";
    case "bottom":
      return "tw-flex-col-reverse";
    case "left":
      return "tw-flex-row";
    case "right":
      return "tw-flex-row-reverse";
    case "off":
    default:
      return "";
  }
}

export function getCaretRotationClass(caretPos: caretPosition) {
  switch (caretPos) {
    case "left":
      return "-tw-rotate-90 tw-translate-x-1/4";
    case "right":
      return "tw-rotate-90 -tw-translate-x-1/4";
    case "bottom":
      return "tw-rotate-180";
    case "top":
    case "off":
    default:
      return "";
  }
}

export function getOuterTranslationClass(modalPos: modalPosition) {
  switch (modalPos) {
    case "top-left":
      return "-tw-translate-y-full -tw-translate-x-full";
    case "top-middle":
      return "-tw-translate-x-1/2 -tw-translate-y-full";
    case "top-right":
      return "-tw-translate-y-full";
    case "middle-left":
      return "-tw-translate-y-1/2 -tw-translate-x-full";
    case "middle-right":
      return "-tw-translate-y-1/2";
    case "bottom-left":
      return "-tw-translate-x-full";
    case "bottom-middle":
      return "-tw-translate-x-1/2";
    case "bottom-right":
    default:
      return "";
  }
}

export function getRoundedCornerClass(modalPos: modalPosition): string {
  switch (modalPos) {
    case "top-left":
      return "tw-rounded-br-none";
    case "top-right":
      return "tw-rounded-bl-none";
    case "bottom-left":
      return "tw-rounded-tr-none";
    case "bottom-right":
      return "tw-rounded-tl-none";
    default:
      return "tw-rounded-md";
  }
}

export function getModalWidthClass(width: PointedModalWidth) {
  switch (width) {
    case "small":
      return "tw-w-32";
    case "medium":
      return "tw-w-72";
    case "large":
      return "tw-w-96 tw-max-w-xs sm:tw-max-w-96";
  }
}
