<template>
  <ErrorBoundary>
    <div class="tw-flex">
      <!-- Top of Sidebar -->
      <ShrinksWithNavBar>
        <div class="tw-flex tw-items-center tw-p-2">
          <router-link :to="{ name: 'Dashboard' }">
            <img
              class="tw-object-contain tw-max-h-16 tw-p-3 tw-max-w-xs tw-h-16 tw-w-full"
              :src="
                globalStore._wlPreviewData.productLogo as string ||
                `/api/get-logo.svg?type=brand&brand=${globalStore.brand}`
              "
              alt="platform logo"
            />
          </router-link>
          <NavigationCollapseButton
            class="tw-ml-auto"
            @click="uiStore.sideBarCollapsed = !uiStore.sideBarCollapsed"
            data-test="navigation-collapse-button"
          />
        </div>
      </ShrinksWithNavBar>
      <div class="tw-my-auto tw-ml-4" v-if="uiStore.sideBarCollapsed">
        <NavigationCollapseButton
          class="tw-transition-opacity tw-duration-300"
          :class="uiStore.sideBarCollapsed ? 'tw-opacity-100' : 'tw-opacity-0'"
          @click="uiStore.sideBarCollapsed = !uiStore.sideBarCollapsed"
          :disabled="!uiStore.sideBarCollapsed"
          data-test="navigation-open-button"
        />
      </div>

      <!-- Actual Header -->
      <div class="tw-px-4 tw-py-2 tw-my-auto tw-w-full tw-flex tw-items-center">
        <!-- Breadcrumbs -->
        <div class="tw-hidden md:tw-block">
          <BreadCrumbs />
        </div>
        <!-- Icons -->
        <div class="tw-flex tw-items-center tw-ml-auto">
          <div
            v-if="hasTour && !isMobile && !isReactNative"
            class="list-icon_item dropdown"
          >
            <RoundDoubleIconButton
              icon-inner="book-open-reader"
              icon-outer="info"
              :outlined="false"
              data-bs-toggle="dropdown"
              data-test="open-guided-tour"
            />
            <div class="dropdown-menu py-0 rounded tw-w-64">
              <div
                class="tw-bg-primary-500 tw-text-primary-contrast tw-flex tw-px-2 tw-py-4"
              >
                <div
                  class="tw-font-bold tw-m-auto tw-items-center tw-text-break"
                  v-html="$t('header.label_guided_tour')"
                ></div>
              </div>
              <template
                v-for="(tours, category) in toursByCategory"
                :key="category"
              >
                <div>
                  <div
                    class="tw-bg-primary-300 tw-text-primary-contrast tw-d-flex tw-px-2 tw-py-2"
                  >
                    <div class="font-bold m-auto text-break">
                      {{ category }}
                    </div>
                  </div>
                  <li v-for="tour in tours" :key="tour.guidedTourUiId">
                    <a
                      href="#"
                      class="tw-flex tw-items-center tw-shadow tw-p-2 tw-cursor-pointer hover:tw-bg-primary-50 tw-text-primary-600"
                      @click.prevent="
                        displayTour(
                          tour.section,
                          tour.guidedTourUiId,
                          tour.currentStep,
                          tour.isComplete
                        )
                      "
                      :data-test="'tour-selector-' + tour.guidedTourUiId"
                    >
                      <span>{{ t(tour.name) }}</span>
                      <div class="tw-flex tw-justify-end tw-ml-auto">
                        <div v-if="tour.isComplete">
                          <RoundIconButton
                            fa-icon="flag-checkered"
                            @click="
                              displayTour(
                                tour.section,
                                tour.guidedTourUiId,
                                FIRST_TOUR_STEP,
                                tour.isComplete
                              )
                            "
                            :outlined="false"
                          />
                        </div>
                        <div v-else>
                          <RoundStepBar
                            :currentStep="tour.currentStep"
                            :totalSteps="tour.totalSteps"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                </div>
              </template>
            </div>
          </div>

          <GuidedTourTarget targetID="add-device-modal-open">
            <RoundDoubleIconButton
              icon-inner="hard-drive"
              icon-outer="plus"
              :outlined="false"
              data-test="open-add-device-modal"
              @click="handleAddDeviceButton"
            />
          </GuidedTourTarget>
          <RoundIconButton
            :outlined="false"
            fa-icon="circle-question"
            @click="handleModalOpen('support')"
          />
          <div class="tw-h-8 tw-w-[1px] tw-bg-primary-50 tw-mx-2" />
          <div
            v-if="
              typeof availableLangs !== undefined &&
              Object.keys(availableLangs).length > 0
            "
            class="list-icon_item dropdown"
            id="navLang"
          >
            <a
              href="#"
              data-modal="timestamp"
              class="btnModal dropdown-toggle"
              id="btnModalLang"
              data-bs-toggle="dropdown"
              data-test="open-language-selector"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              data-tooltip="tooltip"
              data-placement="bottom"
              title="Language / Sprache"
            >
              <FontAwesomeIcon
                icon="custom-language"
                class="tw-m-auto tw-text-2xl tw-mt-2 tw-text-primary-300 hover:tw-text-primary-400"
              />
            </a>
            <ul
              class="dropdown-menu dropdown-menu-right dropdown-submenu dropdown-language"
              aria-labelledby="btnModalLang"
            >
              <span class="dropdown-arrow"></span>
              <div
                class="dropdown-header tw-bg-primary-500 tw-text-primary-contrast"
              >
                <FontAwesomeIcon
                  icon="custom-language"
                  class="tw-absolute tw-text-white tw-opacity-10 tw-text-8xl tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
                />
                <h4 v-html="$t('navi.header_language_headline')"></h4>
              </div>
              <div class="dropdown-body">
                <div class="row">
                  <div class="col-sm-12 subnavi">
                    <ul class="row clearfix">
                      <li
                        v-for="(lang, languageCode) in availableLangs"
                        class="col-md-6"
                        :key="languageCode"
                        :class="
                          $i18next.language === languageCode.toString()
                            ? 'tw-bg-primary-100'
                            : ''
                        "
                      >
                        <a
                          href="#"
                          @click.prevent="
                            languageChanger(languageCode.toString())
                          "
                          :data-test="
                            'language-selector-' + languageCode.toString()
                          "
                        >
                          <div
                            class="tw-h-8 tw-w-8 tw-rounded-full tw-overflow-hidden"
                          >
                            <FlagDisplay
                              squared
                              :flag="languageCode.toString().toLowerCase()"
                            />
                          </div>
                          <span>{{ lang.nativeName }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div class="list-icon_item dropdown">
            <a
              data-test="header-profile-dropdown-toggle"
              href="#"
              class="btnModal dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
            >
              <FontAwesomeIcon
                icon="user"
                class="tw-m-auto tw-text-xl tw-mt-2 tw-text-primary-300 hover:tw-text-primary-400"
              />
              <div
                v-if="newsList.filter((e) => !e.hasRead).length > 0"
                id="profileNewsIndicator"
                class="tw-bg-primary-500"
              />
            </a>
            <div class="dropdown-menu py-0 rounded" style="width: 150px">
              <div
                class="tw-bg-primary-500 tw-text-primary-contrast d-flex px-2 py-3 rounded-top"
              >
                <div class="font-bold m-auto text-break">
                  {{ userName }}
                </div>
              </div>
              <RouterLink to="/profile" class="d-flex py-2">
                <font-awesome-icon
                  class="w-2 my-auto tw-text-primary-600"
                  icon="fa-solid fa-user w-2"
                />
                <div
                  class="text-body"
                  v-html="$t('profileDropdown.profile')"
                ></div>
              </RouterLink>
              <RouterLink
                class="d-flex py-2"
                to="/whats-new"
                data-test="profile_dropdown_whats"
              >
                <font-awesome-icon
                  class="w-2 my-auto tw-text-primary-600"
                  icon="fa-bullhorn"
                />
                <div
                  class="text-body my-auto"
                  v-html="$t('profileDropdown.whatsnew')"
                ></div>
                <div
                  v-if="newsList.filter((e) => !e.hasRead).length > 0"
                  class="rounded-pill tw-bg-primary-500 ms-1 my-auto px-1 py-1 my-auto text-white"
                >
                  {{ newsList.filter((e) => !e.hasRead).length }}
                </div>
              </RouterLink>
              <RouterLink
                v-if="userStore.hasWLTools"
                class="d-flex py-2"
                :to="{ name: 'WL Preview' }"
              >
                <font-awesome-icon
                  class="w-2 my-auto tw-text-primary-600"
                  icon="fa-gear"
                />
                <div class="text-body">WL Preview</div>
              </RouterLink>
              <RouterLink
                class="d-flex py-2"
                to="/logout"
                data-test="header-profile-dropdown-logout"
              >
                <font-awesome-icon
                  class="w-2 tw-text-primary-600"
                  icon="fa-solid
                fa-right-from-bracket"
                />
                <div
                  class="text-body"
                  v-html="$t('profileDropdown.logout')"
                ></div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PM Account Warning -->
    <div v-if="contract" data-test="header-pm-banner">
      <div class="tw-bg-secondary-500 tw-h-1" />
      <div class="flex tw-relative">
        <div
          class="tw-absolute tw-z-[41] tw-text-xs tw-text-primary-contrast tw-font-semibold tw-bg-secondary-500 tw-inline-block tw-pb-1 tw-px-2 tw-ml-8 tw-rounded-b-lg"
        >
          PM Account - Use with caution - v.{{ version }}
          {{ userStore.hasMathFunctionality ? " (Math Beta)" : "" }}
        </div>
      </div>
    </div>

    <FullPageModal
      :isOpen="openModal"
      :onCloseModal="closeModal"
      :headerText="modalHeaderTextHandler()"
    >
      <SupportForm v-if="modalSubject === 'support'" @reset="closeModal" />
    </FullPageModal>
  </ErrorBoundary>
</template>

<script setup lang="ts">
import FullPageModal from "../library/FullPageModal/FullPageModal.vue";
import ShrinksWithNavBar from "./navigation/ShrinksWithNavBar.vue";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs.vue";
import SupportForm from "../elements/SupportForm/SupportForm.vue";
import NavigationCollapseButton from "./navigation/NavigationCollapsButton.vue";
import RoundDoubleIconButton from "../library/RoundDoubleIconButton/RoundDoubleIconButton.vue";
import RoundIconButton from "@/components/library/RoundIconButton/RoundIconButton.vue";
import FlagDisplay from "../library/FlagDisplay/FlagDisplay.vue";
import ErrorBoundary from "../library/ErrorBoundary/ErrorBoundary.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, ComputedRef } from "vue";
import i18next, { t } from "i18next";
import { DXNews, DXToursByCategory } from "@/types";
import {
  useDeviceStore,
  useGlobalConfigsStore,
  useUISettingsStore,
  useUserConfigStore,
} from "@/storeModules";
import { useGuidedTourStore } from "@/storeModules/guidedtours/GuidedTourStore";
import GuidedTourTarget from "@/components/library/GuidedTour/GuidedTourTarget.vue";
import RoundStepBar from "@/components/library/RoundBar/RoundStepBar.vue";
import { FIRST_TOUR_STEP, RESET_TOUR_STEP, TOUR_ID } from "@/constants/tour";
import { useIsMobile } from "@/utils/useIsMobile";
import * as Sentry from "@sentry/vue";

const { isReactNative, isMobile } = useIsMobile();
const globalStore = useGlobalConfigsStore();
const userStore = useUserConfigStore();
const uiStore = useUISettingsStore();
const tourStore = useGuidedTourStore();

const version = __APP_VERSION__;

const availableLangs = computed(() => globalStore.availableLanguages);

const handleAddDeviceButton = () => {
  uiStore.addDeviceModalOpen = true;
  Sentry.setTag("a-b", "add device");
  Sentry.captureMessage("add device opened through header", "info");
};

const toursByCategory = computed(() => {
  const deviceStore = useDeviceStore();
  const categories: DXToursByCategory =
    tourStore.completedOrSavedToursByCategory;
  const sortedCategories: DXToursByCategory = {};
  for (const category in categories) {
    sortedCategories[category] = [...categories[category]].sort(
      (a, b) => a.rank - b.rank
    );
  }
  const hasVirtualDeviceInAccount = deviceStore.getDevices.some((device) =>
    device.type.startsWith("virtual")
  );
  if (!hasVirtualDeviceInAccount) {
    for (const category in sortedCategories) {
      sortedCategories[category] = sortedCategories[category].filter(
        (tour) => tour.guidedTourUiId === TOUR_ID.VIRTUAL_DEVICE_TOUR
      );
    }
  }
  return sortedCategories;
});

const hasTour = computed(() =>
  Object.values(toursByCategory.value).some((category) => category.length > 0)
);

const contract = computed(() => {
  const contractsThatGetsWarning = ["foofoo42", "foofoo43", "demodemo"];
  return contractsThatGetsWarning.includes(
    userStore.userData.contract as string
  );
});

const openModal = ref(false);
const openTour = ref(false);
const modalSubject = ref("");

const handleModalOpen = (modal: "support" | "addDevice") => {
  modalSubject.value = modal;
  openModal.value = true;
};

const closeModal = () => {
  openModal.value = false;
};

const modalHeaderTextHandler = () => {
  switch (modalSubject.value) {
    case "addDevice":
      return t("modals.modal_device_add_title");
    case "support":
      return t("modals.support_title");
    default:
      return "";
  }
};

// TODO: Remove this and replace with the LanguageSelector component
const languageChanger = (val: string) => {
  i18next.changeLanguage(val);
  localStorage.setItem("prominent.user.language", val);
  globalStore.setLanguage(val);
  globalStore.connector?.general.setLanguage({ language: val.toUpperCase() }); // i18n returns the lang in lowercase. BE needs it in uppercase.
  userStore.userSettings.language = val.toUpperCase();
};

const displayTour = async (
  category: string,
  tourId: string,
  currentStep: number,
  isTourComplete: boolean
) => {
  openTour.value = false;
  const tourToBeDisplayed = await tourStore.getTourByIdAndCategory(
    category,
    tourId
  );

  if (userStore.hasMathFunctionality && tourToBeDisplayed) {
    tourToBeDisplayed.currentStep = currentStep;
    const targetStep = isTourComplete ? RESET_TOUR_STEP : currentStep - 2;
    await tourStore.loadTour(tourToBeDisplayed, targetStep);
    await tourStore.incrementStep();
    openTour.value = true;
  }
};

const userName: ComputedRef<string> = computed(() => {
  return userStore.userData.user ?? "";
});

const newsList: ComputedRef<DXNews[]> = computed(() => {
  return userStore.newsList;
});
</script>

<style scoped lang="postcss">
#profileNewsIndicator {
  @apply tw-bg-primary-500;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 2px;
  right: 6px;
  border-radius: 10px;
}
</style>
