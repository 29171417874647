<template>
  <div>
    <div v-if="currentPage == 'data-entry-physical'">
      <AddDeviceDataEntry
        :is-waiting="isWaiting"
        @cancel="emit('reset')"
        @add-existing-device="addExistingDevice"
        @create-virtual-device="createVirtualDevice"
      />
    </div>
    <div
      v-else-if="currentPage == 'success'"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-space-y-8"
    >
      <FontAwesomeIcon
        class="tw-text-status-green tw-text-8xl"
        icon="circle-check"
        data-test="device-added-success"
      />
      <p
        class="tw-my-4 tw-text-center tw-text-2xl tw-font-bold"
        v-html="$t(message)"
      ></p>
      <div>
        {{
          deviceStore.getDeviceById(navigateToDeviceTargetID.toString())?.name
        }}
      </div>
      <div
        class="tw-flex tw-flex-row tw-w-full tw-items-stretch tw-justify-center md:tw-justify-end tw-space-x-4 tw-flex-shrink-0 tw-flex-wrap"
      >
        <BaseButton
          variant="neutral"
          :text="($t('modals.modal_device_add_another_title') as string)"
          @click="resetModal"
          data-test="modal-device-add-another"
        />
        <RouterLink
          :to="{
            name: 'Device Values',
            query: { id: navigateToDeviceTargetID },
          }"
        >
          <BaseButton
            variant="statusGreen"
            :text="($t('modals.modal_device_add_gotodevice') as string)"
            @click="emit('reset')"
            data-test="modal-device-add-gotodevice"
          />
        </RouterLink>
      </div>
    </div>
    <div
      v-else-if="currentPage == 'success-with-admin-approval'"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-space-y-8"
    >
      <FontAwesomeIcon
        class="tw-text-status-yellow tw-text-8xl"
        icon="circle-check"
      />
      <p
        class="tw-my-4 tw-text-center tw-text-2xl tw-font-bold"
        v-html="
          $t('modals.modal_device_add_response_success_with_admin_approval')
        "
      ></p>
      <div
        class="tw-flex tw-flex-row tw-w-full tw-items-stretch tw-justify-center md:tw-justify-end tw-space-x-4 tw-flex-shrink-0 tw-flex-wrap"
      >
        <BaseButton
          variant="neutral"
          :text="($t('modals.modal_device_add_another_title') as string)"
          @click="resetModal"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import BaseButton from "../../library/BaseButton/BaseButton.vue";
import {
  useDeviceStore,
  useGlobalConfigsStore,
  useUISettingsStore,
} from "@/storeModules";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AddDeviceDataEntry from "./AddDeviceDataEntry.vue";
import { addDeviceBySerialNumber } from "./AddDeviceFormUtils";
const message = ref<string>("");

const deviceStore = useDeviceStore();
const uiStore = useUISettingsStore();

const emit = defineEmits(["reset"]);

const isWaiting = ref<boolean>(false);
const navigateToDeviceTargetID = ref(-1);
const currentPage = ref<
  "data-entry-physical" | "success" | "success-with-admin-approval"
>("data-entry-physical");

const resetModal = () => {
  isWaiting.value = false;
  currentPage.value = "data-entry-physical";
};

async function addExistingDevice(options: {
  name: string;
  type: string;
  serialNumber: string;
  tag: string;
}) {
  isWaiting.value = true;
  const result = await addDeviceBySerialNumber(options);
  switch (result.type) {
    case "ResultSuccess":
      uiStore.refreshElements = ["devices"];
      message.value = "modals.modal_device_add_response_success";
      currentPage.value = "success";
      navigateToDeviceTargetID.value = result.deviceID;
      break;
    case "ResultExists":
      message.value = "modals.modal_device_add_headline_exists";
      currentPage.value = "success";
      isWaiting.value = false;
      navigateToDeviceTargetID.value = result.deviceID;
      break;
    case "ResultAdminApproval":
      currentPage.value = "success-with-admin-approval";
      break;
    case "ResultError":
      uiStore.setError({ message: result.message, type: "error" });
      break;
  }
  isWaiting.value = false;
}

async function createVirtualDevice(options: { name: string; tag: string }) {
  isWaiting.value = true;
  try {
    const res =
      await useGlobalConfigsStore().connector?.devices.addVirtualDevice(
        options
      );
    const newDeviceID = parseInt(res ?? "NaN");
    if (res && !isNaN(newDeviceID)) {
      uiStore.refreshElements = ["devices"];
      message.value = "modals.modal_device_add_response_success";
      currentPage.value = "success";
      navigateToDeviceTargetID.value = newDeviceID;
    } else throw new Error(res);
  } catch (error) {
    uiStore.setError({ message: String(error), type: "error" });
  } finally {
    isWaiting.value = false;
  }
}
</script>
