import { CurrentValue, Session, Settings } from "@/api/types";
import { DXNews } from "@/types";
import { defineStore } from "pinia";

export const useUserConfigStore = defineStore("userConfig", {
  state: () => ({
    maxDashboards: 99,
    maxCards: 99,
    maxWidgets: 99,
    featureLimit: false,
    userName: false, //! not present in store anymore
    userContract: false, //! not present in store anymore
    newsList: [] as DXNews[],
    userData: {} as Partial<Session>,
    current: [] as CurrentValue[],
    completedTours: [] as string[],
    userSettings: {} as Settings,
  }),
  getters: {
    hasMathFunctionality(state) {
      return (
        state.userData?.accessRights?.["access-math-functionality"] === true
      );
    },
    hasWLTools(state) {
      return (
        // TODO: Clean it after the BE implementation.
        state.userData?.accessRights?.["access-wl-tools"] === true ||
        state.userData.contract === "foofoo42"
      );
    },
  },
});
