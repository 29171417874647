<template>
  <BaseLabel
    :inputId="inputId!"
    :label="label || ''"
    :labelAlignment="labelAlignment"
    :additionalData="additionalData"
    :secondaryLabel="secondaryLabel"
  >
    <input
      ref="inputRef"
      :class="`${
        disabled ? 'tw-opacity-50' : ''
      }  ${additionalClasses} tw-input-base`"
      :id="inputId"
      :type="inputType"
      :step="step"
      :min="min"
      :max="max"
      :disabled="disabled"
      :value="modelValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :list="`${label}_datalist`"
      :required="required"
      :checked="checked"
      :data-test="dataTest"
      :style="
        inputType === 'checkbox'
          ? `transform: scale(${checkBoxScale || 1.5});`
          : ''
      "
      @input="handleInput"
      :isValid="isValid"
    />
    <datalist :id="`${label}_datalist`" v-if="dataList && dataList.length > 0">
      <option v-for="(tag, index) in dataList" :key="index" :value="tag" />
    </datalist>
  </BaseLabel>
</template>
<script setup lang="ts">
import { PropType, ref } from "vue";
import { BaseInputTypes } from "./BaseInputUtils";
import BaseLabel from "../BaseLabel/BaseLabel.vue";
import { BaseLabelProps } from "../BaseLabel/BaseLabelUtils";
import { onMounted } from "vue";

const inputRef = ref<null | HTMLInputElement>(null);

const emit = defineEmits(["update:modelValue"]);
const handleInput = (event: Event) => {
  const target = event.target;
  if (props.inputType === "checkbox") {
    emit("update:modelValue", (target as HTMLInputElement).checked);
  } else if (target instanceof HTMLInputElement) {
    const value = target.value;
    emit("update:modelValue", value);
  }
};

const props = defineProps({
  inputType: { type: String as PropType<BaseInputTypes>, required: true },
  step: { type: Number, required: false },
  min: { type: Number, required: false },
  max: { type: Number, required: false },
  disabled: { type: Boolean, required: false },
  placeholder: { type: String, required: false },
  autocomplete: { type: String, required: false },
  modelValue: { type: [String, Boolean, Number], required: true },
  dataList: { type: Array as PropType<string[]>, required: false },
  required: { type: Boolean, required: false },
  value: { type: String, required: false },
  checked: { type: Boolean, required: false },
  checkBoxScale: { type: Number, required: false },
  isValid: { type: Boolean, required: false, default: undefined },
  dataTest: { type: String, default: "" },
  additionalClasses: { type: String, default: "" },
  focusOnMounted: { type: Boolean, default: false },
  ...BaseLabelProps,
});

onMounted(() => {
  if (props.focusOnMounted && inputRef.value != null) {
    inputRef.value.focus();
    inputRef.value.select();
  }
});
</script>
