<template>
  <div
    class="toast-container position-fixed b1 end-0 p-3 tw-z-[51] mt8"
    id="toasts"
  >
    <!-- Then put toasts within -->
    <div
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      :data-bs-autohide="toast.persistent ? '' + true : '' + false"
      v-for="(toast, index) in toasts"
      :key="index"
      :class="
        'toast w-100 bl-' +
        toast.type +
        ' bg-' +
        toast.type +
        '-light fade show'
      "
      id="toast"
    >
      <div :class="'d-flex align-items-center mp0 pv1 text-' + toast.type">
        <div class="align-items-center ph1">
          <FontAwesomeIcon
            :icon="getToastStyle(toast.type, toast.action ?? '', '').icon"
            class="tw-text-2xl"
          />
        </div>
        <div :class="'flex-grow-1 fs1 text-' + toast.type">
          <p v-html="toast.message" class="mp0"></p>
          <p v-if="toast.response" class="fs07 mp0 mt04">
            <span v-html="$t('labels.toast_server_response')"></span>
            {{ toast.response }}
          </p>
        </div>
        <div class="ph1" v-if="toast.persistent">
          <button
            type="button"
            :class="'btn mp0 text-' + toast.type"
            data-bs-dismiss="toast"
            aria-label="Close"
            @click.prevent="removeToast(toast.id)"
          >
            <FontAwesomeIcon icon="xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUISettingsStore } from "@/storeModules";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const uiStore = useUISettingsStore();

const toasts = computed(() => {
  return uiStore.toasts;
});

function removeToast(id: string) {
  uiStore.removeToast(id);
}

function getToastStyle(
  type: string,
  useraction: string,
  useractionlabel: string
) {
  let icon = "";
  let link = "";
  let label = "";
  let action = false;
  if (type === "error") icon = "database";
  if (type === "warning") icon = "triangle-exclamation";
  if (type === "success") icon = "circle-check";
  if (useraction === "reload") {
    action = true;
    link = "window.location.reload(true)";
    label = useractionlabel;
  }
  if (useraction === "relogin") {
    action = true;
    link = 'window.location.href="logout.html"';
    label = useractionlabel;
  }
  const obj = {
    type: type,
    icon: icon,
    action: action,
    link: "javascript:" + link,
    label: label,
  };
  return obj;
}
</script>
