<template>
  <div class="tw-h-screen tw-flex tw-flex-col tw-justify-between">
    <PublicMenu v-if="!isReactNative" />
    <ErrorBoundary>
      <div class="tw-flex-auto tw-h-full">
        <router-view></router-view>
      </div>
    </ErrorBoundary>
    <DXFooter v-if="!isReactNative" />
  </div>
</template>
<script setup lang="ts">
import ErrorBoundary from "../library/ErrorBoundary/ErrorBoundary.vue";
import DXFooter from "./DXFooter.vue";
import PublicMenu from "./PublicMenu/PublicMenu.vue";
import { useIsMobile } from "@/utils/useIsMobile";

const { isReactNative } = useIsMobile();
</script>
